import { useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,

  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-gray-700)",
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
    boxShadow:
      "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--clr-white)",
  border: "1px solid var(--clr-secondary-blue-500)",
  "&:before": {
    display: "block",
    position: "absolute",
    right: "9px",
    top: "9px",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='blue' /%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    border: "1px solid var(--clr-secondary-blue-300)",
  },
});

export const Dropdown = ({
  values,
  setValues,
  label,
  options,
  displayOptions,
  width,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();

  const mapValuesToDisplayValues = (values, options, displayOptions) => {
    return values?.map((value) => {
      return displayOptions[options.indexOf(value)];
    });
  };
  if (!displayOptions) displayOptions = options;

  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value[value?.length - 1] === "all") {
      setValues(value?.length > options?.length ? [] : options);
    } else {
      setValues(typeof value === "string" ? value.split(",") : value);
    }
    if (onChange) onChange();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ChevronDown = (props) => {
    return (
      <svg
        {...props}
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: isOpen ? "rotate(180deg)" : "none",
          position: "absolute",
          right: "7%",
          cursor: "pointer",
          top: "calc(50% - 0.3rem)",
        }}
      >
        <path
          d="M1 0.750002L5.5 5.25L10 0.75"
          stroke="#1C1D20"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    );
  };

  const ref = useRef(null);
  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ width: width, position: "relative" }}
      ref={ref}
    >
      <FormControl variant="filled" sx={{ width: "100%" }}>
        <InputLabel
          sx={{
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.9rem",
            transform: "translate(1.2rem, 1rem) scale(1)",
            color: "var(--clr-gray-600)",
            "::first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          multiple
          value={values}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          onBlur={onBlur}
          renderValue={() => {
            if (values?.length >= options?.length) {
              return t("All");
            } else {
              return mapValuesToDisplayValues(
                values,
                options,
                displayOptions
              ).join(", ");
            }
          }}
          MenuProps={{
            sx: {
              "&& .Mui-selected:not(:hover)": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              "& .MuiPaper-root": {
                width: `${
                  ref.current ? ref.current.offsetWidth : ""
                }px !important`,
                padding: "0rem",
                borderRadius: "4px",
              },
            },
          }}
          IconComponent={ChevronDown}
          sx={{
            backgroundColor: "var(--clr-white)",
            height: "4rem",
            border: `1px solid ${
              values?.length === 0
                ? "var(--clr-gray-900)"
                : "var(--clr-leaftech-blue-500)"
            }`,
            borderRadius: "7px",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.4rem",
            lineHeight: "1.9rem",
            "&:hover": {
              backgroundColor: "var(--clr-white)",
              border: "1px solid var(--clr-secondary-blue-200)",
              boxShadow:
                "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
              ":before": {
                border: "none !important",
              },
            },
            "&.Mui-focused": {
              border: "1px solid var(--clr-secondary-blue-500)",
              backgroundColor: "white",
            },
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          <MenuItem
            value="all"
            sx={{
              backgroundColor: `${
                values?.length === options?.length
                  ? "var(--clr-secondary-blue-50)"
                  : "var(--clr-white)"
              }`,
              marginRight: "4px",
              marginLeft: "4px",
              padding: "0.3rem",
              "&:hover": {
                backgroundColor: "var(--clr-secondary-blue-50)",
              },
              "&:focus:not(:hover)": {
                backgroundColor: "white",
              },
            }}
          >
            <Checkbox
              checked={values?.length > 0 && values?.length >= options?.length}
              sx={{
                "&:hover": { bgcolor: "transparent" },
              }}
              disableRipple
              color="default"
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              inputProps={{ "aria-label": "Checkbox demo" }}
            />
            <ListItemText
              primary={t("all")}
              disableTypography
              sx={{
                color: "var(--clr-gray-900)",
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "1.4rem",
                lineHeight: "1.9rem",
                marginLeft: "0.3rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            />
          </MenuItem>
          {options?.map((name, index) => (
            <MenuItem
              key={index}
              value={name}
              sx={{
                backgroundColor: "var(--clr-white)",
                marginRight: "4px",
                marginLeft: "4px",
                minHeight: "auto",
                padding: "0.3rem",
                "&:hover": {
                  backgroundColor: "var(--clr-secondary-blue-50)",
                },
                "&:focus:not(:hover)": {
                  backgroundColor: "white",
                },
              }}
            >
              <Checkbox
                sx={{
                  "&:hover": { bgcolor: "transparent" },
                }}
                checked={values?.indexOf(name) > -1}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                inputProps={{ "aria-label": "Checkbox demo" }}
              />
              <ListItemText
                primary={displayOptions[index]}
                disableTypography
                sx={{
                  color: "var(--clr-gray-900)",
                  fontFamily: "Nunito Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "1.4rem",
                  lineHeight: "1.9rem",
                  marginLeft: "0.3rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  );
};
const Wrapper = styled("div")(() => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },

  "& .MuiInputLabel-shrink": {
    transform: "translate(1.2rem, 0.7rem) scale(0.75)",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.2rem",
    letterSpacing: "0.1em",
    color: "var(--clr-gray-600)",
  },
}));
