// TODO:
// 1-After changing the structure of Icons, change styled part of this script.

import { createContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  useGetBuilding,
  useGetBulidingActiveTabs,
} from "../api/hooks/allHooks";
import { BuildingPassportHeader } from "../components/BuildingPassport/Header";
import { SecondaryNavbar } from "../components/BuildingPassport/SecondaryNavbar";
import { Loading } from "../components/Loading";
import { setCurrentPageName } from "../features/routeName/routeNameSlice";
import { getPageName, isPageDisabled } from "../utils/navigation";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";

export const ScreenShotContext = createContext();

const navLinks = [
  {
    linkName: "building profile",
    to: "buildingProfile",
    dataKey: "building_profile",
  },
  {
    linkName: "technical Data",
    to: "technicalData",
    dataKey: "technical_data",
  },
  {
    linkName: "Energy & CO₂",
    to: "energyPerformance",
    dataKey: "energy_co2",
  },
  {
    linkName: "certificates",
    to: "certificates",
    dataKey: "certificates",
  },
  {
    linkName: "modernization",
    to: "modernization",
    dataKey: "modernization",
  },
];

export const BuildingPassportSharedLayout = () => {
  const componentRef = useRef();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { data, isLoading: tabsIsLoading } = useGetBulidingActiveTabs(
    projectId
  );
  const buildingActiveTabs = data || {};

  useEffect(() => {
    dispatch(setCurrentPageName("building passport"));
  }, []);

  const { pathname } = useLocation();
  const pageName = getPageName(pathname);

  const disabled = isPageDisabled({ buildingActiveTabs, navLinks, pageName });

  if (disabled) return <Navigate to={"/"} />;

  return (
    <LoadingOrEmptyWrapper showLoading={tabsIsLoading} height="100vh">
      <div>
        <BuildingPassportHeader
          projectId={projectId}
          componentRef={componentRef}
          pageName={pageName}
        />
        <section>
          <SecondaryNavbar
            className="mt-4 mb-8"
            activeTabs={buildingActiveTabs}
            navLinks={navLinks}
          />
          <ScreenShotContext.Provider value={componentRef}>
            <Outlet></Outlet>
          </ScreenShotContext.Provider>
        </section>
      </div>
    </LoadingOrEmptyWrapper>
  );
};
