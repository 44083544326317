import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const defaultUnitsState = { consumption: "kWh", price: "€", emission: "KgCO₂" };

const initialState = {
  config: {
    dates: [new Date(null), new Date(null)],
    hourlyDates: [new Date(null), new Date(null)],
    quantity: "consumption",
    measureType: ["simulated"],
    allUnits: defaultUnitsState,
    selectedResourcesOptions: [
      "electricity",
      "hot_water",
      "heating",
      "cooling",
    ],
    timeframe: "monthly",
    resourcesOptions: ["electricity", "hot_water", "heating", "cooling"],
  },
  chartData: [],
};

const energyPerformanceSlice = createSlice({
  name: "energyPerformance",
  initialState,
  reducers: {
    setConfig: (state, { payload }) => {
      state.config[payload.key] = payload.value;
    },
    setChartData: (state, { payload }) => {
      state.chartData = payload;
    },
  },
});

export const { setConfig, setChartData } = energyPerformanceSlice.actions;
export default energyPerformanceSlice.reducer;
