import { useState } from "react";
import React from "react";

import "./buttonNew.css";
import styled from "styled-components";

export const ButtonNew = ({
  children,
  type,
  onClick,
  variant,
  className,
  disabled,
  isActive,
  width,
  height,
  size,
  style,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const preventFocusAndTriggerActive = (event) => {
    event.preventDefault();
    setIsPressed(true);
  };
  const untriggerActive = (event) => {
    setIsPressed(false);
  };
  const keyboardTriggerActive = (event) => {
    if (event.code === "Space" || event.code === "Enter") setIsPressed(true);
  };
  const keybordUntriggerActive = (event) => {
    if (event.code === "Space" || event.code === "Enter") setIsPressed(false);
  };
  // if (state === "pressed") state = "active";
  // const disabled = state === "disabled" ? true : false;
  return (
    <Wrapper
      className={` btn flex justify-center items-center btn--${variant} btn-new--${size} ${
        isPressed ? "pressed" : ""
      } ${isActive ? "isActive" : ""} ${className}`}
      style={{ width, height, ...style }}
      onClick={onClick}
      disabled={disabled}
      type={type}
      onMouseDown={preventFocusAndTriggerActive}
      onMouseUp={untriggerActive}
      onKeyDown={keyboardTriggerActive}
      onKeyUp={keybordUntriggerActive}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  :focus,
  :active {
    outline: none;
    border: none;
  }
`;
