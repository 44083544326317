import React, { useCallback } from "react";
import DynamicChartMemoized from "./DynamicChartMemoized";
import { ErrorBoundary } from "react-error-boundary";
import { GeneralErrorBoundary } from "../ErrorBoundary";
import { Modal } from "rsuite";
import { CustomModal } from "../CustomModal";

export const DynamicChartFullScreenHandler = ({ children, isFullScreen, className }) => {
  if (isFullScreen)
    return (
      <div
        style={{ width: '100vw', height: '100vh' }}
        className={className}>
        <CustomModal
          width='100vw' height='100vh'
          padding="0"
          closeIcon={false}
          modalOpen={true} >
          {children}

        </CustomModal>
      </div>
    )
  else return (
    children
  )
}


export const DynamicChart = ({
  id,
  config,
  removeChart,
  updateChart,
  fullScreenChart,
  ...props
}) => {
  const removeChartMemoized = useCallback(removeChart, []);
  const fullScreenChartMemoized = useCallback(fullScreenChart, [])
  const updateChartMemoized = useCallback(
    (key, value) => updateChart(key, value),
    []
  );

  const {
    diagrams,
    sortValue,
    chartType,
    title,
    xAxis,
    yAxis,
    dataPoint,
    dataLevel,
    timeframe,
    startDate,
    endDate,
    selectedScenarios,
    selectedUtilities,
    selectedDaysOfWeek,
    selectedHoursOfDay,
    legendsPosition,
  } = config;

  const shrinkedConfig = {
    diagrams,
    sortValue,
    chartType,
    title,
    xAxis,
    yAxis,
    dataPoint,
    dataLevel,
    timeframe,
    startDate,
    endDate,
    selectedScenarios,
    selectedUtilities,
    selectedDaysOfWeek,
    selectedHoursOfDay,
    legendsPosition,
  };

  // Pass this parameter to the DynamicChartMemoized only to make a rerender when changed.
  const userLanguage = localStorage.getItem('language')
  return (
    <GeneralErrorBoundary resetKeys={[config, props]}>
      <DynamicChartMemoized
        config={shrinkedConfig}
        onClose={removeChartMemoized}
        setConfig={updateChartMemoized}
        setFullScreenChartId={fullScreenChartMemoized}
        id={id}
        userLanguage={userLanguage}
        {...props}
      />
    </GeneralErrorBoundary>
  );
};
