import { Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsAllowed } from "../../acl";
import apiUrls from "../../api/urls";
import { getAletrnativeImage } from "../../utils/buildingImages";
import {
  addCommaToNumber,
  valueOrAlternative,
} from "../../utils/namesManipulation";
import CustomizedDialogs from "../Dialog";
import { IconButton } from "../IconButton";
import { Loading } from "../Loading";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

const dataLabelsAndKeysMapping = [
  { label: "ASSET NAME", dataKey: "name" },
  { label: "TYPE", dataKey: "type" },
  { label: "ASSET ID", dataKey: "asset_id" },
  { label: "ADDRESS", dataKey: "address" },
  { label: "YEAR OF CONSTRUCTION", dataKey: "construction_year" },
  { label: "MODERNIZATION YEAR", dataKey: "last_modernized" },
  { label: "GROSS ASSET SIZE", dataKey: "gross_asset_size" },
  { label: "GROSS ASSET VALUE", dataKey: "gross_asset_value" },
];

export const AssetDetailedCard = ({
  building,
  className,
  imgPath,
  name,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [imagePath, setImagePath] = useState(imgPath);
  if (!building) building = {};
  const { user } = useSelector((store) => store.user);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const token = user?.access_token;
  const url = apiUrls.buildingImage.post;
  const queryParams = [{ name: "project", value: building?.id }];
  const onSuccess = (resp) => {
    setHasError(false);
    setErrorMessage("");
    setImagePath(resp.image_file);
  };
  const onError = (xhr) => {
    setHasError(true);
    setErrorMessage(xhr.statusText);
  };

  useEffect(() => setImagePath(imgPath), [imgPath]);

  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="100%">
      <Paper className={className}>
        <div className="relative">
          {IsAllowed("BUILDING_PROFILE.UPLOAD_IMAGE") && (
            <CustomizedDialogs
              Component={
                <IconButton
                  iconProps={{
                    iconName: "Camera",
                    color: "white",
                  }}
                  buttonProps={{
                    className: "absolute right-3 bottom-3 z-10",
                    size: "sm",
                    variant: "primary",
                  }}
                ></IconButton>
              }
              token={token}
              url={url}
              formDataFileKeyName="image_file"
              onSuccess={onSuccess}
              onError={onError}
              queryParams={queryParams}
              errorMessage={errorMessage}
              successMessage={t("general.successfulImgaeUpload")}
              headerTitle={t("general.uploadImage")}
              headerText={t("general.dragToUpload")}
              hasError={hasError}
            />
          )}
          <img
            src={imagePath}
            className="min-h-[240px] max-h-[350px] w-full rounded drop-shadow"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getAletrnativeImage(building?.type);
            }}
          />
          {/* w-full h-60 min-w-[520px] max-w-[600px]  max-h-[350px]*/}
        </div>

        <h2 className="mt-8 sentence-case t-heading-l ">{name}</h2>
        <Stack className="justify-between items-start mt-6">
          <ul className="w-[47%]">
            {dataLabelsAndKeysMapping.slice(0, 4)?.map((mapping, index) => {
              return (
                <li
                  key={index}
                  className="mb-4 pb-2 border-solid border-0 border-b border-gray-100 "
                >
                  <h3 className="mb-1 uppercase t-label-m text-gray-400 ">
                    {t(`BuildingProfile.card.${mapping.label}`)}
                  </h3>
                  <p
                    className={`t-body-xl ${
                      mapping.label === "TYPE" ? "sentence-case" : ""
                    }`}
                  >
                    {t(valueOrAlternative(building[mapping.dataKey]))}
                  </p>
                </li>
              );
            })}
          </ul>
          <ul className="w-[47%]">
            {dataLabelsAndKeysMapping.slice(4)?.map((mapping, index) => {
              return (
                <li
                  key={index}
                  className="mb-4 pb-2 border-solid border-0 border-b border-gray-100"
                >
                  <h3 className="mb-1 uppercase t-label-m text-gray-400 ">
                    {t(`BuildingProfile.card.${mapping.label}`)}
                  </h3>
                  <p className="t-body-xl sentence-case">
                    {index > 1
                      ? addCommaToNumber(
                          valueOrAlternative(building[mapping.dataKey])
                        )
                      : valueOrAlternative(building[mapping.dataKey])}
                    {mapping.label === "GROSS ASSET SIZE" &&
                      building[mapping.dataKey] && <span> m²</span>}
                    {mapping.label === "GROSS ASSET VALUE" &&
                      building[mapping.dataKey] && <span> €</span>}
                  </p>
                </li>
              );
            })}
          </ul>
        </Stack>
      </Paper>
    </LoadingOrEmptyWrapper>
  );
};
