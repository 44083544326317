import React, { useState } from "react";
import { calculateLeft } from "../../utils/position";
import Bullet from "./Bullet";
import { Stack } from "@mui/material";
import { sumByKeys } from "../../utils/dataManipulation";

export const HorizontalStackedBarChartColorBar = ({
  data,
  config,
  className,
  height = "2.8rem",
}) => {
  const [hoverBulletIndex, setHoverBulletIndex] = useState(null);
  const aggregatedPercentages = sumByKeys({ data, keys: ["percentage"] });
  const totalSum = aggregatedPercentages?.percentage || 1;

  return (
    <Stack
      className={`${className}   [&>*:first-child]:rounded-l-lg [&>*:last-child]:rounded-r-lg`}
    >
      {data?.map((row, index) => {
        return (
          <div
            key={index}
            onMouseEnter={() => setHoverBulletIndex(index)}
            onMouseLeave={() => setHoverBulletIndex(null)}
            style={{
              backgroundColor: config[row.label]?.color,
              width: `${(row.percentage * 100) / totalSum}%`,
              height,
              position: "relative",
            }}
          >
            {index === hoverBulletIndex && (
              <Bullet
                className="absolute"
                left="50%"
                label={`${parseFloat((row.percentage * 100).toFixed(1))}%`}
                width="1.6rem"
                height="1.6rem"
                bottom="8px"
                top="0px"
                type="circle"
                color={config[row.label]?.color}
                reverse={false}
                transform={`translateX(-50%)`}
              />
            )}
          </div>
        );
      })}
    </Stack>
  );
};
