import { Stack } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";

const handleClick = (e, isDisabled) => {
  if (isDisabled) e.preventDefault();
};

export const SecondaryNavbar = ({ className, activeTabs, navLinks }) => {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={theme.spacing(8)}
      className={`t-subheading-m ${className} border-solid border-0 border-b-2 border-gray-100`}
    >
      {navLinks.map((navLink, index) => {
        const isDisabled = activeTabs && !activeTabs[navLink.dataKey];
        return (
          <NavLink
            key={index}
            className={({ isActive }) =>
              `uppercase border-solid border-0 border-b-2   py-2 px-4 mb-[-2px]   ${
                isActive
                  ? "text-blue-500 border-blue-500"
                  : isDisabled
                  ? "text-gray-500 border-gray-100 cursor-auto"
                  : "text-gray-900 border-gray-100 hover:border-blue-500"
              }`
            }
            to={navLink.to}
            onClick={(e) => handleClick(e, isDisabled)}
          >
            {t(`BuildingPassportSharedLayout.${navLink.linkName}`)}
          </NavLink>
        );
      })}
    </Stack>
  );
};
