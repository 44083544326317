import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import exit from "../Asset/Icons/exit.svg";
import { Breadcrumb } from "./Breadcrumb";
import { ButtonNew } from "./ButtonNew";
import { logoutUser } from "../api/functions/all";
import { Icon } from "./Icon";

const Header = ({ className, breadcrumbs }) => {
  const { t } = useTranslation();
  const { isLoggingOut } = useSelector((store) => store.user);

  return (
    <header
      className={`${className} flex justify-between items-center h-12 mb-6 border-b-blue-50 bg-white `}
    >
      <Breadcrumb breadcrumbs={breadcrumbs}></Breadcrumb>

      <ButtonNew
        onClick={() => logoutUser(t("SharedLayout.header.logoutToast"))}
        variant="tertiary"
        size="md"
        disabled={isLoggingOut}
      >
        <Stack className="cursor-pointer">
          <p className="mr-1.5 t-heading-s sentence-case">
            {t("SharedLayout.header.logout")}
          </p>
          <Icon className="exit" iconName="Exit" />
        </Stack>
      </ButtonNew>
    </header>
  );
};

export default Header;
