export const resources = {
  NAVBAR: {
    SETTINGS: "NAVBAR.SETTINGS",
  },
  BUILDING_PROFILE: {
    UPLOAD_IMAGE: "BUILDING_PROFILE.UPLOAD_IMAGE",
    DOWNLOAD_FILES: "BUILDING_PROFILE.DOWNLOAD_FILES",
    THREE_D_VIEWER: "BUILDING_PROFILE.THREE_D_VIEWER",
  },
  TECHNICAL_DATA: {
    EDIT_TABLE_CELL: "TECHNICAL_DATA.EDIT_TABLE_CELL",
    REMOVE_ROW: "TECHNICAL_DATA.REMOVE_ROW",
    SELECT_COLUMNS: "TECHNICAL_DATA.SELECT_COLUMNS",
    DOWNLOAD_FILES: "TECHNICAL_DATA.DOWNLOAD_FILES",
    UPLOAD_FILES: "TECHNICAL_DATA.UPLOAD_FILES",
  },
  ENERGY_PERFORMANCE: {
    DOWNLOAD_FILES: "ENERGY_PERFORMANCE.DOWNLOAD_FILES",
  },
  CERTIFICATE: {
    DOWNLOAD_FILES: "CERTIFICATE.DOWNLOAD_FILES",
  },
  MODERNIZATION: {
    DOWNLOAD_SCENARIOS: "MODERNIZATION.DOWNLOAD_SCENARIOS",
    CUSTOM_SCENARIO_UPLOAD: "MODERNIZATION.CUSTOM_SCENARIO_UPLOAD",
  },
};
