import { ButtonNew } from "../ButtonNew";
import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { Table } from "../Table";
import apiUrls from "../../api/urls";
import { customApiCall } from "../../utils/axios";
import { convertDatetimeStringToDate, nDaysOld } from "../../utils/date";
import { Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { theme } from "../../styles/theme";
import { IsAllowed } from "../../acl";
import { IconButton } from "../IconButton";
import { useCheckFloorPlanAllFilesStatus } from "../../api/hooks/allHooks";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";
import { ComponentsCommonHeader } from "../ComponentsCommonHeader";

const downloadBuildingPassportFiles = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.download(projectId);
  const fileName = "Floor plan.zip";
  const errorMessage = i18next.t("general.downloadFileProblem");
  customApiCall.download({ url, fileName, errorMessage });
};

export const BuildingProfileTable = ({
  className,
  projectId,
  buildingFiles,
  isLoading,
  tableClassName,
}) => {
  const { t } = useTranslation();
  const { data: filesStatus } = useCheckFloorPlanAllFilesStatus(projectId);

  const filesTableColumns = [
    {
      label: t("BuildingProfile.table.columns.name"),
      dataKey: "name",
      cellType: null,
      valueTransformation: (row) => row["name"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("BuildingProfile.table.columns.type"),
      dataKey: "type",
      cellType: null,
      valueTransformation: (row) => row["type"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("BuildingProfile.table.columns.date modified"),
      dataKey: "_updated_at",
      cellType: null,
      subValueTransformation: (row) => null,
      valueTransformation: (row) => {
        return convertDatetimeStringToDate(row["_updated_at"]);
      },
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("BuildingProfile.table.columns.files"),
      dataKey: "file_url",
      cellType: "downloadFile",
      valueTransformation: (row) => (
        <IconButton
          iconProps={{
            color: "var(--clr-secondary-blue-500)",
            iconName: "Download",
            size: "md",
          }}
          buttonProps={{
            variant: "tertiary",
            size: "sm",
            disabled: !row.file_url,
          }}
        />
      ),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => row["file_url"],
    },
  ];
  return (
    <Paper className={`${className}   relative min-h-[20rem]`}>
      <ComponentsCommonHeader
        action={() => downloadBuildingPassportFiles(projectId)}
        actionIsDisabled={filesStatus?.status !== 200}
        actionText={t("BuildingProfile.table.downloadAllFiles")}
        hasActionAccess={IsAllowed("BUILDING_PROFILE.DOWNLOAD_FILES")}
        title={t("BuildingProfile.table.title")}
      />
      <LoadingOrEmptyComponent showLoading={isLoading} />
      {!isLoading && (
        <Table
          className={`mt-6 ${tableClassName}`}
          data={buildingFiles}
          headers={filesTableColumns}
          idColumnName="id"
        ></Table>
      )}
    </Paper>
  );
};
