import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { customApiCall } from "../../utils/axios";
import {
  deleteChildFilesRow,
  deleteParentFilesRow,
  getChildFiles,
  getDashboardNew,
  getParentFiles,
  postChildFiles,
  postParentFiles,
  postTranslation,
} from "../functions/all";
import apiUrls from "../urls";
import { useMemo } from "react";
import { getMonthString, getWeekdayString } from "../../utils/date";

// ==========================
// Hooks Skleton
// ==========================
const useGeneralGetHook = (cacheName, url, ...rest) => {
  return useQuery(cacheName, () => customApiCall.get({ url }), {
    enabled: true,
    ...rest,
  });
};

const useGeneralHeadHook = (cacheName, url) => {
  return useQuery(cacheName, () => customApiCall.head({ url }));
};

// ==========================
// Common Hooks
// ==========================
export const useGetBuilding = (projectId) => {
  const url = apiUrls.assets.get(projectId);
  return useGeneralGetHook(["building2", projectId], url);
};

export const useGetBulidingActiveTabs = (projectId) => {
  const url = apiUrls.assets.getPages(projectId);
  const { data, isLoading, isError } = useGeneralGetHook(
    ["ActiveTabs", projectId],
    url
  );
  const filteredData = data?.find((building) => {
    return building?.project_id === Number(projectId);
  });
  return { data: filteredData || {}, isLoading, isError };
};

export const useGetBuildingCertificates = (projectId) => {
  const url = apiUrls.certificates.list(projectId);
  const { data, isLoading } = useGeneralGetHook(["certs2", projectId], url);
  const energyCertificates = (data || []).filter((e) => e.type === "EU EPC");
  const nonEnergyCertificates = (data || []).filter((e) => e.type !== "EU EPC");
  return { data: { energyCertificates, nonEnergyCertificates }, isLoading };
};
export const useCheckAllFilesStatus = (projectId, pageName) => {
  const isTechnical = pageName === "technicalData";
  const url = apiUrls.files.folders.download(projectId, isTechnical);
  return useGeneralHeadHook(["allFilesExistence", projectId, pageName], url);
};
// ==========================
// Asset Overview
// ==========================
export const useGetAllBuildings = (UserId) => {
  const url = apiUrls.assets.list(UserId);
  return useGeneralGetHook(["allBuildings", UserId], url);
};

// ==========================
// Building Profile
// ==========================
export const useGetBuildingFiles = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.list(projectId);
  return useGeneralGetHook(["buildingFiles", projectId], url);
};
export const useGetBuildingKpis = (projectId) => {
  const url = apiUrls.kpis.list(projectId);
  return useGeneralGetHook(["kpis", projectId], url);
};
export const useCheckFloorPlanAllFilesStatus = (projectId) => {
  const url = apiUrls.layerFiles.buildingPassport.download(projectId);
  return useGeneralHeadHook(["floorPlanAllFiles", projectId], url);
};
export const useGetBulidingUsage = (projectId) => {
  const url = apiUrls.buildingUsage.list(projectId);
  return useGeneralGetHook(["bulidingUsage", projectId], url);
};

// ==========================
// Tech Data
// ==========================
export const useGetTechData = ({ projectId, tabNames }) => {
  const allParentsFiles = useQueries(
    tabNames.map((tab) => {
      return {
        queryKey: ["techDataAllParents", tab, projectId],
        queryFn: () => getParentFiles({ activeTab: tab, projectId }),
        select: (data) => {
          const newData = data?.map((row) => {
            const hasFiles = row?.layers.some((layer) => layer.file_url);
            return { ...row, hasFiles };
          });
          return { data: newData, tab };
        },
      };
    })
  );
  const isLoading = allParentsFiles?.some((result) => result.isLoading);
  return { data: allParentsFiles, isLoading };
};

export const useGetChildrenFiles = ({ childIds, activeTab }) => {
  const childrenFiles = useQueries(
    (childIds || [])?.map((id) => {
      return {
        queryKey: ["techDataChild", activeTab, id],
        queryFn: () => getChildFiles({ activeTab, layerId: id }),
        select: (data) => {
          return { ...data, id };
        },
      };
    })
  );
  const isLoading = childrenFiles?.some((result) => result.isLoading);

  return { data: childrenFiles, isLoading };
};

export const useModifyParentTableCell = () => {
  const queryClient = useQueryClient();
  return useMutation(postParentFiles, {
    onMutate: async (inputs) => {
      const { activeTab, projectId, rowId, data } = inputs;
      await queryClient.cancelQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      const previousData = queryClient.getQueryData([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      queryClient.setQueryData(
        ["techDataAllParents", activeTab, projectId],
        (oldData) => {
          const newData = oldData?.payload?.map((row) => {
            if (row.id === rowId) return { ...row, ...data };
            else {
              return row;
            }
          });
          return { payload: newData };
        }
      );
      return { previousData, activeTab, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["techDataAllParents", context.activeTab, context.projectId],
        context.previousData
      );
    },
    // onSettled: (_data, _error, _variables, context) => {
    //   const { projectId } = context;
    //   queryClient.invalidateQueries(["allMeasures", projectId]);
    //   queryClient.invalidateQueries(["selectedMeasures", projectId]);
    // },
    onSettled: (inputs, _error, _variables, context) => {
      const { projectId, activeTab } = context;
      queryClient.invalidateQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
    },
  });
};

export const useDeleteParentTableRow = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteParentFilesRow, {
    onMutate: async (inputs) => {
      const { activeTab, projectId, rowId } = inputs;
      await queryClient.cancelQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      const previousData = queryClient.getQueryData([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      queryClient.setQueryData(
        ["techDataAllParents", activeTab, projectId],
        (oldData) => {
          const newData = oldData?.payload?.filter((row) => row.id !== rowId);
          return { payload: newData };
        }
      );
      return { previousData, activeTab, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["techDataAllParents", context.activeTab, context.projectId],
        context.previousData
      );
    },
    onSettled: (inputs) => {
      const { activeTab, projectId } = inputs;
      queryClient.invalidateQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
    },
  });
};

export const useModifyChildTableCell = () => {
  const queryClient = useQueryClient();
  return useMutation(postChildFiles, {
    onMutate: async (inputs) => {
      const { activeTab, projectId, rowId, layerId, data } = inputs;
      await queryClient.cancelQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      const previousData = queryClient.getQueryData([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      queryClient.setQueryData(
        ["techDataAllParents", activeTab, projectId],
        (oldData) => {
          const newData = oldData?.payload?.map((parentRow) => {
            if (parentRow.id === rowId) {
              const newLayers = parentRow?.layers?.map((childRow) => {
                if (childRow.id === layerId) return { ...childRow, ...data };
                return childRow;
              });
              return { ...parentRow, layers: newLayers };
            }
            return parentRow;
          });
          return { payload: newData };
        }
      );
      return { previousData, activeTab, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["techDataAllParents", context.activeTab, context.projectId],
        context.previousData
      );
    },
    onSettled: (inputs) => {
      const { activeTab, projectId, data } = inputs;

      queryClient.invalidateQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
    },
  });
};

export const useDeleteChildTableRow = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteChildFilesRow, {
    onMutate: async (inputs) => {
      const { activeTab, projectId, rowId, layerId } = inputs;
      await queryClient.cancelQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      const previousData = queryClient.getQueryData([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
      queryClient.setQueryData(
        ["techDataAllParents", activeTab, projectId],
        (oldData) => {
          const newData = oldData?.payload?.map((parentRow) => {
            if (parentRow.id === rowId) {
              const newLayers = parentRow?.layers?.filter(
                (childRow) => childRow.id !== layerId
              );
              return { ...parentRow, layers: newLayers };
            }
            return parentRow;
          });
          return { payload: newData };
        }
      );
      return { previousData, activeTab, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["techDataAllParents", context.activeTab, context.projectId],
        context.previousData
      );
    },
    onSettled: (inputs) => {
      const { activeTab, projectId } = inputs;
      queryClient.invalidateQueries([
        "techDataAllParents",
        activeTab,
        projectId,
      ]);
    },
  });
};

export const useCheckTechDataDownloadFilesStatus = (projectId, activeTab) => {
  const url = apiUrls.layerFiles.techData[activeTab].downloadAll(projectId);
  return useGeneralHeadHook(["TechDataFiles", projectId, activeTab], url);
};
// ==========================
// Energy Performance
// ==========================
export const useGetPerformanceIndicator = (projectId) => {
  const url = apiUrls.performanceIndicator.list({
    projectId,
  });
  return useGeneralGetHook(["performanceIndicator", projectId], url);
};

export const useGetEnergyMix = (projectId) => {
  const url = apiUrls.energyMix.list({ projectId });
  return useGeneralGetHook(["energyMix", projectId], url);
};

export const useGetDashboard = (inputs) => {
  const { projectId, quantity, startDate, endDate, interval, enabled } = inputs;
  const url = apiUrls.energyPerformance[interval].list({
    projectId,
    quantity,
    startDate,
    endDate,
  });
  return useGeneralGetHook(
    ["dashboard", projectId, quantity, startDate, endDate, interval],
    url,
    { enabled }
  );
};

const addExtraFieldsToDashboardData = (data, interval) => {

  if (!data?.length || !interval) return;
  const output = data.map((row, ind) => {

    let dateTimeString = row.datetime;
    if (interval === "monthly") dateTimeString += "-01 00:00:00";
    if (interval === "daily") dateTimeString += " 00:00:00";
    const parts = dateTimeString.split(/[- :]/);
    const dateTime = new Date(
      Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5])
    );

    const year = dateTime.getUTCFullYear();
    const yearMonth = dateTime.toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    });
    const monthNumber = dateTime.getUTCMonth() + 1;
    const month = getMonthString(monthNumber - 1);
    const dayOfMonth = dateTime.getUTCDate();
    const hourOfDay = dateTime.getUTCHours();

    const dayOfWeekInteger = dateTime.getUTCDay();
    const dayOfWeek = getWeekdayString(dayOfWeekInteger);

    const startOfYear = new Date(
      Date.UTC(dateTime.getUTCFullYear(), 0, 1, 0, 0, 0)
    );
    const timeDifference = dateTime - startOfYear;
    const dayOfYear = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
    const hourOfYear = Math.floor(timeDifference / (1000 * 60 * 60));

    return {
      ...row,
      year,
      yearMonth,
      month,
      monthNumber,
      dayOfYear,
      dayOfMonth,
      dayOfWeek,
      hourOfDay,
      hourOfYear,
    };
  });
  return output;
};

export const useGetDashboardNew = (inputs) => {
  const {
    projectId,
    quantities,
    scenarios,
    startDate,
    endDate,
    interval,
    dataLevel,
    enabled,
  } = inputs;
  const allSegments = [];
  scenarios.forEach((scenario) => {
    quantities.forEach((quantity) => {
      allSegments.push({ scenario, quantity });
    });
  });
  const dashboardNew = useQueries(
    allSegments.map(({ quantity, scenario }) => {
      const apiCallInputs = {
        projectId,
        quantity,
        scenario,
        startDate,
        endDate,
        interval,
        dataLevel,
      };
      return {
        queryKey: [
          "dashboardNew",
          scenario,
          dataLevel,
          quantity,
          projectId,
          startDate,
          endDate,
          interval,
        ],
        enabled,
        queryFn: () => getDashboardNew(apiCallInputs),
        select: (data) => {
          const newData = data?.map((row) => {
            return { ...row, scenario, dataPoint: quantity };
          });
          return newData;
        },
      };
    })
  );

  const isLoading = dashboardNew?.some((result) => result.isLoading);
  const isError = dashboardNew?.every((result) => result.isError);
  const mergedData = dashboardNew.reduce((total, quantityScenario) => {
    return quantityScenario?.status === "success"
      ? [...total, ...quantityScenario?.data]
      : total;
  }, []);
  const enrichedData = useMemo(
    () => addExtraFieldsToDashboardData(mergedData, interval),
    [JSON.stringify(inputs) + `${mergedData?.length}`]
  );

  return { data: enrichedData, isLoading, isError };
};

export const useGetProjectInfo = (projectId, initialData) => {
  const url = apiUrls.energyPerformanceNew.projectInfo.list(projectId);
  return useGeneralGetHook(["projectInfo", projectId], url, { initialData });
};

// ==========================
// Certificates
// ==========================
export const useGetCertificateStatus = (projectId) => {
  const url = apiUrls.certificates.certificationStatus.list({
    projectId,
  });
  return useGeneralGetHook(["certificateStatus", projectId], url);
};

export const useGetEnergyRatingStatus = (projectId) => {
  const url = apiUrls.certificates.energyRatingStatus.list({
    projectId,
  });
  return useGeneralGetHook(["energyRatingStatus", projectId], url);
};
export const useCheckCertificatesDownloadFilesStatus = (
  projectId,
  certificateType
) => {
  const url = apiUrls.files.certificates.download(projectId, certificateType);
  return useGeneralHeadHook(
    ["certificatesFiles", projectId, certificateType],
    url
  );
};
// ==========================
// Modernization
// ==========================
export const useGetGoals = (projectId) => {
  const url = apiUrls.modernization.goals.list(projectId);
  return useGeneralGetHook(["getGoals", projectId], url);
};

export const useGetScenarios = (projectId) => {
  const url = apiUrls.modernization.scenarios.list(projectId);
  return useGeneralGetHook(["getScenarios", projectId], url);
};
export const useCheckScenariosFilesStatus = (projectId) => {
  const url = apiUrls.modernization.scenarioFiles.download(projectId);
  return useGeneralHeadHook(["scenariosFiles", projectId], url);
};
// ==========================
// Translations
// ==========================
export const useGetTranslations = () => {
  const url = apiUrls.translation.get("de");
  return useGeneralGetHook("getTranslations", url);
};

export const useModifyTranslation = () => {
  const queryClient = useQueryClient();
  return useMutation(postTranslation, {
    onMutate: async (inputs) => {
      const { data } = inputs;
      await queryClient.cancelQueries("getTranslations");
      const previousData = queryClient.getQueryData("getTranslations");
      queryClient.setQueryData("getTranslations", (oldData) => {
        return { ...oldData, ...data };
      });
      return { previousData };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData("getTranslations", context.previousData);
    },
    onSuccess: () => {
      toast.success("Successfully modified.");
    },
    onSettled: (inputs) => {
      queryClient.invalidateQueries("getTranslations");
    },
  });
};

// ============== CRREM TOOL
export const useGetCrrem = (projectId, degree, parameter) => {
  const url = apiUrls.modernization.crrem.list({
    projectId,
    degree,
    parameter,
  });
  return useGeneralGetHook(["crrem", projectId, degree, parameter], url);
};

//=============================
// Wizard
//============================
export const useGetAllMeasures = (projectId) => {
  const url = apiUrls.modernization.retrofits.list(projectId);
  return useGeneralGetHook(["allMeasures", projectId], url);
};

export const postMeasure = async ({ data }) => {
  const url = apiUrls.modernization.retrofits.post;
  const resp = await customApiCall.post({ url, data });
  return resp;
};

export const deleteMeasure = async ({ rowId }) => {
  const url = apiUrls.modernization.retrofits.get(rowId);
  const resp = await customApiCall.delete({ url });
  return resp;
};

export const modifyMeasure = async ({ rowId, data }) => {
  const url = apiUrls.modernization.retrofits.get(rowId);
  const resp = await customApiCall.patch({ url, data });
  return resp;
};

export const useAddMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(postMeasure, {
    onMutate: async (inputs) => {
      const {
        projectId,
        data,
        onSettled: onReqSettled,
        onSuccess: onReqSuccess,
      } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = [...oldData];
        newData.push({ ...data, id: -2 });
        return newData;
      });
      return { previousData, projectId, onReqSettled, onReqSuccess };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["allMeasures", context.projectId],
        context.previousData
      );
    },
    onSuccess: (_data, _error, context) => {
      const { onReqSuccess } = context;
      onReqSuccess();
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId, onReqSettled } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      onReqSettled();
    },
  });
};

export const useDeleteMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMeasure, {
    onMutate: async (inputs) => {
      const { projectId, rowId } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = oldData?.filter((row) => row.id !== rowId);
        return newData;
      });
      return { previousData, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["allMeasures", context.projectId],
        context.previousData
      );
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useModifyMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(modifyMeasure, {
    onMutate: async (inputs) => {
      const { projectId, rowId, data } = inputs;
      await queryClient.cancelQueries(["allMeasures", projectId]);
      const previousData = queryClient.getQueryData(["allMeasures", projectId]);
      queryClient.setQueryData(["allMeasures", projectId], (oldData) => {
        const newData = oldData?.map((row) => {
          if (row.id === rowId) return { ...row, ...data };
          else {
            return row;
          }
        });
        return newData;
      });
      return { previousData, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["allMeasures", context.projectId],
        context.previousData
      );
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["allMeasures", projectId]);
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

// ========= User Selected Measures
export const useGetAllSelectedMeasures = (projectId) => {
  const url = apiUrls.modernization.selectedRetrofit.list(projectId);
  return useGeneralGetHook(["selectedMeasures", projectId], url);
};

export const postSelectedMeasure = async ({ data }) => {
  const url = apiUrls.modernization.selectedRetrofit.post;
  const resp = await customApiCall.post({ url, data });
  return resp;
};

export const deleteSelectedMeasure = async ({ rowId }) => {
  const url = apiUrls.modernization.selectedRetrofit.get(rowId);
  const resp = await customApiCall.delete({ url });
  return resp;
};

export const modifySelectedMeasure = async ({ rowId, data }) => {
  const url = apiUrls.modernization.selectedRetrofit.get(rowId);
  const resp = await customApiCall.patch({ url, data });
  return resp;
};

export const useAddSelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(postSelectedMeasure, {
    onMutate: async (inputs) => {
      const { projectId, data } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData([
        "selectedMeasures",
        projectId,
      ]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = [...oldData];
        newData.push({ ...data, id: -2 });
        return newData;
      });
      return { previousData, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["selectedMeasures", context.projectId],
        context.previousData
      );
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useDeleteSelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSelectedMeasure, {
    onMutate: async (inputs) => {
      const { projectId, rowId } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData([
        "selectedMeasures",
        projectId,
      ]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = oldData?.filter((row) => row.id !== rowId);
        return newData;
      });
      return { previousData, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["selectedMeasures", context.projectId],
        context.previousData
      );
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};

export const useModifySelectedMeasure = () => {
  const queryClient = useQueryClient();
  return useMutation(modifySelectedMeasure, {
    onMutate: async (inputs) => {
      const { projectId, rowId, data } = inputs;
      await queryClient.cancelQueries(["selectedMeasures", projectId]);
      const previousData = queryClient.getQueryData([
        "selectedMeasures",
        projectId,
      ]);
      queryClient.setQueryData(["selectedMeasures", projectId], (oldData) => {
        const newData = oldData?.map((row) => {
          if (row.id === rowId) return { ...row, ...data };
          else {
            return row;
          }
        });
        return newData;
      });
      return { previousData, projectId };
    },
    onError: (error, _output, context) => {
      toast.error(JSON.stringify(error?.response?.data));
      queryClient.setQueryData(
        ["selectedMeasures", context.projectId],
        context.previousData
      );
    },
    onSettled: (_data, _error, _variables, context) => {
      const { projectId } = context;
      queryClient.invalidateQueries(["selectedMeasures", projectId]);
    },
  });
};
