export default {
  card: {
    title: "building information",
    "ASSET NAME": "ASSET NAME",
    TYPE: "TYPE",
    "ASSET ID": "ASSET ID",
    ADDRESS: "ADDRESS",
    "YEAR OF CONSTRUCTION": "YEAR OF CONSTRUCTION",
    "MODERNIZATION YEAR": "MODERNIZATION YEAR",
    "GROSS ASSET SIZE": "GROSS ASSET SIZE",
    "GROSS ASSET VALUE": "GROSS ASSET VALUE",
  },
  table: {
    title: "Building passport files",
    downloadAllFiles: "Download all files",
    columns: {
      name: "name",
      type: "type",
      "date modified": "date modified",
      files: "files",
    },
  },
  kpis: {
    title: "KPIs",
    totalEnergyConsumption: "total energy consumption",
    totalCarbonEmission: "total carbon emission",
    kpisNotListed: "KPIs not listed.",
  },
  certificateCard: {
    certificateTitle: "Certificates",
    energyRatingTitle: "EU EPC rating certificates",
    "Allotment date": "Allotment date",
    Area: "Area",
    "Valid until": "Valid until",
  },
};
