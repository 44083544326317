import { Modal, Stack } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  useGetBuilding,
  useGetDashboardNew,
  useGetProjectInfo,
} from "../../api/hooks/allHooks";
import { deepEqual, minMaxByKey } from "../../utils/dataManipulation";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { TfiSave } from "react-icons/tfi";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import { ButtonNew } from "../ButtonNew";
import { DynamicChart, DynamicChartFullScreenHandler } from "../chart/DynamicChart";
import { diagramInitialConfig } from "../chart/DynamicChartMemoized";
import { DropdownPure, DropdownSingleSelect } from "../DropdownSingleSelect";
// import { GeneralErrorBoundary } from "../ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary"
import { Dropdown } from "../Dropdown";
import { CustomModal } from "../CustomModal";

const ResponsiveGridLayout = WidthProvider(Responsive);


const defaultDashboardConfigs0 = [
  {
    type: "1",
    id: 0,
    x: 0,
    y: 0,
    w: 12,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: "sensor",
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "line",
    title: "Energy Demand",
    xAxis: "hourOfYear",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "hourly",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-01-31T00:00:00.000Z",
    selectedUtilities: [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg",
      "hotWaterDemand_avg",
    ],
    selectedScenarios: ["Timestamps Occupancy-60"],
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 1,
    x: 0,
    y: 5,
    w: 6,
    h: 6,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: "loadsLuminanceInternal_avg",
        sensorDisplayText: null,
        diagramType: "scatter",
        color: "#ef0b0b",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "loadsSolarRadiation_avg",
        sensorDisplayText: null,
        diagramType: "scatter",
        color: "#197bd7",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "loadsVentilation_avg",
        sensorDisplayText: null,
        diagramType: "scatter",
        color: "black",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "composed",
    title: "Cloud Diagram",
    xAxis: "loadsConduction_avg",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-02-26T00:00:00.000Z",
    selectedUtilities: null,
    selectedScenarios: ["Timestamps Occupancy-60"],
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 2,
    x: 6,
    y: 5,
    w: 6,
    h: 6,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: "ventilationInfiltrationFlowRate_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "ventilationInfiltrationTemperature_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "#f20d0d",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "ventilationMechanicalFlowRate_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "#5a0fe6",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "histogram",
    title: "Ventilation Histogram",
    xAxis: null,
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-03-30T23:00:00.000Z",
    selectedUtilities: null,
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 3,
    x: 6,
    y: 11,
    w: 6,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: "ventilationInfiltrationTemperature_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "#e60a0a",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "ventilationMechanicalTemperature_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "#1375f6",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
      {
        dataPoint: null,
        sensor: "ventilationNaturalTemperature_avg",
        sensorDisplayText: null,
        diagramType: null,
        color: "#1fef5d",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "histogram",
    title: "Ventilation Histogram Working Hours",
    xAxis: null,
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "hourly",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-01-31T00:00:00.000Z",
    selectedUtilities: null,
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 4,
    x: 0,
    y: 11,
    w: 6,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: "sensor",
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: "All values",
    chartType: "line",
    title: "Energy  load",
    xAxis: "dayOfYear",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-12-31T00:00:00.000Z",
    selectedUtilities: [
      "heatingDemand_avg",
      "coolingDemand_avg",
      "electricityDemand_avg",
      "hotWaterDemand_avg",
      "loadsConduction_avg",
    ],
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 5,
    x: 0,
    y: 16,
    w: 4,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: null,
        stacks: "sensor",
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "bar",
    title: "Annual Energy Demand",
    xAxis: "",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-07-30T23:00:00.000Z",
    selectedUtilities: [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg",
      "hotWaterDemand_avg",
    ],
    selectedScenarios: ["Timestamps Occupancy-60"],
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 6,
    x: 4,
    y: 16,
    w: 8,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: null,
        stacks: "sensor",
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "bar",
    title: "Monthly Energy Demand",
    xAxis: "yearMonth",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: null,
    endDate: null,
    selectedUtilities: [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg",
      "hotWaterDemand_avg",
    ],
    selectedScenarios: ["Timestamps Occupancy-60"],
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 7,
    x: 0,
    y: 21,
    w: 5,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: "sensor",
        stacks: "scenario",
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "pie",
    title: null,
    xAxis: null,
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: null,
    endDate: null,
    selectedUtilities: [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg",
      "hotWaterDemand_avg",
    ],
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 8,
    x: 5,
    y: 21,
    w: 7,
    h: 5,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "#11e30d",
        partitions: null,
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "heatmap",
    title: null,
    xAxis: "month",
    yAxis: "dayOfWeek",
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "daily",
    startDate: null,
    endDate: null,
    selectedUtilities: null,
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
  {
    type: "1",
    id: 9,
    x: 0,
    y: 26,
    w: 12,
    h: 7,
    minW: 3,
    minH: 3,
    maxH: 20,
    draggable: true,
    diagrams: [
      {
        dataPoint: null,
        sensor: null,
        sensorDisplayText: null,
        diagramType: null,
        color: "black",
        partitions: "sensor",
        stacks: null,
        x: null,
        percentageBased: false,
        direction: "horizontal",
        title: null,
      },
    ],
    sortValue: null,
    chartType: "line",
    title: "Thermal Load Profile",
    xAxis: "hourOfYear",
    yAxis: null,
    dataPoint: "consumption",
    dataLevel: "building",
    timeframe: "hourly",
    startDate: "2020-01-01T00:00:00.000Z",
    endDate: "2020-02-29T00:00:00.000Z",
    selectedUtilities: [
      "loadsConduction_avg",
      "loadsLuminanceInternal_avg",
      "loadsPeopleInternal_avg",
      "loadsSolarRadiation_avg",
      "loadsTechnicalAppliancesInternal_avg",
      "loadsVentilation_avg",
      "loadsWallStorage_avg",
    ],
    selectedScenarios: null,
    selectedDaysOfWeek: [],
    selectedHoursOfDay: [],
  },
];

const defaultDashboardConfigs = [
  {
    "type": "1",
    "id": 0,
    "x": 0,
    "y": 8,
    "w": 12,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "line",
    "title": "Energy Demand",
    "xAxis": "hourOfYear",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "hourly",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-06-29T23:00:00.000Z",
    "selectedUtilities": [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg",
      "hotWaterDemand_avg"
    ],
    "selectedScenarios": [
      "Timestamps Occupancy-60"
    ],
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 1,
    "x": 0,
    "y": 14,
    "w": 6,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": "loadsLuminanceInternal_avg",
        "sensorDisplayText": null,
        "diagramType": "scatter",
        "color": "#ea4d4d",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      },
      {
        "dataPoint": null,
        "sensor": "loadsSolarRadiation_avg",
        "sensorDisplayText": null,
        "diagramType": "scatter",
        "color": "#65abec",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      },
      {
        "dataPoint": null,
        "sensor": "loadsVentilation_avg",
        "sensorDisplayText": null,
        "diagramType": "scatter",
        "color": "#3fff0a",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "composed",
    "title": "Cloud Diagram",
    "xAxis": "loadsConduction_avg",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-02-26T00:00:00.000Z",
    "selectedUtilities": null,
    "selectedScenarios": [
      "Timestamps Occupancy-60"
    ],
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 2,
    "x": 6,
    "y": 14,
    "w": 6,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": "ventilationInfiltrationTemperature_avg",
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "#f20d0d",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      },
      {
        "dataPoint": null,
        "sensor": "ventilationNaturalTemperature_avg",
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "#2ce407",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "histogram",
    "title": "Ventilation Histogram",
    "xAxis": null,
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-03-30T23:00:00.000Z",
    "selectedUtilities": null,
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 4,
    "x": 0,
    "y": 20,
    "w": 12,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": "All values",
    "chartType": "line",
    "title": "Energy  load",
    "xAxis": "dayOfYear",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-12-31T00:00:00.000Z",
    "selectedUtilities": [
      "heatingDemand_avg",
      "coolingDemand_avg",
      "electricityDemand_avg",
      "hotWaterDemand_avg",
      "loadsConduction_avg"
    ],
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 5,
    "x": 4,
    "y": 0,
    "w": 8,
    "h": 8,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": "scenario",
        "x": null,
        "percentageBased": false,
        "direction": "vertical",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "bar",
    "title": "Annual Energy Demand",
    "xAxis": "",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-07-30T23:00:00.000Z",
    "selectedUtilities": [
      "loadsLuminanceInternal_avg",
      "loadsSolarRadiation_avg",
      "loadsTechnicalAppliancesInternal_avg",
      "loadsWallStorage_avg"
    ],
    "selectedScenarios": [
      "Timestamps Occupancy-60",
      "Timestamps Occupancy-0"
    ],
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "right"
  },
  {
    "type": "1",
    "id": 6,
    "x": 0,
    "y": 26,
    "w": 12,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": "scenario",
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "bar",
    "title": "Monthly Energy Demand",
    "xAxis": "yearMonth",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": null,
    "endDate": null,
    "selectedUtilities": [
      "ventilationInfiltrationTemperature_avg",
      "ventilationMechanicalFlowRate_avg",
      "ventilationMechanicalTemperature_avg"
    ],
    "selectedScenarios": [
      "Timestamps Occupancy-60",
      "Timestamps Occupancy-0"
    ],
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "right"
  },
  {
    "type": "1",
    "id": 7,
    "x": 6,
    "y": 32,
    "w": 6,
    "h": 7,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": "scenario",
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "doughnut",
    "title": "Ventilation Distribution",
    "xAxis": null,
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": null,
    "endDate": null,
    "selectedUtilities": [
      "ventilationMechanicalTemperature_avg",
      "ventilationNaturalTemperature_avg",
      "ventilationMechanicalFlowRate_avg",
      "ventilationInfiltrationTemperature_avg",
      "ventilationInfiltrationFlowRate_avg"
    ],
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "right"
  },
  {
    "type": "1",
    "id": 8,
    "x": 0,
    "y": 39,
    "w": 12,
    "h": 7,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "#ea0606",
        "partitions": null,
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "heatmap",
    "title": "Carpet Plot",
    "xAxis": "dayOfYear",
    "yAxis": "hourOfDay",
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "hourly",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-06-29T23:00:00.000Z",
    "selectedUtilities": null,
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 9,
    "x": 0,
    "y": 46,
    "w": 12,
    "h": 7,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "bar",
    "title": "Thermal Load Profile",
    "xAxis": "hourOfYear",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "hourly",
    "startDate": "2020-01-01T00:00:00.000Z",
    "endDate": "2020-06-29T23:00:00.000Z",
    "selectedUtilities": [
      "loadsPeopleInternal_avg",
      "loadsSolarRadiation_avg",
      "loadsTechnicalAppliancesInternal_avg",
      "loadsWallStorage_avg",
      "loadsVentilation_avg",
      "loadsLuminanceInternal_avg"
    ],
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": []
  },
  {
    "type": "1",
    "id": 10,
    "x": 0,
    "y": 53,
    "w": 12,
    "h": 6,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "scenario",
        "stacks": "dayOfWeek",
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "pie",
    "title": "Days Consumption Comparison",
    "xAxis": null,
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": null,
    "endDate": null,
    "selectedUtilities": null,
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "bottom"
  },
  {
    "type": "1",
    "id": 11,
    "x": 0,
    "y": 0,
    "w": 4,
    "h": 8,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "sensor",
        "stacks": "scenario",
        "x": null,
        "percentageBased": false,
        "direction": "vertical",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "kpi",
    "title": "Total Consumption",
    "xAxis": null,
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": null,
    "endDate": null,
    "selectedUtilities": [
      "coolingDemand_avg",
      "electricityDemand_avg",
      "heatingDemand_avg"
    ],
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "bottom"
  },
  {
    "type": "1",
    "id": 12,
    "x": 0,
    "y": 32,
    "w": 6,
    "h": 7,
    "minW": 3,
    "minH": 3,
    "maxH": 20,
    "draggable": true,
    "diagrams": [
      {
        "dataPoint": null,
        "sensor": null,
        "sensorDisplayText": null,
        "diagramType": null,
        "color": "black",
        "partitions": "scenario",
        "stacks": null,
        "x": null,
        "percentageBased": false,
        "direction": "horizontal",
        "title": null
      }
    ],
    "sortValue": null,
    "chartType": "area",
    "title": "Scenarios Monthly Consumption",
    "xAxis": "yearMonth",
    "yAxis": null,
    "dataPoint": "consumption",
    "dataLevel": "building",
    "timeframe": "daily",
    "startDate": null,
    "endDate": null,
    "selectedUtilities": null,
    "selectedScenarios": null,
    "selectedDaysOfWeek": [],
    "selectedHoursOfDay": [],
    "legendsPosition": "bottom"
  }
]
const defaultMinW = 3;
const defaultMinH = 3;

const defaultChart = {
  type: "1",
  // i: "0",
  id: 0,
  x: 0,
  y: 0,
  w: defaultMinW + 1,
  h: defaultMinH + 2,
  minW: defaultMinW,
  minH: defaultMinH,
  maxH: 20,
  draggable: true,
  diagrams: [diagramInitialConfig],
  sortValue: null,
  chartType: null,
  title: null,
  xAxis: null,
  yAxis: null,
  dataPoint: null,
  dataLevel: "building",
  timeframe: "daily",
  startDate: null,
  endDate: null,
  selectedUtilities: null,
  selectedScenarios: null,
  selectedDaysOfWeek: [],
  selectedHoursOfDay: [],
  legendsPosition: 'bottom'
};

const adjustConfigs = (configs) => {
  if (configs) {
    const adjustedConfigs = configs.map((config) => ({
      ...config,
      startDate: config?.startDate && new Date(config?.startDate),
      endDate: config?.endDate && new Date(config?.endDate),
    }));
    return adjustedConfigs;
  }
};

const loadDashboardConfigs = () => {
  let dashboardConfigs = localStorage.getItem("dashboardConfigs");
  if (dashboardConfigs) {
    dashboardConfigs = JSON.parse(dashboardConfigs);
  } else {
    dashboardConfigs = defaultDashboardConfigs;
  }
  return adjustConfigs(dashboardConfigs);
};

export const removeZeroFields = (data) => {
  if (!data?.length) return;
  let keys = Object.keys(data[0]);
  for (let key of keys) {
    let allZero = data.every((item) => item[key] === 0);
    if (allZero) {
      for (let obj of data) {
        delete obj[key];
      }
    }
  }
  return data;
};

const getUpdatedLayoutConfigs = (allChartsConfigs, newLayout) => {
  // return allChartsConfigs;
  return allChartsConfigs.map((chart) => {
    const newChart = newLayout.find(
      (newLayoutItem) =>
        newLayoutItem.i === `${chart.id}` || newLayoutItem.id === chart.id
    );
    if (!newChart) return chart;
    else {
      const { x, y, w, h } = newChart;
      return { ...chart, x, y, w, h };
    }
  });
};

const allChartsConfigsReducer = (state, action) => {
  const payload = action?.payload;
  const layout = payload?.layout;
  if (!layout) return state;
  const updatedLayoutState = getUpdatedLayoutConfigs(state, layout);
  if (action.type === "add") {
    if (!layout) return updatedLayoutState;
    const maxY = layout?.length
      ? Math.max(...layout?.map((l) => l.y + l.h))
      : -1;
    const maxId = layout?.length ? Math.max(...layout?.map((l) => l.id)) : -1;

    const newChart = {
      ...defaultChart,
      y: maxY + 1,
      id: maxId + 1,
    };
    return [...updatedLayoutState, newChart];
  } else if (action.type === "remove") {
    return updatedLayoutState.filter((l) => l.id !== payload.id);
  } else if (action.type === "update") {
    return updatedLayoutState.map((l) => {
      if (l.id !== payload.id) return l;
      else return { ...l, [payload.key]: payload.value };
    });
  }
};

export const DashboardNew = ({ }) => {
  const { t } = useTranslation();
  const [allChartsConfigs, setAllChartsConfigs] = useState(
    loadDashboardConfigs()
  );
  const { projectId } = useParams();

  const {
    data: projectInfo,
    isLoading: pIIsLoading,
    isSuccess,
  } = useGetProjectInfo(projectId, {});
  const projectInfoParsed = useMemo(() => {
    let scenarios = [],
      dataPoints = [],
      utilities = [],
      roomList = [];
    if (isSuccess) {
      try {
        scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
        dataPoints = JSON.parse(projectInfo[0].metrics);
        utilities = JSON.parse(projectInfo[0].utilities);
        roomList = JSON.parse(projectInfo[0].room_list);
      } catch { }
    }
    return { scenarios, dataPoints, utilities, roomList };
  }, [projectInfo]);

  const dataLevelList = ["building", ...(projectInfoParsed?.roomList ?? [])];
  const dataLevel = allChartsConfigs?.length
    ? allChartsConfigs[0].dataLevel
    : "building";

  let scenarios = [],
    dataPoints = [];

  if (isSuccess) {
    try {
      scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
      dataPoints = JSON.parse(projectInfo[0].metrics);
    } catch { }
  }

  const inputs = {
    quantities: dataPoints
      ?.filter((e) => e.level === "building")
      ?.map((e) => e.dataKey),
    scenarios: scenarios,
    projectId,
    startDate: new Date("2000-01-01"),
    endDate: new Date("2100-01-01"),
    interval: "daily",
    enabled: isSuccess,
    dataLevel: "building",
  };
  let { data, isLoading } = useGetDashboardNew(inputs);
  const generalDashboardData = useMemo(() => data || [], [
    projectInfo,
    isLoading,
  ]);

  const { min: minDate, max: maxDate } = minMaxByKey({
    data: generalDashboardData,
    key: "datetime",
  });

  const componentRef = useRef();

  const layout = useMemo(
    () =>
      allChartsConfigs.map((chart) => ({
        ...chart,
        i: `${chart.id}`,
      })),
    [allChartsConfigs]
  );
  const handleLayoutChange = (newLayout) => {

    setAllChartsConfigs((allChartsConfigs) =>
      allChartsConfigs.map((chart) => {
        const newChart = newLayout.find(
          (newLayoutItem) => newLayoutItem.i === `${chart.id}`
        );
        if (!newChart) return chart;
        else {
          const { x, y, w, h } = newChart;
          return { ...chart, x, y, w, h };
        }
      })
    );
  };
  const scrollRef = useRef();

  const addChart = () => {
    const maxY = allChartsConfigs?.length
      ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
      : -1;
    const maxId = allChartsConfigs?.length
      ? Math.max(...allChartsConfigs?.map((l) => l.id))
      : -1;
    // const maxY = allChartsConfigs?.length
    //   ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
    //   : -1;
    // const maxId = allChartsConfigs?.length
    //   ? Math.max(...allChartsConfigs?.map((l) => l.id))
    //   : -1;
    const newChart = {
      ...defaultChart,
      y: maxY + 1,
      // i: `${10 * maxId + 10}`,
      id: maxId + 1,
    };

    setAllChartsConfigs([...allChartsConfigs, newChart]);
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
    });
    // setNewRow(emptyNewRow);
  };

  // const addChart1 = () => {
  //   dispatchConfigs({ type: "add", payload: { layout: layoutRef.current } });

  //   scrollRef.current?.scrollIntoView({
  //     behavior: "smooth",
  //   });
  //   // setNewRow(emptyNewRow);
  // };

  const removeChart = (id) => {
    setAllChartsConfigs((allChartsConfigs) =>
      allChartsConfigs.filter((l) => l.id !== id)
    );
  };

  const updateChart = (id, key, value) => {
    setAllChartsConfigs((allChartsConfigs) => {
      const updatedCharts = allChartsConfigs.map((l) => {
        if (l.id !== id) return l;
        else return { ...l, [key]: value };
      });
      return updatedCharts;
    });
  };

  const fullScreenChart = (id) => {
    setFullScreenChartId(id)
  }

  const saveDashboardConfigs = () => {
    localStorage.setItem("dashboardConfigs", JSON.stringify(allChartsConfigs));
    b(!a);
  };

  const resetDashboardConfigs = () => {
    localStorage.removeItem("dashboardConfigs");
    setAllChartsConfigs(loadDashboardConfigs());
  };

  const dynamicChartsRowHeight = 50;
  const [a, b] = useState(false);

  const dashboardConfigsHasChanged = !deepEqual(
    loadDashboardConfigs(),
    allChartsConfigs
  );


  const defaultDashboardConfigsHasChanged = !deepEqual(
    adjustConfigs(defaultDashboardConfigs),
    allChartsConfigs
  );

  // useEffect(() => {
  //   dispatch(setConfigs(allChartsConfigs));
  // }, [allChartsConfigs]);
  const [fullScreenChartId, setFullScreenChartId] = useState(null)
  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="400px">
      <article
        className="bg-white mt-8 px-4 pb-4 rounded-xl overflow-hidden"
        ref={componentRef}
      >
        {/* <DashboardHeader
          isDownloadDisabled={!chartData?.length > 0}
          displayLabel={
            quantitiesConfig.find((i) => i.name === config.quantity)
              ?.displayLabel
          }
          componentRef={componentRef}
        /> */}
        {/* Dashboard body */}
        <Stack className="mb-2 p-2 justify-between">
          <span>
            {dashboardConfigsHasChanged && (
              <span className="t-heading-m">*</span>
            )}
            <span className="t-heading-l  ">{`${t(
              "EnergyPerformance.dashboard.title"
            )}`}</span>
          </span>

          <DropdownSingleSelect
            label="Data level"
            options={dataLevelList}
            displayOptions={dataLevelList}
            selectedValue={dataLevel}
            setSelectedValue={(value) => {
              allChartsConfigs.forEach((config) =>
                updateChart(config.id, "dataLevel", value)
              );
            }}
            className="w-64"
          />
          <Stack gap={4} className="items-center">
            {defaultDashboardConfigsHasChanged && (
              <ButtonNew
                variant="primary"
                size="md"
                onClick={resetDashboardConfigs}
              >
                <Stack gap={2}>
                  <GrPowerReset className="cursor-pointer w-4 h-4" />
                  <span>Reset To Default</span>
                </Stack>
              </ButtonNew>
            )}
            {dashboardConfigsHasChanged && (
              <>
                <ButtonNew
                  variant="primary"
                  size="md"
                  onClick={saveDashboardConfigs}
                >
                  <Stack gap={2}>
                    <TfiSave className="cursor-pointer w-4 h-4" />
                    <span>Save</span>
                  </Stack>
                </ButtonNew>
              </>
            )}
            <ButtonNew variant="primary" size="md" onClick={addChart}>
              +Add new chart
            </ButtonNew>
          </Stack>
        </Stack>

        <ResponsiveGridLayout
          draggableCancel=".cancelDrag"
          layouts={{ lg: layout }}
          onLayoutChange={handleLayoutChange}
          breakpoints={{ lg: 280, md: 0, sm: 0, xs: 0, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          rowHeight={dynamicChartsRowHeight}
          resizeHandles={["sw", "se"]}
          className="rounded-xl bg-gray-50"
        >
          {allChartsConfigs.map((item, i) => {
            const isFullScreen = fullScreenChartId === item.id
            // if (i === 2)
            return (
              // <GeneralErrorBoundary>
              // <ErrorBoundary fallback={<div>Something went wrong</div>}>
              // <div width='100vw' height='100vh' key={item.id} className="grid-item">

              //   <Modal aria-labelledby="modal-modal-title"
              //     aria-describedby="modal-modal-description" open={true} >
              //     <DynamicChart
              //       id={item.id}
              //       // setAllChartsConfigs={setAllChartsConfigs}
              //       // dispatchConfigs={(action) =>
              //       //   dispatchConfigs({
              //       //     ...action,
              //       //     payload: { ...action.payload, layout: layoutRef.current },
              //       //   })
              //       // }
              //       ///////
              //       generalData={generalDashboardData}
              //       w={item.w}
              //       height={dynamicChartsRowHeight * item.h}
              //       // onClose={() => removeChart(item.id)}
              //       updateChart={(key, value) => updateChart(item.id, key, value)}
              //       removeChart={() => removeChart(item.id)}
              //       minDate={minDate}
              //       maxDate={maxDate}
              //       xCoord={item.x}
              //       config={item}
              //       // setConfig={(key, value) => updateChart(item.id, key, value)}
              //       projectInfoParsed={projectInfoParsed}
              //     />
              //   </Modal>
              // </div>
              <div key={item.id} className='grid-item'>
                <DynamicChartFullScreenHandler isFullScreen={isFullScreen}>
                  <DynamicChart
                    id={item.id}
                    // setAllChartsConfigs={setAllChartsConfigs}
                    // dispatchConfigs={(action) =>
                    //   dispatchConfigs({
                    //     ...action,
                    //     payload: { ...action.payload, layout: layoutRef.current },
                    //   })
                    // }
                    ///////
                    generalData={generalDashboardData}
                    w={item.w}
                    height={dynamicChartsRowHeight * item.h}
                    // onClose={() => removeChart(item.id)}
                    updateChart={(key, value) => updateChart(item.id, key, value)}
                    removeChart={() => removeChart(item.id)}
                    minDate={minDate}
                    maxDate={maxDate}
                    xCoord={item.x}
                    isFullScreen={isFullScreen}
                    config={item}
                    // setConfig={(key, value) => updateChart(item.id, key, value)}
                    projectInfoParsed={projectInfoParsed}
                    fullScreenChart={fullScreenChart}
                  />
                </DynamicChartFullScreenHandler>
              </div>

              // </ErrorBoundary>
            );
          })}
        </ResponsiveGridLayout>
        <span ref={scrollRef} />
      </article>
    </LoadingOrEmptyWrapper>
  );
};
