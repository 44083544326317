import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { sumByKeys } from "../../utils/dataManipulation";
import { addCommaToNumber } from "../../utils/namesManipulation";
import LegendItem from "../chart/LegendItem";
import { withDashboardChartsPrecalculator } from "./DashboardChartsPrecalculator";
import { useParams } from "react-router-dom";

const SingleLegendGroup = ({ className, resources, unit, total, type }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20;
  const bobHam = Number(projectId) == 18;

  return (
    <ul className={className}>
      <p className=" t-label-m2-caps text-gray-400">
        <span className="uppercase">
          {!(newProject || bobHam)
            ? t(`EnergyPerformance.dashboard.${type}`)
            : type}
        </span>
        <span>({unit})</span>
      </p>
      {resources?.map((resource, index) => (
        <li key={`first-${index}`} className="legend">
          <Stack spacing={4} className="justify-between">
            <LegendItem
              className=""
              bulletType="rectangle"
              bulletWidth="0.8rem"
              bulletHeight="0.4rem"
              color={resource.color}
              label={resource.legendLabel}
            />
            <span className=" t-body-n text-gray-500">
              {resource.value >= 1000
                ? addCommaToNumber(parseInt(resource.value))
                : resource.value?.toFixed(2)}{" "}
            </span>
          </Stack>
        </li>
      ))}

      <li>
        <Stack spacing={4} className="justify-between">
          <p className="ml-4  uppercase t-numbers-m text-gray-900 ">
            {t("EnergyPerformance.dashboard.total")}
          </p>
          <p className="pb-2 t-numbers-m text-gray-900">
            {total >= 1000
              ? addCommaToNumber(parseInt(total))
              : total?.toFixed(2)}{" "}
          </p>
        </Stack>
      </li>
    </ul>
  );
};

const DashboardLegends = ({
  props,
  pieChartDataFirst,
  pieChartDataSecond,
  isSingleChart,
}) => {
  const { className, unit } = props;
  const { t } = useTranslation();
  const { projectId } = useParams();
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20;
  const bobHam = Number(projectId) == 18;

  const totalFirst = sumByKeys({ data: pieChartDataFirst, keys: ["value"] })
    ?.value;
  const totalSecond = sumByKeys({ data: pieChartDataSecond, keys: ["value"] })
    ?.value;

  const pieChartDataThird = pieChartDataFirst?.map((d, i) => {
    return {
      ...d,
      value:
        d.value -
        (pieChartDataSecond !== undefined && pieChartDataSecond[i] !== undefined
          ? pieChartDataSecond[i]?.value
          : null),
    };
  });
  const totalThird = sumByKeys({ data: pieChartDataThird, keys: ["value"] })
    ?.value;
  return (
    <div className={`legends-container text-center ${className}`}>
      {pieChartDataFirst && (
        <SingleLegendGroup
          className="mt-2"
          type={
            bobHam
              ? "Standard year"
              : !newProject
              ? "simulated"
              : "Energy Consumption"
          }
          resources={pieChartDataFirst}
          unit={unit}
          total={totalFirst}
        />
      )}
      {pieChartDataSecond && (
        <SingleLegendGroup
          className={isSingleChart ? "mt-2" : "mt-2"}
          type={
            bobHam ? "Extreme year" : !newProject ? "measured" : "Energy Demand"
          }
          resources={pieChartDataSecond}
          unit={unit}
          total={totalSecond}
        />
      )}
      {pieChartDataThird && newProject && totalThird && !isSingleChart && (
        <SingleLegendGroup
          className={isSingleChart ? "mt-2" : "mt-2"}
          type="Saving Potential"
          resources={pieChartDataThird}
          unit={unit}
          total={totalThird}
        />
      )}
    </div>
  );
};

export default withDashboardChartsPrecalculator(DashboardLegends);
