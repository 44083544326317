import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, SearchInput } from "..";
import {
  setFilters,
  setSearchTerm,
} from "../../features/allBuildings/allBuildingsSlice";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";

export const FilterContainer = ({
  noOfCards,
  locationsOptions,
  certificatesOptions,
}) => {
  const { t } = useTranslation();
  const { filters, searchTerm } = useSelector((store) => store.allBuildings);
  const dispatch = useDispatch();

  const [searchTermRef, setSearchTermRef] = useState("");

  const [certificateValues, setCertificateValues] = useState(
    filters.certificate.values
  );
  const [locationValues, setLocationValues] = useState(filters.location.values);
  const [typeValues, setTypeValues] = useState(filters.type.values);
  const [yearValues, setYearValues] = useState(filters.year.values);
  const [energyLabelValues, setEnergyLabelValues] = useState(
    filters.energyLabel.values
  );

  const allFiltersValues = {
    energyLabel: energyLabelValues,
    location: locationValues,
    type: typeValues,
    certificate: certificateValues,
    year: yearValues,
  };
  const allFiltersSetValues = {
    energyLabel: setEnergyLabelValues,
    location: setLocationValues,
    type: setTypeValues,
    certificate: setCertificateValues,
    year: setYearValues,
  };

  useEffect(() => {
    dispatch(setFilters(allFiltersValues));
    dispatch(setSearchTerm(searchTermRef));
  }, [allFiltersValues, searchTermRef]);

  const clearFilters = () => {
    setEnergyLabelValues([]);
    setLocationValues([]);
    setTypeValues([]);
    setCertificateValues([]);
    setYearValues([]);
    setSearchTermRef("");
  };
  return (
    <section >
      <div className="flex flex-wrap justify-between mt-4">
        {Object.keys(allFiltersValues)?.map((filterName, index) => {
          return (
            <Dropdown
              width="15%"
              key={index}
              label={t(`AssetOverview.filters.${filters[filterName].label}`)}
              options={
                filterName === "location"
                  ? locationsOptions
                  : filterName === "certificate"
                    ? certificatesOptions
                    : filters[filterName].options
              }
              displayOptions={filters[filterName].displayOptions}
              values={allFiltersValues[filterName]}
              setValues={allFiltersSetValues[filterName]}
            ></Dropdown>
          );
        })}
        <SearchInput
          value={searchTermRef}
          onChange={(e) => setSearchTermRef(e.target.value)}
          type="search"
          label={t(`AssetOverview.filters.search`)}
          height="4rem"
          width="20%"
        />
      </div>
      <Stack spacing={theme.spacing(7)} className="mt-5">
        <span className="t-heading-m  ">
          {noOfCards} {t("AssetOverview.filters.buildings")}
        </span>
        <span
          className="t-heading-s text-gray-500 cursor-pointer"
          onClick={clearFilters}
        >
          {t("AssetOverview.filters.resetFilters")}
        </span>
      </Stack>
    </section>
  );
};
