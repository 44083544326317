import styled from "styled-components";
import React, { PureComponent, useEffect, useRef, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  ReferenceDot,
} from "recharts";
import arrowDown from "../../Asset/Icons/arrowDown.svg";
import { Icon } from "../Icon";
import { distinctFilter, sum } from "../../utils/dataManipulation";
import { Input, LabelLessInput } from "../Input";
import {
  useAddMeasure,
  useAddSelectedMeasure,
  useDeleteMeasure,
  useDeleteSelectedMeasure,
  useGetAllMeasures,
  useGetAllSelectedMeasures,
  useGetBuildingKpis,
  useGetCrrem,
  useGetPerformanceIndicator,
  useModifyMeasure,
  useModifySelectedMeasure,
} from "../../api/hooks/allHooks";
import { Table } from "../Table";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Paper, Stack } from "@mui/material";
import {
  addCommaToNumber,
  replaceDecimalPointWithComma,
} from "../../utils/namesManipulation";
import Chip from "../Chip";
import { theme } from "../../styles/theme";
import { RadioButton } from "../RadioButton";
import Kpis from "../../api/urls/kpis";
import { KPIs } from "../BuildingProfile/KPIs";
import { BulletChart } from "../chart/BulletChart";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CustomModal } from "../CustomModal";
import { SingleScenario } from "./SingleScenario";
import LegendBullet from "../chart/LegendBullet";
import { StrandingChart } from "./StrandingChart";

export const EnergyAndCarbonPerformance = ({ projectId, className }) => {
  const { t } = useTranslation();

  const { data: pIData, isLoading: pIIsLoading } = useGetPerformanceIndicator(
    projectId
  );

  const { data: crremDataConsumption0 } = useGetCrrem(
    projectId,
    "1.5",
    "consumption"
  );
  const { data: crremDataEmission0 } = useGetCrrem(
    projectId,
    "1.5",
    "emission"
  );

  const crremDataEmission = crremDataEmission0 || [];
  const crremDataConsumption = crremDataConsumption0 || [];

  const currentCon = crremDataConsumption[0]?.climate;
  const currentEm = crremDataEmission[0]?.climate;

  const targetCon = crremDataConsumption?.find((e) => e.year == 2030)?.crrem;
  const targetEm = crremDataEmission?.find((e) => e.year == 2030)?.crrem;

  const netZeroCon =
    crremDataConsumption[crremDataConsumption?.length - 1]?.crrem;
  const netZeroEm = crremDataEmission[crremDataEmission?.length - 1]?.crrem;

  const energyIndicator = (pIData || []).find(
    (indicator) => indicator.type === "energy performance indicator"
  );
  const carbonIndicator = (pIData || []).find(
    (indicator) => indicator.type === "carbon performance indicator"
  );

  const bullets = [
    {
      label: netZeroCon
        ? netZeroCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.net_zero_emission
        : null,
      value: netZeroCon
        ? netZeroCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.net_zero_emission
        : null,
      legendLabel: "Net-Zero-emission",
      color: "var(--clr-plunge-700)",
    },
    {
      label: targetCon
        ? targetCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.target_value
        : null,
      value: targetCon
        ? targetCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.target_value
        : null,
      legendLabel: "target value",
      color: "var(--clr-secondary-blue-700)",
    },
    {
      label: currentCon
        ? currentCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.status_qu
        : null,
      value: currentCon
        ? currentCon.toFixed(1)
        : energyIndicator
        ? energyIndicator?.status_qu
        : null,
      legendLabel: "status quo",
      color: "var(--clr-bright-indigo-700)",
    },
  ];

  const carbonBullets = [
    {
      label: netZeroEm
        ? netZeroEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.net_zero_emission
        : null,
      value: netZeroEm
        ? netZeroEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.net_zero_emission
        : null,
      legendLabel: "Net-Zero-emission",
      color: "var(--clr-plunge-700)",
    },
    {
      label: targetEm
        ? targetEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.target_value
        : null,
      value: targetEm
        ? targetEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.target_value
        : null,
      legendLabel: "target value",
      color: "var(--clr-secondary-blue-700)",
    },
    {
      label: currentEm
        ? currentEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.status_qu
        : null,
      value: currentEm
        ? currentEm.toFixed(1)
        : carbonIndicator
        ? carbonIndicator?.status_qu
        : null,
      legendLabel: "status quo",
      color: "var(--clr-bright-indigo-700)",
    },
  ];
  const energyAxis = [0, 50, 100, 150, 200, 250, 300, 350, 400];
  const carbonAxis = [0, 10, 20, 30, 40, 50, 60, 70, 80];

  const carbonZones = {
    breakPoint: carbonIndicator ? carbonIndicator?.tax_point_mid_zone : null,
    lowZoneLegend: "low tax zone",
    lowZoneColor: "var(--clr-mystic-red-500)",
    highZoneColor: "var(--clr-mystic-red-500)",
    highZoneLegend: "high tax zone",
  };

  const legends = [
    {
      bulletType: "circle",
      color: "var(--clr-bright-indigo-700)",
      legendLabel: t("EnergyPerformance.bulletChart.Status quo"),
    },
    {
      bulletType: "circle",
      color: "var(--clr-secondary-blue-700)",
      legendLabel: "2030",
    },

    {
      bulletType: "circle",
      color: "var(--clr-plunge-700)",
      legendLabel: "2050",
    },
    {
      bulletType: "rectangle",
      color: "var(--clr-vivid-orchid-500)",
      legendLabel: t("EnergyPerformance.bulletChart.Low tax zone"),
    },
    {
      bulletType: "rectangle",
      color: "var(--clr-mystic-red-500)",
      legendLabel: t("EnergyPerformance.bulletChart.High tax zone"),
    },
  ];

  const [quantity, setQuantity] = useState("consumption");

  return (
    <Paper className={` ${className}`}>
      {/* <Stack gap={theme.spacing(6)} alignItems="center" className="mb-2">
        <RadioButton
          options={["emission", "consumption"]}
          displayOptions={["CO₂ Emission", "kWh"]}
          row={true}
          value={quantity}
          setValue={setQuantity}
        />
      </Stack> */}
      {/* {quantity === "consumption" && ( */}
      <BulletChart
        header={t("EnergyPerformance.bulletChart.energyTitle")}
        pill="kWh/m²/a"
        bullets={bullets}
        legends={legends}
        axis={energyAxis}
        isLoading={pIIsLoading}
      ></BulletChart>
      {/* )} */}
      {/* {quantity === "emission" && ( */}
      <BulletChart
        className="mt-8"
        header={t("EnergyPerformance.bulletChart.carbonTitle")}
        pill="kg/m²/a"
        bullets={carbonBullets}
        legends={legends}
        axis={carbonAxis}
        zones={carbonZones}
        hasZones={false}
        isLoading={pIIsLoading}
      ></BulletChart>
      {/* )} */}
    </Paper>
  );
};
