import { Card, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetBuilding,
  useGetBuildingCertificates,
  useGetBuildingFiles,
  useGetBuildingKpis,
  useGetBulidingUsage,
  useGetCertificateStatus,
  useGetEnergyRatingStatus,
} from "../api/hooks/allHooks";
import { AssetDetailedCard } from "../components/BuildingProfile/AssetDetailedCard";
import { BuildingProfileTable } from "../components/BuildingProfile/BuildingProfileTable";
import { KPIs } from "../components/BuildingProfile/KPIs";
import { CertificatesCardsArticle } from "../components/CertificatesCards";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Paper from "@mui/material/Paper";
import { ComponentsCommonHeader } from "../components/ComponentsCommonHeader";
import { ButtonNew, Chip, CustomModal } from "../components";
import { useRef, useState } from "react";
import LegendBullet from "../components/chart/LegendBullet";
import {
  addCommaToNumber,
  replaceDecimalPointWithComma,
} from "../utils/namesManipulation";
import { HorizontalStackedBarChart } from "../components/chart/HorizontalStackedBarChart";
import { HorizontalStackedBarChartColorBar } from "../components/chart/HorizontalStackedBarChartColorBar";
import { Input, LabelLessInput } from "../components/Input";
import apiUrls from "../api/urls";
import axios from "axios";
import { customApiCall } from "../utils/axios";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { IsAllowed } from "../acl";

const UsageItem = ({
  className,
  title,
  area,
  coveredArea,
  percentage,
  ...props
}) => {
  return (
    // <Paper className={`py-2 px-3 ${className}`} {...props}>
    <Card className={`py-2 px-3 ${className}`} {...props}>
      <Stack justifyContent="space-between">
        <div>
          <h4 className="t-body-xl sentence-case">{title}</h4>
          <p className="t-body-s mt-1">
            <span className="text-gray-600">Area: </span>
            <span>{addCommaToNumber(Number(area))} m²</span>
          </p>
          <p className="t-body-s mt-1">
            <span className=" text-gray-600">Rented area covered:</span>
            <span>{coveredArea}%</span>
          </p>
        </div>
        <span className="t-numbers-m-reg text-blue-500">{percentage} %</span>
      </Stack>
    </Card>
    // </Paper>
  );
};

export const BuildingProfileNew = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const queryClient = useQueryClient();
  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { data: kpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);
  const { data: usages, isLoading: usagesIsLoading } = useGetBulidingUsage(
    projectId
  );
  const { data: buildingFiles1, isLoading: bFIsLoading } = useGetBuildingFiles(
    projectId
  );
  const buildingFiles = buildingFiles1 || [];
  const { data, isLoading: ceIsLoading } = useGetBuildingCertificates(
    projectId
  );
  const { energyCertificates, nonEnergyCertificates } = data;
  const [category, setCategory] = useState("usage");

  const legends = [
    {
      color: "var(--clr-vivid-orchid-500)",
      label: "lab",
    },
    {
      color: "var(--clr-secondary-blue-500)",
      label: "health",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "office",
    },
    // {
    //   color: "var(--clr-mystic-red-500)",
    //   label: "",
    // },
  ];

  const config = {
    lab: {
      color: "var(--clr-vivid-orchid-500)",
      label: "Lab",
    },
    health: {
      color: "var(--clr-secondary-blue-500)",
      label: "Health",
    },
    office: {
      color: "var(--clr-plunge-700)",
      label: "Office",
    },
  };

  const dataForChart = usages?.map((usage) => {
    return {
      ...usage,
      percentage: usage.area / 100,
      label: usage.category,
    };
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const BuildingUsageUpdateBox = ({
    className,
    data,
    setShowModal,
    ...props
  }) => {
    const [updatedData, setUpdatedData] = useState(data || {});
    const handleChange = (key, e) => {
      setUpdatedData({ ...updatedData, [key]: e.target.value });
    };

    const handleSubmit = async () => {
      const url = apiUrls.buildingUsage.get(updatedData.id);
      const data = {
        area: updatedData.area,
        rented_area_covered: updatedData.rented_area_covered,
        percentage: updatedData.percentage,
      };
      try {
        await customApiCall.patch({ url, data });
        toast.success("Updated successfully!");
        queryClient.invalidateQueries(["bulidingUsage", projectId]);
      } catch {
        toast.error("There was a problem updating information!");
      }
    };

    return (
      <Paper className={`border-0 ${className}`} {...props}>
        <h3 className="t-heading-m sentence-case">{updatedData.category}</h3>
        <h4 className="t-body-s text-gray-600 mt-4">
          Update the information using the boxes below.
        </h4>
        <Input
          label="Area m²"
          className="mt-4"
          placeholder="Area m²"
          value={updatedData.area}
          onChange={(e) => handleChange("area", e)}
        />
        <Input
          label="Rented area covered %"
          className="mt-4"
          placeholder="Rented area covered %"
          value={updatedData.rented_area_covered}
          onChange={(e) => handleChange("rented_area_covered", e)}
        />

        <Input
          label="Percentage %"
          className="mt-4"
          placeholder="Percentage %"
          value={updatedData.percentage}
          onChange={(e) => handleChange("percentage", e)}
        />
        <Stack justifyContent="space-around" className="mt-4">
          <ButtonNew
            variant="secondary"
            size="md"
            onClick={() => setShowModal(false)}
            width="150px"
          >
            Cancel
          </ButtonNew>
          <ButtonNew
            variant="primary"
            size="md"
            onClick={() => {
              handleSubmit();
              setShowModal(false);
            }}
            width="150px"
          >
            Done
          </ButtonNew>
        </Stack>
      </Paper>
    );
  };
  const certificateRatingConfig = {
    platinum: {
      color: "#737481", ///* gray/600 */
      label: t("Certificates.chart.platinum"),
      order: 1,
    },
    gold: {
      color: "#C5B100", ///* star/700 */
      label: t("Certificates.chart.gold"),
      order: 2,
    },
    silver: {
      color: "#C0C0C0", ///* */
      label: t("Certificates.chart.silver"),
      order: 3,
    },
    bronze: {
      color: "#A15C00", ///* tangerine/800*/
      label: t("Certificates.chart.bronze"),
      order: 4,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 5,
    },
  };

  const energyRatingConfig = {
    A: {
      color: "#00722C", //cathode-green/900
      label: "EPC-A",
      order: 1,
    },
    B: {
      color: "#00A13F", //cathode-green/800
      label: "EPC-B",
      order: 2,
    },
    C: {
      color: "#00C54D", //cathode-green/700
      label: "EPC-C",
      order: 3,
    },
    D: {
      color: "#E4CC00", //   star/600
      label: "EPC-D",
      order: 4,
    },
    E: {
      color: "#FF9200", //   tangerine/500
      label: "EPC-E",
      order: 5,
    },
    F: {
      color: "#FF5300", //   mystic-red/500
      label: "EPC-F",
      order: 6,
    },
    G: {
      color: "#C54000", //   mystic-red/700
      label: "EPC-G",
      order: 7,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 8,
    },
  };

  const addNotCertifiedToData = (data) => {
    if (!data) return data;
    let newData = [...data];
    let sumPercentage = 0;
    for (let i = 0; i < data?.length; i++) {
      sumPercentage += data[i]?.percentage;
    }
    let newObj = {
      type: null,
      label: "notCertified",
      percentage: 1 - sumPercentage,
    };
    newData.push(newObj);
    return newData;
  };

  const {
    data: dataCSI,
    isLoading: cSIsLoading,
    isLoadingError: isErrorCSI,
  } = useGetCertificateStatus(projectId);
  const certStat = isErrorCSI ? dataCSI : addNotCertifiedToData(dataCSI);

  const {
    data: dataRSI,
    isLoading: eRSIsLoading,
    isLoadingError: isErrorRSI,
  } = useGetEnergyRatingStatus(projectId);
  const energyStat = isErrorRSI ? dataRSI : addNotCertifiedToData(dataRSI);

  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <CustomModal
        modalOpen={showModal}
        setModalOpen={setShowModal}
        width={500}
        height={500}
        className="overflow-scroll max-h-[90rem]"
      >
        <BuildingUsageUpdateBox
          data={selectedCategory}
          setShowModal={setShowModal}
        />
      </CustomModal>

      <Stack spacing={6} className="justify-between items-stretch">
        <section class="min-w-[47%] max-w-[55%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            imgPath={building?.image_url_value}
            name={t("BuildingProfile.card.title")}
            isLoading={isLoading}
          ></AssetDetailedCard>
        </section>
        <section class="flex flex-col w-[49%]">
          <KPIs
            kpis={kpis || []}
            isLoading={kpiIsLoading}
            title={t("BuildingProfile.kpis.title")}
            xs={kpis?.length > 2 ? 12 : 6}
          />
          <BuildingProfileTable
            className="mt-8 p-6 flex-1"
            isLoading={bFIsLoading}
            buildingFiles={buildingFiles || []}
            projectId={projectId}
            tableClassName="max-h-85"
          />
        </section>
      </Stack>
      <Paper className="mt-6">
        <ComponentsCommonHeader title="Building Use Percentage"></ComponentsCommonHeader>
        <Stack justifyContent="space-between" className="mt-6">
          <Stack spacing={4}>
            <Chip
              isOn={category === "usage"}
              onClick={() => setCategory("usage")}
            >
              Usage Types
            </Chip>
            <Chip isOn={category === "epc"} onClick={() => setCategory("epc")}>
              EPC Rating
            </Chip>
            <Chip
              isOn={category === "certificates"}
              onClick={() => setCategory("certificates")}
            >
              Certificates
            </Chip>
          </Stack>
          <Stack spacing={4}>
            {legends?.map((legend, index) => {
              return (
                <Stack spacing={2} key={index}>
                  <LegendBullet
                    width="0.8rem"
                    height="0.8rem"
                    type="circle"
                    color={legend.color}
                  />
                  <span className="t-body-xl sentence-case">
                    {legend.label}
                  </span>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Stack spacing={12} className="mt-6">
          <div>
            <h5 className="t-label-m-caps text-gray-400 ">Total Area</h5>
            <p className="t-numbers-m mt-1">
              {addCommaToNumber(23890)}
              {" m²"}
            </p>
          </div>
          <div>
            <h5 className="t-label-m-caps text-gray-400 ">
              Rented Area covered
            </h5>
            <p className="t-numbers-m mt-1">
              {replaceDecimalPointWithComma("80")}
              {" %"}
            </p>
          </div>
        </Stack>
        <Stack spacing={8} alignItems="end" className="h-80 mt-4">
          {category === "epc" && (
            <HorizontalStackedBarChart
              className="w-3/5 h-full border-0 "
              height="8rem"
              data={energyStat}
              config={energyRatingConfig}
            />
          )}
          {category === "certificates" && (
            <HorizontalStackedBarChart
              className="w-3/5 h-full border-0 flex flex-col justify-center"
              height="8rem"
              data={certStat}
              config={certificateRatingConfig}
            />
          )}

          {category === "usage" && (
            <HorizontalStackedBarChartColorBar
              className="w-3/5 h-full"
              data={dataForChart}
              config={config}
              height="8rem"
              // isLoading={false}
              // isError={false}
            />
          )}
          <div className="w-2/5">
            {category === "usage" &&
              usages?.map((usage) => {
                return (
                  <UsageItem
                    className="w-full  mt-4"
                    title={usage.category}
                    coveredArea={usage.rented_area_covered}
                    percentage={usage.percentage}
                    area={usage.area}
                    onClick={() => {
                      setShowModal(true);
                      setSelectedCategory(usage);
                    }}
                  />
                );
              })}
            {category === "epc" && (
              <CertificatesCardsArticle
                className="min-h-[13rem] mt-10"
                certificates={energyCertificates}
                // isLoading={ceIsLoading}
                // title={t("BuildingProfile.certificateCard.energyRatingTitle")}
                hasButton={false}
                certificateType="epc"
                xl2={12}
              />
            )}
            {category === "certificates" && (
              <CertificatesCardsArticle
                className="min-h-[13rem] mt-10"
                certificates={nonEnergyCertificates}
                // isLoading={ceIsLoading}
                // title={t("BuildingProfile.certificateCard.certificateTitle")}
                hasButton={false}
                certificateType="certificate"
                xl2={12}
              />
            )}
          </div>
        </Stack>
      </Paper>
    </>
  );
};

export const BuildingProfile = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { data: kpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);
  const { data: buildingFiles, isLoading: bFIsLoading } = useGetBuildingFiles(
    projectId
  );
  const { data, isLoading: ceIsLoading } = useGetBuildingCertificates(
    projectId
  );
  const { energyCertificates, nonEnergyCertificates } = data;

  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <Stack spacing={6} className="justify-between items-start">
        {/* Left column */}
        <section className="min-w-[47%] max-w-[55%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            imgPath={building?.image_url_value}
            name={t("BuildingProfile.card.title")}
            isLoading={isLoading}
          ></AssetDetailedCard>
          <BuildingProfileTable
            className="mt-8 p-6"
            isLoading={bFIsLoading}
            buildingFiles={buildingFiles || []}
            projectId={projectId}
          />
        </section>

        {/* Right column */}
        <section className="w-[49%]">
          <KPIs
            kpis={kpis || []}
            isLoading={kpiIsLoading}
            title={t("BuildingProfile.kpis.title")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={nonEnergyCertificates}
            projectId={projectId}
            isLoading={ceIsLoading}
            title={t("BuildingProfile.certificateCard.certificateTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.carbonButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="certificate"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={energyCertificates}
            isLoading={ceIsLoading}
            projectId={projectId}
            title={t("BuildingProfile.certificateCard.energyRatingTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.energyButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="epc"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
        </section>
      </Stack>
    </>
  );
};
