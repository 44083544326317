import { resources } from "./resources";

export const roles = {
  GUEST: {
    roleId: 0,
    permissions: [],
  },
  LOGGED_IN: {
    roleId: 1,
    permissions: [
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
    ],
  },
  ASSET_MANAGER: {
    roleId: 2,
    permissions: [
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.UPLOAD_IMAGE,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.UPLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.MODERNIZATION.CUSTOM_SCENARIO_UPLOAD,
    ],
  },
  ADMIN: {
    roleId: 4,
    permissions: [
      resources.NAVBAR.SETTINGS,
      resources.BUILDING_PROFILE.DOWNLOAD_FILES,
      resources.BUILDING_PROFILE.UPLOAD_IMAGE,
      resources.BUILDING_PROFILE.THREE_D_VIEWER,
      resources.TECHNICAL_DATA.DOWNLOAD_FILES,
      resources.TECHNICAL_DATA.UPLOAD_FILES,
      resources.TECHNICAL_DATA.SELECT_COLUMNS,
      resources.TECHNICAL_DATA.REMOVE_ROW,
      resources.TECHNICAL_DATA.EDIT_TABLE_CELL,
      resources.ENERGY_PERFORMANCE.DOWNLOAD_FILES,
      resources.CERTIFICATE.DOWNLOAD_FILES,
      resources.MODERNIZATION.DOWNLOAD_SCENARIOS,
      resources.MODERNIZATION.CUSTOM_SCENARIO_UPLOAD,
    ],
  },
};
