import { Paper, Stack } from "@mui/material";
import React from "react";
import { Loading } from "../Loading";
import { HorizontalStackedBarChartColorBar } from "./HorizontalStackedBarChartColorBar";
import { HorizontalStackedBarChartLegend } from "./HorizontalStackedBarChartLegend";
import { Pill } from "./Pill";
import { addCommaToNumber } from "../../utils/namesManipulation";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";

export const HorizontalStackedBarChart = ({
  className,
  data,
  title,
  area,
  config,
  isLoading,
  isError,
  height,
}) => {
  const noData = !(data?.length > 0 && !isError);
  return (
    <Paper className={`${className} relative`}>
      <Stack spacing={4}>
        {title && <h3 className="t-heading-l">{title}</h3>}
        {area && <Pill>{addCommaToNumber(area)} m²</Pill>}
      </Stack>
      <LoadingOrEmptyComponent
        showLoading={isLoading}
        showEmpty={noData}
        height="12rem"
      />
      {!noData && (
        <>
          <HorizontalStackedBarChartLegend
            config={config}
            legends={data}
            className="mt-5"
          />
          <HorizontalStackedBarChartColorBar
            data={data}
            config={config}
            className="mt-10 h-7"
            height={height}
          />
        </>
      )}
    </Paper>
  );
};
