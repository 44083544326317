import i18next from "i18next";
import { ButtonNew, Icon } from "../components";
import { addCommaToNumber } from "../utils/namesManipulation";
import apiUrls from "../api/urls";
import { customApiCall } from "../utils/axios";

const downloadLayerFiles = ({ projectId, layerId, activeTab }) => {
  const url = apiUrls.layerFiles.techData[activeTab].downloadLayer(
    projectId,
    layerId
  );
  const fileName = `${activeTab} layer files ${layerId}.zip`;
  const errorMessage = i18next.t("general.downloadFileProblem");
  customApiCall.download({ url, fileName, errorMessage });
};

export const getTechTableConfig = ({ projectId, activeTab }) => {
  const wallAndRooftopColumns = [
    {
      label: i18next.t("TechnicalData.table.columns.Description"),
      dataKey: "description",
      cellType: null,
      valueTransformation: (row) => row["description"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Area") + " (m²)",
      dataKey: "area",
      cellType: null,
      valueTransformation: (row) => addCommaToNumber(row["area"]),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.lastChecked"),
      dataKey: "last_checked",
      cellType: "",
      valueTransformation: (row) => row["last_checked"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: i18next.t("TechnicalData.table.columns.Colour"),
      dataKey: "color",
      cellType: null,
      valueTransformation: (row) => row["color"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Modernization"),
      dataKey: "modernization",
      cellType: "",
      valueTransformation: (row) => row["modernization"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Id"),
      dataKey: "id",
      cellType: null,
      valueTransformation: (row) => row["id"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Installed"),
      dataKey: "installed",
      cellType: null,
      valueTransformation: (row) => row["installed"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.uValue"),
      dataKey: "u_value",
      cellType: null,
      valueTransformation: (row) => row["u_value"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Thickness") + " (m)",
      dataKey: "thickness",
      cellType: null,
      valueTransformation: (row) => row["thickness"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.files"),
      dataKey: "files",
      cellType: "downloadZipFile",
      valueTransformation: (row) => (
        <ButtonNew
          onClick={(row) => {
            downloadLayerFiles({ projectId, activeTab, layerId: row.id });
          }}
          variant="tertiary"
          size="sm"
          disabled={!row?.hasFiles}
        >
          <Icon
            color="var(--clr-secondary-blue-500)"
            iconName={"Download"}
            size="md"
            disabled={!row?.hasFiles}
          />
        </ButtonNew>
      ),
      subValueTransformation: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
  ];
  const windowsColumns = [
    {
      label: i18next.t("TechnicalData.table.columns.windowType"),
      dataKey: "window_type",
      cellType: null,
      valueTransformation: (row) => row["window_type"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.numberOfWindows"),
      dataKey: "number_of_windows",
      cellType: null,
      valueTransformation: (row) => addCommaToNumber(row["number_of_windows"]),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Area") + " (m²)",
      dataKey: "area",
      cellType: null,
      valueTransformation: (row) => addCommaToNumber(row["area"]),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: i18next.t("TechnicalData.table.columns.uValue") + " (W/m²K)",
      dataKey: "u_value",
      cellType: "",
      valueTransformation: (row) => row["u_value"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.gValue"),
      dataKey: "g_value",
      cellType: "",
      valueTransformation: (row) => row["g_value"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: i18next.t("TechnicalData.table.columns.lastChecked"),
      dataKey: "last_checked",
      cellType: "",
      valueTransformation: (row) => row["last_checked"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Id"),
      dataKey: "id",
      cellType: "",
      valueTransformation: (row) => row["id"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
  ];
  const childColumns = [
    {
      label: i18next.t("TechnicalData.table.columns.Layer"),
      dataKey: "name",
      cellType: null,
      valueTransformation: (row) => row["name"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Thickness") + " (m)",
      dataKey: "thickness",
      cellType: null,
      valueTransformation: (row) => row["thickness"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Density") + " (kg/m³)",
      dataKey: "density",
      cellType: null,
      valueTransformation: (row) => row["density"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label:
        i18next.t("TechnicalData.table.columns.Thermal conductivity") +
        " (W/(m*K))",
      dataKey: "thermal_conductivity",
      cellType: "",
      valueTransformation: (row) => row["thermal_conductivity"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label:
        i18next.t("TechnicalData.table.columns.Heat capacity") + " (J/kg*K)",
      dataKey: "heat_capacity",
      cellType: "",
      valueTransformation: (row) => row["heat_capacity"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: i18next.t("TechnicalData.table.columns.Created at"),
      dataKey: "_created_at",
      cellType: "",
      valueTransformation: (row) => row["_created_at"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Updated at"),
      dataKey: "_updated_at",
      cellType: "",
      valueTransformation: (row) => row["_updated_at"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: false,
    },
    {
      label: i18next.t("TechnicalData.table.columns.Files"),
      dataKey: "",
      cellType: "downloadFile",
      valueTransformation: (row) => (
        <ButtonNew disabled={!row.file_url} variant="tertiary" size="sm">
          <Icon
            color={
              !row.file_url
                ? "var(--clr-gray-500)"
                : "var(--clr-secondary-blue-500)"
            }
            iconName={"Download"}
            size="md"
          />
        </ButtonNew>
      ),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => row.file_url,
      isInDefault: true,
    },
  ];

  return {
    windows: {
      dataKey: "windows_features",
      columns: windowsColumns,
    },
    wall: {
      dataKey: "facade_features",
      columns: wallAndRooftopColumns,
    },
    rooftop: {
      dataKey: "rooftop_features",
      columns: wallAndRooftopColumns,
    },
    children: {
      columns: childColumns,
    },
  };
};

export const tabNames = [
  "windows",
  "wall",
  "rooftop",
  "shading system",
  "heating system",
  "hot water",
  "cooling system",
  "PV system",
  "building automation",
];

export const dataLabels = {
  "building envelope": ["windows", "wall", "rooftop", "shading system"],
  "energy supply system": [
    "heating system",
    "hot water",
    "cooling system",
    "PV system",
  ],
  other: ["building automation"],
};
