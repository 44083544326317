import React from "react";
import { HorizontalStackedBarChart } from "../components/chart/HorizontalStackedBarChart";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetBuilding,
  useGetBuildingCertificates,
  useGetCertificateStatus,
  useGetEnergyRatingStatus,
} from "../api/hooks/allHooks";
import { CertificatesCardsArticle } from "../components/CertificatesCards";
import { useTranslation } from "react-i18next";
import { IsAllowed } from "../acl";
import { Helmet } from "react-helmet-async";

const addNotCertifiedToData = (data) => {
  if (!data) return data;
  let newData = [...data];
  let sumPercentage = 0;
  for (let i = 0; i < data?.length; i++) {
    sumPercentage += data[i]?.percentage;
  }
  let newObj = {
    type: null,
    label: "notCertified",
    percentage: 1 - sumPercentage,
  };
  newData.push(newObj);
  return newData;
};

export const Certificates = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data: building } = useGetBuilding(projectId);
  const {
    data: dataCSI,
    isLoading: cSIsLoading,
    isLoadingError: isErrorCSI,
  } = useGetCertificateStatus(projectId);
  const certStat = isErrorCSI ? dataCSI : addNotCertifiedToData(dataCSI);

  const {
    data: dataRSI,
    isLoading: eRSIsLoading,
    isLoadingError: isErrorRSI,
  } = useGetEnergyRatingStatus(projectId);
  const energyStat = isErrorRSI ? dataRSI : addNotCertifiedToData(dataRSI);

  const {
    data: { energyCertificates, nonEnergyCertificates },
    isLoading: bcIsLoading,
  } = useGetBuildingCertificates(projectId);

  const certificateRatingConfig = {
    platinum: {
      color: "#737481", ///* gray/600 */
      label: t("Certificates.chart.platinum"),
      order: 1,
    },
    gold: {
      color: "#C5B100", ///* star/700 */
      label: t("Certificates.chart.gold"),
      order: 2,
    },
    silver: {
      color: "#C0C0C0", ///* */
      label: t("Certificates.chart.silver"),
      order: 3,
    },
    bronze: {
      color: "#A15C00", ///* tangerine/800*/
      label: t("Certificates.chart.bronze"),
      order: 4,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 5,
    },
  };

  const energyRatingConfig = {
    A: {
      color: "#00722C", //cathode-green/900
      label: "EPC-A",
      order: 1,
    },
    B: {
      color: "#00A13F", //cathode-green/800
      label: "EPC-B",
      order: 2,
    },
    C: {
      color: "#00C54D", //cathode-green/700
      label: "EPC-C",
      order: 3,
    },
    D: {
      color: "#E4CC00", //   star/600
      label: "EPC-D",
      order: 4,
    },
    E: {
      color: "#FF9200", //   tangerine/500
      label: "EPC-E",
      order: 5,
    },
    F: {
      color: "#FF5300", //   mystic-red/500
      label: "EPC-F",
      order: 6,
    },
    G: {
      color: "#C54000", //   mystic-red/700
      label: "EPC-G",
      order: 7,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 8,
    },
  };

  return (
    <>
      <Helmet>
        <title>Certificates | Leaftech</title>
      </Helmet>

      <section className="mt-8">
        <HorizontalStackedBarChart
          title={t("Certificates.chart.energyTitle")}
          area={building?.total_area}
          data={energyStat}
          config={energyRatingConfig}
          isLoading={eRSIsLoading}
          isError={isErrorRSI}
        />
        <HorizontalStackedBarChart
          className="mt-8"
          title={t("Certificates.chart.carbonTitle")}
          area={building?.total_area}
          data={certStat}
          config={certificateRatingConfig}
          isLoading={cSIsLoading}
          isError={isErrorCSI}
        />
        {/* Certificates section */}
        <Stack spacing={8} className="mt-16 items-start">
          <CertificatesCardsArticle
            className="min-h-[13rem] w-1/2"
            projectId={projectId}
            certificates={energyCertificates}
            isLoading={bcIsLoading}
            title={t("Certificates.cards.energyTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.energyButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="epc"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem]  w-1/2"
            projectId={projectId}
            certificates={nonEnergyCertificates}
            isLoading={bcIsLoading}
            title={t("Certificates.cards.carbonTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.carbonButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="certificate"
          />
        </Stack>
      </section>
    </>
  );
};
