import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

export const CustomModal = ({
  children,
  modalOpen,
  setModalOpen,
  width = 400,
  height = 400,
  padding = "5rem 2.4rem 4rem 2.4rem",
  className,
  closeIcon = true
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Wrapper className={className} width={width} height={height} padding={padding}>
        {children}
        {closeIcon &&
          <Icon
            iconName="Close"
            className="close"
            onClick={() => setModalOpen(false)}
          />
        }
      </Wrapper>
    </Modal>
  );
};
const Wrapper = styled("div")(({ width, height, padding }) => ({
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width,
  height: height,
  backgroundColor: "var(--clr-white)",
  padding,
  boxShadow: "var(--box-shadow-elevation-400)",
  borderRadius: "7px",
  border: "1px solid var(--clr-gray-100)",
  position: "absolute",
  textAlign: "center",
  ".close": {
    position: "absolute",
    display: "block",
    top: "2rem",
    right: "2rem",
    cursor: "pointer",
  },
}));
