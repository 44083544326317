import { Grid, Modal, Paper, Stack } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { isEqual } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { GiHistogram } from "react-icons/gi";
import { IoBarChartOutline, IoPieChartOutline } from "react-icons/io5";
import { LiaChartAreaSolid } from "react-icons/lia";
import { PiChartDonutThin, PiChartLineLight } from "react-icons/pi";
import { TbChartHistogram, TbSum } from "react-icons/tb";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  Dot,
  Label,
  Legend,
  Line,
  Pie,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetDashboardNew } from "../../api/hooks/allHooks";
import { ButtonNew } from "../../components/ButtonNew";
import { DownloadCSV, deleteKeysFromObjects, distinctFilter, isNumeric, keepKeysFromObjects } from "../../utils/dataManipulation";
import { dateToUCTDatetime, getWeekdayNumber } from "../../utils/date";
import { addCommaToNumber, formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { DateRangePickerNew } from "../DateRangePickerNew";
import { Dropdown } from "../Dropdown";
import { DropdownPure, DropdownSingleSelect } from "../DropdownSingleSelect";
import { Icon } from "../Icon";
import { Input } from "../Input";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import LegendItem from "./LegendItem";
import { ErrorBoundary } from "react-error-boundary";
import { EmptyErrorBoundary, GeneralErrorBoundary, TextErrorBoundary } from "../ErrorBoundary";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { setDataToDownload } from "../../features/energyPerformance/energyPerformanceSliceNew";
import i18next from "i18next";

export const diagramInitialConfig = {
  dataPoint: null,
  sensor: null,
  sensorDisplayText: null,
  diagramType: null,
  color: "black",
  partitions: null,
  stacks: null,
  x: null,
  percentageBased: false,
  direction: "horizontal",
  title: null,
};

const XYAxisAndCartesianGrid = (
  xDataKey,
  xAxisType,
  angle,
  interval,
  width,
  title,
  hasYAxis = true
) => {
  const isDataCategorical =
    xAxisType === "category" ||
    [
      "datetime",
      "yearMonth",
      "month",
      "dataPoint",
      "scenario",
      "dayOfWeek",
    ].includes(xDataKey);
  return (
    <>
      <text
        x={width / 2 + (hasYAxis ? 30 : 0)}
        y={10}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="14">{title}</tspan>
      </text>
      <CartesianGrid strokeDasharray="0" stroke="var(--clr-gray-100)" />
      {xDataKey && (
        <XAxis
          // tickFormatter={tickFormatter}
          // dataKey={xAxisDataKey}
          dataKey={xDataKey}
          textAnchor="end"
          stroke="var(--clr-gray-900)"
          angle={angle}
          // allowDuplicatedCategory={false}
          tickLine={false}
          tickMargin={5}
          style={{
            fontFamily: "Exo 2",
            fontSize: "1.2rem",
            fontWeight: 400,
            lineHeight: "1.4rem",
            letterSpacing: 0,
          }}
          domain={!isDataCategorical ? [0, "dataMax"] : undefined}
          type={!isDataCategorical ? "number" : "category"}
          interval={interval}
          tickFormatter={(x) => (!isDataCategorical ? x?.toFixed(2) : x)}
        // label={{
        //   value: xDataKey,
        //   position: "insideBottomRight",
        //   offset: -2,
        // }}
        ></XAxis>
      )}
      {hasYAxis && (
        <YAxis
          className="t-heading-xxl"
          style={{
            fontFamily: "Exo 2",
            fontSize: "1.2rem",
            fontWeight: 400,
            lineHeight: "1.4rem",
            letterSpacing: 0,
          }}
          stroke="var(--clr-gray-900)"
          axisLine={true}
          tickLine={false}
          width={60}
          tickMargin={0}
          domain={[0, "dataMax"]}
          type="number"
          tickFormatter={(y) => y?.toFixed(2)}
        >
          <Label
            position="top"
            offset={15}
            style={{
              textAnchor: "start",
              fontSize: "130%",
              fill: "black",
            }}
            angle={0}
          // value={unit}
          />
        </YAxis>
      )}
    </>
  );
};

const chartTypes = {
  BAR: "bar",
  LINE: "line",
  AREA: "area",
  SCATTER: "scatter",
  COMPOSED: "composed",
  HISTOGRAM: "histogram",
  DOUGHNUT: "doughnut",
  PIE: "pie",
  KPI: "kpi",
  HEATMAP: "heatmap",
};

const diagramTypes = {
  BAR: "bar",
  LINE: "line",
  SCATTER: "scatter",
};

const CustomizedLabel = ({ x, y, stroke, value, fill }) => {
  return (
    <>
      <Dot cx={x} cy={y} r={3} fill={fill} stroke={stroke} />
    </>
  );
};

const DynamicComboChart = ({ xDataKey, ...props }) => {
  const dispatch = useDispatch()
  if (!props.data?.length || !xDataKey) return;

  const data = props.data?.map((d, index) => ({
    ...d,
    index,
  }));
  const xIsNumeric = data?.some(row => isNumeric(row[xDataKey]))

  const aggregatedData = xIsNumeric ? data : aggregatedSumValue({
    data,
    aggregateColumns: props.chartsConfigs?.filter((config) => config.dataKey)?.map((config) => config.dataKey),
    groupByColumns: [xDataKey],
  })

  if (props.sort) {
    aggregatedData?.sort((a, b) => a[props.sortBy] - b[props.sortBy]);
  } else if (xDataKey) {
    aggregatedData?.sort((a, b) => a[xDataKey] - b[xDataKey]);
  }
  const sortedData =
    xDataKey !== "index" || !props.sort
      ? aggregatedData
      : aggregatedData?.map((d, index) => ({ ...d, index }));

  const xAxisValues = sortedData?.map((row) => row[xDataKey]);


  const xAxis = xIsNumeric ? {} : [
    {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
  ]
  const yAxis = xIsNumeric ? {} : [
    {
      type: "value",
    },
  ]

  const selectedConfigs = props?.chartsConfigs?.filter((config) => config.dataKey)
  const option = {
    xAxis,
    yAxis,
    series: selectedConfigs?.map((config) => {
      return {
        name: config.dataKey,
        type: config.chartType,
        color: config.color,
        tooltip: {
          trigger: 'item',
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        data: xIsNumeric ? sortedData?.map((row) => [row[xDataKey], row[config?.dataKey]]) : sortedData?.map((row) => row[config?.dataKey]),
        emphasis: {
          focus: "series",
        },
      };
    }),
  };

  const dataKeysForDownload = selectedConfigs?.map(config => config.dataKey)
  if (dataKeysForDownload) {
    dataKeysForDownload?.push(xDataKey)
    let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : aggregatedData, dataKeysForDownload)
    dispatch(setDataToDownload({ id: props.id, data: dataToDownload }))
  }

  return <DynamicEchartsChart option={option} title={props.title} legendsPosition={props?.legendsPosition} isFullScreen={props.isFullScreen} />;
};

const findDataRange = ({ data, dataKeys }) => {
  return data?.reduce(
    (total, current) => {
      for (const dataKey of dataKeys) {
        if (total.min === null || current[dataKey] < total.min)
          total.min = current[dataKey];
        if (total.max === null || current[dataKey] > total.max)
          total.max = current[dataKey];
      }
      return total;
    },
    { min: null, max: null }
  );
};

const predefinedSteps = [
  0.001,
  0.002,
  0.005,
  0.01,
  0.02,
  0.05,
  0.1,
  0.2,
  0.5,
  1,
  2,
  5,
  10,
  25,
  50,
  100,
  250,
  500,
  1000,
  2000,
  5000,
  10000,
  25000,
  50000,
  100000,
  250000,
  500000,
  1000000,
  2500000,
  5000000,
  10000000,
  25000000,
  50000000,
];

const generateAxisValues = (minValue, maxValue, step) => {
  if (minValue === undefined || maxValue === undefined) return;
  let bestStep;
  const range = maxValue - minValue;
  if (step !== undefined) bestStep = step;
  else {
    const ticksNos = [...Array(5).keys()]?.map((e) => e + 5);
    const upperBounds = predefinedSteps?.map((step) => {
      for (const tickNo of ticksNos) {
        if (step * tickNo > range) return step * tickNo;
      }
      return null;
    });
    const bestStepIndex = upperBounds.indexOf(
      Math.min(...upperBounds.filter((e) => e !== null))
    );
    bestStep = predefinedSteps[bestStepIndex];
  }
  if (bestStep === undefined || bestStep === null) return;
  const axisValues = [];
  const roundedMinValue = Math.floor(minValue / bestStep) * bestStep;
  for (let i = 0; i <= Math.ceil(range / bestStep) + 2; i++) {
    axisValues.push(roundedMinValue + i * bestStep);
    if (roundedMinValue + i * bestStep >= maxValue) break;
  }
  return axisValues;
};

const generateHistData = ({ data, dataKeys, axisValues }) => {
  if (!data?.length || !axisValues?.length) return;
  const histData = [];
  for (const i in axisValues) {
    if (i == 0) continue;
    const min = axisValues[i - 1];
    const max = axisValues[i];
    const label = `${min} - ${max}`;
    const allCounts = {};
    for (const dataKey of dataKeys) {
      const count = data?.reduce((total, current) => {
        const value = current[dataKey];
        if ((value > min || (value === min && i === 1)) && value <= max)
          total++;
        return total;
      }, 0);
      allCounts[dataKey] = count;
    }
    histData.push({ name: label, ...allCounts });
  }
  return histData;
};

const DynamicHistogramChart = ({ data, chartsConfigs, ...props }) => {
  const dispatch = useDispatch()
  const dataKeys = chartsConfigs?.map((c) => c.dataKey);
  if (!dataKeys?.filter((k) => k)?.length) return;
  const minMax = findDataRange({ data, dataKeys });
  if (!minMax) return;
  const { min, max } = minMax;
  const axisValues = generateAxisValues(min, max);

  const histData = generateHistData({ data, dataKeys, axisValues });
  var option = {
    xAxis: {
      data: histData?.map((row) => row.name),
    },
    yAxis: {
      type: "value",
    },
    series: chartsConfigs?.map((config, i) => {
      return {
        name: config.dataKey,
        type: "bar",
        color: config.color,
        stack: i,
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        data: histData?.map((row) => row[config?.dataKey]),
        emphasis: {
          focus: "series",
        },
      };
    }),
  };
  dispatch(setDataToDownload({ id: props.id, data: histData?.map((row) => ({ ...row, name: `[${row?.name}]` })) }))

  return <DynamicEchartsChart option={option} title={props.title} legendsPosition={props?.legendsPosition} isFullScreen={props.isFullScreen} />;
};

const echartsDefaultTooltipFormatter = (value) => formatNumberBasedOnUser(value)

const legendsWidth = 200
const legendsWidthFullScreen = 400

const echartsDefaultOptions = {
  tooltip: {
    position: "top",
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      saveAsImage: { show: true },
    },
  },
  dataZoom: {
    type: "slider",
    height: 16,
    bottom: 35,
  },

};

const legendConfigAtRight = {
  legend: {
    type: "scroll",
    orient: "vertical",
    left: 'right',
    top: 40,
    align: 'right',
    textStyle: {
      width: legendsWidth,
      overflow: 'truncate'
    }
  },
  grid: {
    left: 10,
    right: legendsWidth + 30,
    bottom: 60,
    top: 40,
    containLabel: true,
  },
}

const legendsConfigAtBottom = {
  legend: {
    type: "scroll",
    orient: "horizontal",
    right: 40,
    bottom: 0,
    left: 10,
  },
  grid: {
    left: 10,
    right: 10,
    bottom: 60,
    top: 40,
    containLabel: true,
  },
}

const DynamicEchartsChart = ({ option, ...props }) => {
  const eChartsRef = useRef();

  let mergedOptions = {
    ...echartsDefaultOptions,
    title: {
      text: props.title,
      left: "center",
    },
  };

  if (props?.legendsPosition === 'right') {
    mergedOptions = { ...mergedOptions, ...legendConfigAtRight }
    if (props.isFullScreen) {
      mergedOptions = {
        ...mergedOptions, legend: {
          ...mergedOptions.legend, textStyle: {
            ...mergedOptions.legend.textStyle,
            width: legendsWidthFullScreen
          }
        },
        grid: {
          ...mergedOptions.grid,
          right: legendsWidthFullScreen + 30,
        },
      }
    }
  } else {
    mergedOptions = { ...mergedOptions, ...legendsConfigAtBottom }
  }

  if (option) mergedOptions = { ...mergedOptions, ...option };

  if (eChartsRef && eChartsRef.current)
    eChartsRef.current?.getEchartsInstance().setOption(mergedOptions, true);

  return (
    <ReactECharts
      option={mergedOptions}
      style={{ height: "100%", width: "100%" }}
      ref={eChartsRef}
      {...props}
    />
  );
};

const DynamicPieChart = ({ data, dataPoint, chartsConfigs, ...props }) => {
  const {
    selectedSensors,
    selectedScenarios,
    width,
    height,
    chartType,
    title,
  } = props;
  const dispatch = useDispatch()

  if (!chartsConfigs.length) return;
  const config = chartsConfigs[0];
  const { partitions, stacks } = config;
  let flattenData = getFlattenData({
    data,
    sensors: props.allSensorsDataKeys,
    scenarios: props.scenarios,
    valueName: dataPoint,
  });

  if (selectedSensors?.length)
    flattenData = flattenData.filter((e) =>
      selectedSensors?.includes(e.sensor)
    );
  if (selectedScenarios?.length)
    flattenData = flattenData.filter((e) =>
      selectedScenarios?.includes(e.scenario)
    );

  flattenData = flattenData?.map((row) => {
    const filteredRow = {};
    if (partitions) filteredRow[partitions] = row[partitions];
    if (stacks) filteredRow[stacks] = row[stacks];
    return {
      ...filteredRow,
      dataPoint,
      [dataPoint]: row[dataPoint],
    };
  });

  let dataStacks;
  if (!stacks) dataStacks = [];
  else {
    dataStacks = flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
  }

  let allAggregatedData = [];

  if (!dataStacks.length) {
    allAggregatedData.push(
      aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [partitions],
      })
    );
  } else {
    for (const stack of dataStacks) {
      const filteredData = flattenData.filter((e) => e[stacks] === stack);

      allAggregatedData.push(
        aggregatedSumValue({
          data: filteredData,
          aggregateColumns: [dataPoint],
          groupByColumns: [partitions],
        })
      );
    }
  }
  const noOfCharts = allAggregatedData.length;

  let availableWidth = props.legendsPosition === 'right' ? width - legendsWidth : width
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypes.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(
    availableWidth / (5 * noOfCharts + 0.25),
    height / (5 * noOfCharts + 0.25)
  );
  var option = {
    tooltip: {
      trigger: "item",
    },
    title: [
      {
        text: props.title,
        left: "center",
      },
      ...dataStacks?.map((stackName, stackIndex) => ({
        subtext: !isDoughnut && stackName,
        top: height / 2 - outerRadius - 30,
        textAlign: "center",
        left: isDoughnut
          ? "50%"
          : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
      })),
    ],
    series: allAggregatedData?.map((data, stackIndex) => {
      return {
        name: partitions + " " + dataStacks[stackIndex],
        type: "pie",
        center: [
          isDoughnut
            ? `${100 * availableWidth / (2 * width)}%`
            : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
          "50%",
        ],
        radius: [
          isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
          isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
        ],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: isDoughnut ? 4 : 6,
          borderColor: "#fff",
          borderWidth: 1,
        },
        label: {
          show: false,
          position: "inside",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
            position: "inside",
          },
        },
        labelLine: {
          show: true,
        },
        color: data?.map((_row, index) => {
          // const utilityName = partitions!=='sensor'?null:row[partitions]
          return getColor(stackIndex, index);
        }),
        data: data?.map((row) => ({
          name: row[partitions],
          value: row[dataPoint],
        })),
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
      };
    }),
  };
  const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
    const name = dataStacks[stackIndex]
    return [...total, ...(current.map(r => ({ ...r, name })))]
  }, [])

  dispatch(setDataToDownload({ id: props.id, data: dataToDownload }))

  return <DynamicEchartsChart option={option} legendsPosition={props?.legendsPosition} isFullScreen={props.isFullScreen} />;
};

const LegendItemWithValue = ({
  label,
  value,
  color,
  hasBullet,
  direction,
  className,
}) => {
  return (
    <Stack
      spacing={4}
      className={`w-full legend justify-between ${className}`}
      flexDirection={direction}
    >
      <LegendItem
        className=""
        bulletType="rectangle"
        bulletWidth={hasBullet ? "0.8rem" : 0}
        bulletHeight={hasBullet ? "0.4rem" : 0}
        color={color}
        label={label}
      />
      <span className=" t-body-n text-gray-500">
        {/* {value >= 1000 ? addCommaToNumber(parseInt(value)) : value?.toFixed(2)}{" "} */}
        {formatNumberBasedOnUser(value)}
      </span>
    </Stack>
  );
};



const DynamicStackedChart = ({
  data: withoutIndexData,
  dataPoint,
  chartsConfigs,
  ...props
}) => {
  let {
    selectedSensors,
    selectedScenarios,
    width,
    height,
    chartType,
    title,
    xDataKey,
  } = props;
  const dispatch = useDispatch()
  const config = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = config;

  if (chartType === chartTypes.LINE || chartType === chartTypes.AREA) stacks = null
  if (chartType === chartTypes.KPI) xDataKey = null
  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  let flattenData = getFlattenData({
    data,
    sensors: props.allSensorsDataKeys,
    scenarios: props.scenarios,
    valueName: dataPoint,
  });

  if (selectedSensors?.length)
    flattenData = flattenData.filter((e) =>
      selectedSensors?.includes(e.sensor)
    );
  if (selectedScenarios?.length)
    flattenData = flattenData.filter((e) =>
      selectedScenarios?.includes(e.scenario)
    );

  flattenData = flattenData?.map((row) => {
    const filteredRow = {};
    if (partitions) filteredRow[partitions] = row[partitions];
    if (stacks) filteredRow[stacks] = row[stacks];
    if (xDataKey) filteredRow[xDataKey] = row[xDataKey];
    return {
      ...filteredRow,
      dataPoint,
      [dataPoint]: row[dataPoint],
    };
  });

  let dataStacks;
  if (!stacks) dataStacks = [];
  else {
    dataStacks = flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
  }

  let dataPartitions;
  if (!partitions) dataPartitions = [];
  else {
    dataPartitions = flattenData
      ?.map((e) => e[partitions])
      ?.filter(distinctFilter);
  }

  let allAggregatedData = [];
  let kpiAggregatedData;
  const pivotColumn =
    (chartType === chartTypes.BAR || chartType === chartTypes.KPI)
      ? partitions && stacks
        ? stacks + " - " + partitions
        : partitions || stacks
      : partitions;
  if (partitions && stacks && (chartType === chartTypes.BAR || chartType === chartTypes.KPI)) {
    flattenData = flattenData?.map((e) => ({
      ...e,
      [pivotColumn]: e[stacks] + " - " + e[partitions],
      [stacks]: null,
      [partitions]: null,
    }));
  }
  const isStacked = partitions || stacks;
  if (!isStacked) {
    allAggregatedData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [xDataKey],
    });
    kpiAggregatedData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [],
    });
  } else {
    var pivotColumnValues = flattenData
      ?.map((e) => e[pivotColumn])
      ?.filter(distinctFilter);

    flattenData = aggregatedSumValue({
      data: flattenData,
      aggregateColumns: [dataPoint],
      groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
    });

    const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);

    allAggregatedData = aggregatedSumValue({
      data: pivotedData,
      aggregateColumns: pivotColumnValues,
      groupByColumns: [xDataKey],
    });

    kpiAggregatedData = aggregatedSumValue({
      data: pivotedData,
      aggregateColumns: pivotColumnValues,
      groupByColumns: [],
    });
  }

  if (chartType === chartTypes.LINE) {
    if (!partitions && props.sort)
      allAggregatedData?.sort((a, b) => b[dataPoint] - a[dataPoint]);
    if (partitions && props.sort) {
      const sorted = {};
      pivotColumnValues.forEach((v) => {
        const pivotValues = allAggregatedData?.map((d) => d[v]);
        pivotValues.sort((a, b) => b - a);
        sorted[v] = pivotValues;
      });
      const sortedAllAggregatedData = [];
      for (const i in allAggregatedData) {
        const sortedIthIndex = {};
        for (const v of pivotColumnValues) {
          sortedIthIndex[v] = sorted[v][i];
        }
        sortedAllAggregatedData.push({ i, ...sortedIthIndex });
      }
      allAggregatedData = sortedAllAggregatedData;
    }
  }
  const isVertical = direction === "vertical";

  const xAxisValues = xDataKey
    ? allAggregatedData?.map((row, index) =>
      partitions && props.sort ? index : row[xDataKey]
    )
    : ["Total"];

  const xAxisOption = !isVertical
    ? {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
    : {
      type: "value",
    };
  const yAxisOption = isVertical
    ? {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
    }
    : {
      type: "value",
    };

  var option = {
    xAxis: [
      xAxisOption,
      // {
      //   data: xAxisValues,
      // type: direction !== "vertical" ? undefined : "value",
      // type: "value",
      // data: direction !== "vertical" ? xAxisValues : undefined,
      // axisPointer: {
      //   type: "shadow",
      // },
      // },
    ],
    yAxis: [
      yAxisOption,
      // {
      // type: direction !== "vertical" ? "value" : undefined,
      // data: direction !== "vertical" ? undefined : xAxisValues,
      // type: "value",
      // min: 0,
      // max: 250,
      // interval: 50,
      // axisLabel: {
      //   formatter: '{value} ml'
      // }
      // },
    ],
    series: !isStacked
      ? [
        {
          name: dataPoint,
          color: getColor(0),
          type: chartType === chartTypes.AREA ? chartTypes.LINE : chartType,
          areaStyle: chartType === chartTypes.AREA ? {} : undefined,
          data: allAggregatedData?.map((row) => row[dataPoint]),
        },
      ]
      : pivotColumnValues?.map((value, i) => {
        const stack = dataStacks?.find((s) => value?.includes(s));
        const stackIndex = dataStacks?.findIndex((s) => value?.includes(s));
        const partitionIndex = dataPartitions?.findIndex((p) =>
          value?.includes(p)
        );

        return {
          name: value,
          type: chartType === chartTypes.AREA ? chartTypes.LINE : chartType,
          areaStyle: chartType === chartTypes.AREA ? {} : undefined,
          color: stack ? getColor(stackIndex, partitionIndex) : getColor(i),
          stack:
            chartType === chartTypes.LINE
              ? null
              : chartType === chartTypes.AREA
                ? "0"
                : stack ?? "0",
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          data: allAggregatedData?.map((row) => row[value]),
          emphasis: {
            focus: "series",
          },
        };
      }),
  };
  dispatch(setDataToDownload({ id: props.id, data: allAggregatedData }))

  if (chartType === chartTypes.KPI)
    return (
      <>
        <h4 className="text-center t-body-n uppercase mt-4">{title}</h4>
        <Stack
          className="w-full"
          flexDirection={isVertical ? "column" : "row"}
          justifyContent={"space-around"}
          height={height - 48}
        >
          {!isStacked ? (
            <LegendItemWithValue
              label={dataPoint}
              value={kpiAggregatedData[0][dataPoint]}
              hasBullet={false}
              direction={"column"}
            />
          ) : partitions && !stacks ? (
            dataPartitions?.map((p, i) => (
              <LegendItemWithValue
                label={p}
                value={kpiAggregatedData[0][p]}
                hasBullet={false}
                direction={isVertical ? "row" : "column"}
                className={"w-full"}
              // color={flatColors[i]}
              />
            ))
          ) : (
            <Stack
              flexDirection={isVertical ? "column" : "row"}
              justifyContent={"space-between"}
              className={"h-full w-full"}
            >
              {dataStacks?.map((s, i) => {
                return (
                  <div className="w-full text-center t-body-n uppercase">
                    <p className="mt-2">{s}</p>
                    <Stack
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                      className="w-full"
                    >
                      {dataPartitions?.map((p, j) => (
                        <LegendItemWithValue
                          label={p}
                          value={kpiAggregatedData[0][`${s} - ${p}`]}
                          hasBullet={true}
                        // color={stackedColors[i][j]}
                        />
                      ))}{" "}
                    </Stack>
                  </div>
                );
              })}
            </Stack>
          )}

          <Legend />
          <Tooltip />
        </Stack>
      </>
    );


  return <DynamicEchartsChart option={option} title={props.title} legendsPosition={props?.legendsPosition} isFullScreen={props.isFullScreen} />;
};

const DynamicHeatmap = ({
  data,
  dataPoint,
  xDataKey,
  yDataKey,
  height,
  width,
  chartsConfigs,
  ...props
}) => {
  const dispatch = useDispatch()
  if (!dataPoint || !xDataKey || !yDataKey) return;

  const { selectedSensors, selectedScenarios } = props
  let flattenData = getFlattenData({
    data,
    sensors: props.allSensorsDataKeys,
    scenarios: props.scenarios,
    valueName: dataPoint,
  });

  if (selectedSensors?.length)
    flattenData = flattenData.filter((e) =>
      selectedSensors?.includes(e.sensor)
    );
  if (selectedScenarios?.length)
    flattenData = flattenData.filter((e) =>
      selectedScenarios?.includes(e.scenario)
    );

  flattenData = flattenData?.map((row) => {
    const filteredRow = {};
    if (yDataKey) filteredRow[yDataKey] = row[yDataKey];
    if (xDataKey) filteredRow[xDataKey] = row[xDataKey];
    return {
      ...filteredRow,
      dataPoint,
      [dataPoint]: row[dataPoint],
    };
  });



  const xLabels = (xDataKey !== "sensor" ? data : flattenData)
    ?.map((row) => row[xDataKey])
    ?.filter(distinctFilter);

  const yLabels = (yDataKey !== "sensor" ? data : flattenData)
    ?.map((row) => row[yDataKey])
    ?.filter(distinctFilter);

  if (yDataKey === "dayOfWeek")
    yLabels.sort((a, b) => getWeekdayNumber(a) - getWeekdayNumber(b));
  const aggregatedData = aggregatedSumValue({
    data: flattenData,
    groupByColumns: [xDataKey, yDataKey],
    aggregateColumns: [dataPoint],
  });

  const xyzData = [];
  yLabels.forEach((y, yIndex) => {
    xLabels.forEach((x, xIndex) => {
      const xyRecordIndex = aggregatedData.findIndex(
        (r) => r[xDataKey] === x && r[yDataKey] === y
      );
      const z =
        xyRecordIndex === -1 ? 0 : aggregatedData[xyRecordIndex][dataPoint];
      xyzData.push([xIndex, yIndex, z]);
    });
  });
  const defaultColor = "black";
  const color = chartsConfigs?.length ? chartsConfigs[0]?.color : defaultColor;

  var option = {
    legend: null,
    tooltip: {
      position: "top",
    },
    grid: {
      left: 10,
      right: 70,
      bottom: 60,
      top: 40,
      containLabel: true,
    },
    xAxis: {
      data: xLabels,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      data: yLabels,
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
      max: Math.max(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
      calculable: true,
      orient: "vertical",
      left: "right",
      top: 40,
      inRange: {
        color: ["white", color ?? defaultColor],
      },
    },
    series: [
      {
        name: dataPoint,
        type: "heatmap",
        data: xyzData,
        label: {
          show: false,
        },
        tooltip: {
          valueFormatter: echartsDefaultTooltipFormatter,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
          },
        },
      },
    ],
  };
  dispatch(setDataToDownload({ id: props.id, data: aggregatedData }))


  return <DynamicEchartsChart option={option} title={props.title} />;
};

const ChartType = ({ chartType, xDataKey = "datetime", ...props }) => {
  if (!props.chartsConfigs?.length || !props.dataPoint) return;
  if (xDataKey === "dayOfWeek")
    props?.data?.sort(
      (a, b) => getWeekdayNumber(a?.dayOfWeek) - getWeekdayNumber(b?.dayOfWeek)
    );
  if (chartType === chartTypes.COMPOSED)
    return <DynamicComboChart {...{ xDataKey, ...props }} />;
  if (chartType === chartTypes.HISTOGRAM)
    return <DynamicHistogramChart {...{ ...props }} />;
  if (chartType === chartTypes.PIE || chartType === chartTypes.DOUGHNUT)
    return <DynamicPieChart {...{ ...props, chartType }} />;
  if (
    chartType === chartTypes.BAR ||
    chartType === chartTypes.AREA ||
    chartType === chartTypes.KPI ||
    chartType === chartTypes.LINE
  )
    return <DynamicStackedChart {...{ ...props, chartType, xDataKey }} />;
  if (chartType === chartTypes.HEATMAP)
    return <DynamicHeatmap {...{ xDataKey, ...props }} />;
};

// const colors = [
//   "red",
//   "blue",
//   "green",
//   "yellow",
//   "purple",
//   "black",
//   "orange",
//   "brown",
//   "navy",
//   "gray",
// ];

const colorGroups = [
  "secondary-blue",
  "mystic-red",
  "vivid-orchid",
  "plunge",
  "star",
  "gray",
  "leaftech-blue",
  "tangerine",
  "cathode-green",
  "bright-indigo",
];

const spectrum = [600, 400, 200, 800];
let flatColors = [];
spectrum.forEach((s) => {
  colorGroups.forEach((group) => {
    flatColors.push(`--clr-${group}-${s}`);
  });
});

const stackedColors = spectrum.map((s) => {
  return colorGroups.map((group) => `--clr-${group}-${s}`);
});

const getColor = (stackIdx, partitionIdx) => {
  var style = getComputedStyle(document.documentElement);
  const isStacked = stackIdx >= 0;
  const isPartitioned = partitionIdx >= 0;

  if (!isStacked && !isPartitioned) {
    return style.getPropertyValue(flatColors[0]).trim();
  }
  if (!(isStacked && isPartitioned)) {
    return style
      .getPropertyValue(
        flatColors[(stackIdx ?? partitionIdx) % flatColors.length]
      )
      .trim();
  }
  const validStackIdx = stackIdx % stackedColors.length;
  const validPartitionIdx = partitionIdx % stackedColors[0].length;

  return style.getPropertyValue(
    stackedColors[validStackIdx][validPartitionIdx]
  );
};

// const CustomShape = (props) => {
//   return (
//     <Rectangle
//       {...props}
//       height={25}
//       x={props.x + 5}
//       y={props.y - 22}
//     />
//   );
// };

const ElementType = ({ chartType, ...props }) => {
  if (chartType === chartTypes.BAR) return <Bar {...props} />;
  if (chartType === chartTypes.LINE) return <Line dot={false} {...props} />;
  if (chartType === chartTypes.AREA) return <Area {...props} />;
  if (chartType === chartTypes.SCATTER)
    return (
      // <Line
      //   {...{
      //     ...props,
      //     strokeWidth: 0,
      //     stroke: "#000",
      //     label: <CustomizedLabel {...props} />,
      //   }}
      // />
      <Scatter {...props} />
    );
  if (chartType === chartTypes.PIE) {
    return (
      <>
        <text
          x={props.cx}
          y={25}
          fill="gray"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize="14">{props.stackName}</tspan>
        </text>
        <Pie
          labelLine={false}
          cx="50%"
          cy="50%"
          outerRadius={110}
          fill="#82ca9d"
          {...props}
        >
          {props?.data?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={getColor(props?.stackIndex, index)}
            />
          ))}
        </Pie>
      </>
    );
  }
};

const quantitiesConfig = [
  {
    name: "consumption",
    apiKey: "",
    displayLabel: "Energy",
    unit: "kWh",
  },
  {
    name: "price",
    apiKey: "",
    displayLabel: "Costs",
    unit: "€",
  },
  {
    name: "emission",
    apiKey: "",
    displayLabel: "CO₂ emission",
    unit: "KgCO₂",
  },
];

const resources = [
  {
    name: "index",
    dataKey: "index",
    legendLabel: "Nothing",
    color: "green",
  },
  {
    name: "time",
    dataKey: "datetime",
    legendLabel: "Time",
    color: "green",
  },
  {
    name: "electricity",
    dataKey: "electricity_system_value_simulated",
    legendLabel: "Electricity",
    color: "var(--clr-mystic-red-500)",
  },
  {
    name: "hot_water",
    dataKey: "hot_water_system_value_simulated",
    legendLabel: "PV Yield",
    color: "var(--clr-secondary-blue-500)",
  },
  {
    name: "heating",
    dataKey: "heating_system_value_simulated",
    legendLabel: "Heating demand",
    color: "var(--clr-vivid-orchid-500)",
  },
  {
    name: "cooling",
    dataKey: "cooling_system_value_simulated",
    legendLabel: "Cooling demand",
    color: "var(--clr-plunge-700)",
  },
];

const options = {
  X: "x",
  Y: "y",
  UTILITY: "utility",
  DIAGRAM_TYPE: "diagramType",
  PARTITIONS: "partitions",
  STACKS: "stacks",
  HEIGHT_100: "height100",
  DIRECTION: "direction",
  COLOR: "color",
  SORT_BY: "sortBy",
  LEGENDS_POSITION: "legendsPosition"
};
const relevantConfigOptions = {
  [chartTypes.AREA]: [
    options.X,
    options.PARTITIONS,
    options.HEIGHT_100,
    options.DIRECTION,
    options.LEGENDS_POSITION
  ],
  [chartTypes.BAR]: [
    options.X,
    options.PARTITIONS,
    options.STACKS,
    options.DIRECTION,
    options.LEGENDS_POSITION
  ],
  [chartTypes.PIE]: [options.PARTITIONS, options.STACKS,
  options.LEGENDS_POSITION

  ],
  [chartTypes.DOUGHNUT]: [options.PARTITIONS, options.STACKS,
  options.LEGENDS_POSITION

  ],
  [chartTypes.LINE]: [
    options.X,
    options.PARTITIONS,
    options.SORT_BY,
    options.DIRECTION,
    options.LEGENDS_POSITION
  ],
  [chartTypes.COMPOSED]: [
    options.X,
    options.UTILITY,
    options.DIAGRAM_TYPE,
    options.COLOR,
    options.LEGENDS_POSITION
  ],
  [chartTypes.KPI]: [options.PARTITIONS, options.STACKS, options.DIRECTION],
  [chartTypes.HISTOGRAM]: [options.UTILITY, options.COLOR,
  options.LEGENDS_POSITION

  ],
  [chartTypes.HEATMAP]: [options.X, options.Y, options.COLOR],
};
const isRelevantOption = (chartType, option, index) => {
  if (
    index > 0 &&
    chartType !== chartTypes.HISTOGRAM &&
    chartType !== chartTypes.COMPOSED
  )
    return false;
  return relevantConfigOptions[chartType]?.includes(option);
};

const DynamicChartConfigurations = ({
  dataColumns,
  categoricalColumns,
  dataPointsOptions,
  dataPointsDisplayOptions,
  allSensorsDataKeys,
  allSensorsDisplayNames,
  sortValue,
  setSortValue,
  x,
  setX,
  y,
  setY,
  title,
  setTitle,
  dataPoint,
  setDataPoint,
  diagrams,
  setDiagrams,
  selectedChartType,
  legendsPosition,
  setLegendsPosition
}) => {
  const handleConfigChange = (index, key, newValue) => {
    const newConfig = { ...diagrams[index], [key]: newValue };
    const updatedConfig = diagrams?.map((diagram, i) => {
      return i !== index ? diagram : newConfig;
    });
    setDiagrams(updatedConfig);
  };

  const handleAddDiagram = () => {
    setDiagrams([...diagrams, diagramInitialConfig]);
  };
  const selectedSensors = diagrams?.map((d) => d.sensor);
  const sortOptions = !selectedSensors?.length
    ? []
    : resources
      ?.filter((resource) => selectedSensors?.includes(resource.dataKey))
      ?.map((resource) => resource.dataKey);

  return (
    <Stack direction={"column"} className="w-full mt-4 gap-2 ">
      <Stack className="w-full" justifyContent={"space-between"}>
        <span className="block w-1/3 t-heading-m">Chart title :</span>
        <Input
          className="block w-3/4 "
          value={title}
          label="Title"
          placeholder="Title"
          onChange={(e) => setTitle(e.target.value)}
          height="4rem"
        />
      </Stack>
      <Stack className="w-full" justifyContent={"space-between"}>
        <span className="block w-1/3 t-heading-m">Data point :</span>
        <DropdownSingleSelect
          label="Data point"
          options={dataPointsOptions}
          displayOptions={dataPointsDisplayOptions}
          selectedValue={dataPoint}
          setSelectedValue={(value) => setDataPoint(value)}
          width="75%"
        />
        {/* <DropdownPure
          selectedValue={dataPoint}
          setSelectedValue={setDataPoint}
          options={dataPointsOptions}
          displayOptions={dataPointsDisplayOptions}
          className="w-3/4"
          placeholder={"Data Point"}
        /> */}
      </Stack>
      <Stack className="w-full" justifyContent={"space-between"}>
        {(selectedChartType === chartTypes.COMPOSED ||
          selectedChartType === chartTypes.HISTOGRAM) &&
          isRelevantOption(selectedChartType, options.X) && (
            <>
              <span className="block w-1/3 t-heading-m">X Axis :</span>
              <DropdownSingleSelect
                label="x-axis"
                options={dataColumns}
                displayOptions={dataColumns}
                selectedValue={x}
                setSelectedValue={(value) => setX(value)}
                className="w-3/4"
              />
            </>
          )}

        {(selectedChartType === chartTypes.BAR ||
          selectedChartType === chartTypes.AREA ||
          selectedChartType === chartTypes.LINE ||
          selectedChartType === chartTypes.HEATMAP) &&
          isRelevantOption(selectedChartType, options.X) && (
            <>
              <span className="block w-1/3 t-heading-m">X Axis :</span>
              <DropdownSingleSelect
                label="x-axis"
                options={["", "index", "sensor", ...categoricalColumns]}
                displayOptions={[
                  "None",
                  "index",
                  "Utility",
                  ...categoricalColumns,
                ]}
                selectedValue={x}
                setSelectedValue={(value) => setX(value)}
                className="w-3/4"
              />
            </>
          )}
      </Stack>
      {isRelevantOption(selectedChartType, options.Y) && (
        <Stack className="w-full" justifyContent={"space-between"}>
          <span className="block w-1/3 t-heading-m">Y Axis :</span>
          <DropdownSingleSelect
            label="y-axis"
            options={["", "index", "sensor", ...categoricalColumns]}
            displayOptions={["None", "index", "Utility", ...categoricalColumns]}
            selectedValue={y}
            setSelectedValue={(value) => setY(value)}
            className="w-3/4"
          />
        </Stack>
      )}
      {isRelevantOption(selectedChartType, options.LEGENDS_POSITION) &&
        <Stack className="w-full" justifyContent={"space-between"}>
          <span className="block w-1/3 t-heading-m">Lengeds Position :</span>
          <DropdownSingleSelect
            label="Lengeds position"
            options={['bottom', 'right']}
            displayOptions={['Bottom', 'Right']}
            selectedValue={legendsPosition}
            setSelectedValue={(value) => setLegendsPosition(value)}
            width="75%"
          />
        </Stack>
      }
      {diagrams?.map((diagram, index) => {
        return (
          <>
            {(selectedChartType === chartTypes.COMPOSED ||
              selectedChartType === chartTypes.HISTOGRAM) && (
                <Stack gap={2} className="items-center mt-1">
                  <Icon
                    iconName={"Close"}
                    size={"sm"}
                    color={"red"}
                    onClick={() =>
                      setDiagrams(diagrams.filter((_d, i) => i !== index))
                    }
                  />
                  <span className="block t-heading-m">Diagram {index + 1}:</span>
                </Stack>
              )}
            {isRelevantOption(selectedChartType, options.UTILITY, index) && (
              <>
                <Stack className="w-full" justifyContent={"space-between"}>
                  <span className="block w-1/3 t-heading-m">Utility :</span>
                  <DropdownSingleSelect
                    label="Utility"
                    className="block w-3/4 "
                    options={allSensorsDataKeys}
                    displayOptions={allSensorsDisplayNames}
                    selectedValue={diagram.sensor}
                    setSelectedValue={(value) =>
                      handleConfigChange(index, "sensor", value)
                    }
                  />
                </Stack>
                {isRelevantOption(selectedChartType, options.DIAGRAM_TYPE) && (
                  <Stack className="w-full" justifyContent={"space-between"}>
                    <span className="block w-1/3 t-heading-m">
                      Diagram type :
                    </span>
                    <DropdownSingleSelect
                      label="Diagram type"
                      className="block w-3/4 "
                      options={Object.values(diagramTypes)}
                      displayOptions={Object.values(diagramTypes)}
                      selectedValue={diagram.diagramType}
                      setSelectedValue={(value) =>
                        handleConfigChange(index, "diagramType", value)
                      }
                    />
                  </Stack>
                )}
              </>
            )}
            {isRelevantOption(selectedChartType, options.PARTITIONS, index) && (
              <Stack className="w-full" justifyContent={"space-between"}>
                <span className="block w-1/3 t-heading-m">Partitions :</span>
                <DropdownSingleSelect
                  label="Partitions"
                  className="block w-3/4 "
                  options={["", "sensor", ...categoricalColumns]}
                  displayOptions={["None", "Utility", ...categoricalColumns]}
                  selectedValue={diagram.partitions}
                  setSelectedValue={(value) =>
                    handleConfigChange(index, "partitions", value)
                  }
                />
              </Stack>
            )}
            {isRelevantOption(selectedChartType, options.STACKS, index) && (
              <Stack className="w-full" justifyContent={"space-between"}>
                <span className="block w-1/3 t-heading-m">Stacks :</span>
                <DropdownSingleSelect
                  label="Stacks"
                  className="block w-3/4 "
                  options={["", "sensor", ...categoricalColumns]}
                  displayOptions={["None", "Utility", ...categoricalColumns]}
                  selectedValue={diagram.stacks}
                  setSelectedValue={(value) =>
                    handleConfigChange(index, "stacks", value)
                  }
                />
              </Stack>
            )}
            {/* {isRelevantOption(selectedChartType, options.HEIGHT_100, index) && (
              <Stack className="w-full" justifyContent={"space-between"}>
                <Checkbox
                  checked={diagram.percentageBased}
                  onChange={(e) =>
                    handleConfigChange(
                      index,
                      "percentageBased",
                      e.target.checked
                    )
                  }
                >
                  <span className="ml-2 w-1/3 t-heading-m">100% Height</span>
                </Checkbox>
              </Stack>
            )} */}

            {isRelevantOption(selectedChartType, options.DIRECTION, index) && (
              <Stack className="w-full" justifyContent={"space-between"}>
                <span className="block w-1/3 t-heading-m">Direction :</span>
                <DropdownSingleSelect
                  label="direction"
                  className="block w-3/4 "
                  options={["horizontal", "vertical"]}
                  displayOptions={["Horizontal", "Vertical"]}
                  selectedValue={diagram.direction}
                  setSelectedValue={(value) =>
                    handleConfigChange(index, "direction", value)
                  }
                />
              </Stack>
            )}
            {isRelevantOption(selectedChartType, options.COLOR, index) && (
              <Stack className="w-full" justifyContent={"space-between"}>
                <span className="block w-1/3 t-heading-m">Color :</span>
                <input
                  className="block w-3/4 "
                  type="color"
                  value={diagram.color}
                  onChange={(e) =>
                    handleConfigChange(index, "color", e.target.value)
                  }
                />
              </Stack>
            )}
          </>
        );
      })}
      {isRelevantOption(selectedChartType, options.SORT_BY) && (
        <Stack className="w-full" justifyContent={"space-between"}>
          <span className="block w-1/3 t-heading-m">Sort by :</span>
          <DropdownSingleSelect
            label="Sort by"
            className="block w-3/4 "
            options={["", "All values", ...sortOptions]}
            displayOptions={["None", "All values", ...sortOptions]}
            selectedValue={sortValue}
            setSelectedValue={setSortValue}
          />
        </Stack>
      )}
      {(selectedChartType === chartTypes.COMPOSED ||
        selectedChartType === chartTypes.HISTOGRAM) && (
          <ButtonNew
            onClick={handleAddDiagram}
            variant="primary"
            size="sm"
            className="mt-1"
          >
            + Add Diagram
          </ButtonNew>
        )}

    </Stack>

  );
};

const ChartFilters = ({
  allSensorsDataKeys,
  allSensorsDisplayNames,
  selectedSensors,
  setSelectedSensors,
  scenarios,
  selectedScenarios,
  setSelectedScenarios,
  setStartDate,
  setEndDate,
  minDate,
  maxDate,
  dates,
  timeframe,
  setTimeframe,
  dataDaysOfWeek,
  selectedDaysOfWeek,
  setSelectedDaysOfWeek,
  dataHoursOfDay,
  selectedHoursOfDay,
  setSelectedHoursOfDay,
  datePickerBoxPlacement,
}) => {
  return (
    <Stack flexDirection={"column"} gap={2}>
      <Dropdown
        width="75%"
        label="Utilities"
        options={allSensorsDataKeys}
        displayOptions={allSensorsDisplayNames}
        values={selectedSensors}
        setValues={setSelectedSensors}
      />

      <Dropdown
        width="75%"
        label="Scenarios"
        options={scenarios}
        displayOptions={scenarios}
        values={selectedScenarios}
        setValues={setSelectedScenarios}
      />
      <DateRangePickerNew
        className="w-3/4"
        appearance="default"
        value={dates}
        onChange={(value) => {
          setStartDate(value[0]);
          setEndDate(value[1]);
        }}
        cleanable={false}
        minDate={minDate}
        maxDate={maxDate}
        placement={datePickerBoxPlacement}
      />
      <DropdownSingleSelect
        className="w-3/4"
        label="Timeframe"
        options={["daily", "hourly"]}
        displayOptions={["Daily", "Hourly"]}
        selectedValue={timeframe}
        setSelectedValue={setTimeframe}
      />
      <Dropdown
        width="75%"
        label="Day"
        options={dataDaysOfWeek}
        displayOptions={dataDaysOfWeek}
        values={selectedDaysOfWeek}
        setValues={setSelectedDaysOfWeek}
      />

      <Dropdown
        width="75%"
        label="Hour"
        options={dataHoursOfDay}
        displayOptions={dataHoursOfDay}
        values={selectedHoursOfDay}
        setValues={setSelectedHoursOfDay}
      />
    </Stack>
  );
};

const CustomResizeHandle = React.forwardRef((props, ref) => {
  const { handleAxis, ...restProps } = props;
  return (
    <div
      className={`custom-handle custom-handle-${handleAxis} custom-resize-handle-component`}
      ref={ref}
      {...restProps}
    ></div>
  );
});

const getFlattenData = ({ data, sensors, valueName }) => {
  const flattenData = [];
  for (const row of data) {
    const rowCopy = { ...row };
    for (const col of sensors) delete rowCopy[col];
    for (const key of Object.keys(row)) {
      if (sensors?.includes(key)) {
        const flatItem = {
          ...rowCopy,
          [valueName]: row[key],
          sensor: key,
        };
        flattenData.push(flatItem);
      }
    }
  }
  return flattenData;
};

const aggregatedSumValue = ({ data, groupByColumns, aggregateColumns }) => {
  if (!groupByColumns?.filter((e) => e !== null && e !== undefined)?.length) {
    return [
      data?.reduce((acc, cur) => {
        aggregateColumns.forEach((col) => {
          acc[col] = (acc[col] || 0) + cur[col];
        });
        return acc;
      }, {}),
    ];
  }

  return Object.values(
    data?.reduce((acc, cur) => {
      const key = groupByColumns?.map((col) => cur[col]).join("|");
      if (!acc[key]) {
        acc[key] = Object.fromEntries(
          groupByColumns?.map((col) => [col, cur[col]])
        );
        aggregateColumns.forEach((col) => {
          acc[key][col] = cur[col];
        });
      } else {
        aggregateColumns.forEach((col) => {
          acc[key][col] += cur[col];
        });
      }
      return acc;
    }, {})
  );
};

const pivotData = (data, pivotColumn, valueColumn) => {
  if (!data?.length || !pivotColumn) return [];
  const pivotedData = {};
  const keyColumns = Object.keys(data[0]).filter(
    (col) => col !== pivotColumn && col !== valueColumn
  );
  data?.forEach((entry) => {
    const key = keyColumns?.map((col) => entry[col]).join("-");
    if (!pivotedData[key]) {
      pivotedData[key] = {};
      keyColumns?.forEach((col) => {
        pivotedData[key][col] = entry[col];
      });
    }
    pivotedData[key][entry[pivotColumn]] = entry[valueColumn];
  });
  return Object.values(pivotedData);
};




const ChartNavbar = ({
  id,
  onClose,
  isVisible,
  setIsVisible,
  handleClick,
  setChartSelectionIsOpen,
  isFullScreen,
  enterFullScreenMode,
  exitFullScreenMode
}) => {
  const configsIconsColor = "var(--clr-secondary-blue-500)";
  const items = [
    {
      onClick: onClose,
      iconName: "Close",
    },
    {
      iconName: isFullScreen ? "FullscreenExit" : "Fullscreen",
      onClick: isFullScreen ? exitFullScreenMode : enterFullScreenMode,
      stroke: true
    },
    {
      iconName: isVisible ? "EyeClose" : "EyeOpen",
      onClick: () => setIsVisible(!isVisible),
    },
    {
      onClick: () => handleClick("settings"),
      iconName: "Settings",
    },
    {
      onClick: () => handleClick("filters"),
      iconName: "Filter",
    },
    {
      iconName: "Piechart",
      onClick: (e) => {
        setChartSelectionIsOpen(
          (chartSelectionIsOpen) => !chartSelectionIsOpen
        );
      },
    },
  ];

  const dataToDownload = useSelector(store => store.energyPerformanceNew).dataToDownload?.[id]
  return (
    <Stack
      className="px-2 h-6 gap-4 w-full bg-[#f5f5ff]  rounded items-center "
      flexDirection={"row-reverse"}
    >
      {items?.map((item) => (
        <Icon
          className="cancelDrag  cursor-pointer "
          onClick={item.onClick}
          iconName={item.iconName}
          svgClassName="w-4 h-4 hover:scale-[1.3] transition"
          color={configsIconsColor}
          stroke={item.stroke && configsIconsColor}
        />
      ))}
      <EmptyErrorBoundary>
        {dataToDownload &&
          <CSVLink data={dataToDownload} >
            <Icon
              className="cancelDrag  cursor-pointer"
              iconName={"Download"}
              svgClassName="hover:scale-[2] transition"
              size='md'
              color={configsIconsColor}
            />
          </CSVLink>
        }
      </EmptyErrorBoundary>


    </Stack>
  );
};

const DynamicChartNavbarAndSidebar = forwardRef((props, ref) => {
  const {
    onClose,
    setIsVisible,
    isVisible,
    setChartSelectionIsOpen,
    dataColumns,
    categoricalColumns,
    dataPoint,
    dataPointsOptions,
    dataPointsDisplayOptions,
    allSensorsDataKeys,
    allSensorsDisplayNames,
    title,
    xAxis,
    yAxis,
    value,
    setValue,
    diagrams,
    sortValue,
    chartType,
    selectedSensors,
    scenarios,
    selectedScenarios,
    dates,
    minDate,
    maxDate,
    xCoord,
    timeframe,
    selectedDaysOfWeek,
    selectedHoursOfDay,
    setConfig,
    legendsPosition,
    isFullScreen,
    setFullScreenChartId,
    id,
  } = props;

  const setDiagrams = (newValue) => setConfig("diagrams", newValue);
  const setXAxis = (newValue) => setConfig("xAxis", newValue);
  const setYAxis = (newValue) => setConfig("yAxis", newValue);
  const setDataPoint = (newValue) => setConfig("dataPoint", newValue);
  const setTitle = (newValue) => setConfig("title", newValue);
  const setSortValue = (newValue) => setConfig("sortValue", newValue);
  const setTimeframe = (newValue) => setConfig("timeframe", newValue);
  const setStartDate = (newValue) => setConfig("startDate", newValue);
  const setEndDate = (newValue) => setConfig("endDate", newValue);
  const setSelectedDaysOfWeek = (newValue) =>
    setConfig("selectedDaysOfWeek", newValue);
  const setSelectedHoursOfDay = (newValue) =>
    setConfig("selectedHoursOfDay", newValue);
  const setSelectedSensors = (newValue) =>
    setConfig("selectedUtilities", newValue);
  const setSelectedScenarios = (newValue) =>
    setConfig("selectedScenarios", newValue);
  const setLegendsPosition = (newValue) => setConfig("legendsPosition", newValue);

  const dataDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dataHoursOfDay = [...Array(24).keys()];

  const [openSidebar, setOpenSidebar] = useState(false);
  const [section, setSection] = useState(null);
  const handleClick = (selectedSection) => {
    setSection(selectedSection);
    setOpenSidebar(true);
  };

  useImperativeHandle(ref, () => ({
    childHandleClick: handleClick,
  }));

  return (
    <>
      <ChartNavbar
        id={id}
        onClose={onClose}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        handleClick={handleClick}
        setChartSelectionIsOpen={setChartSelectionIsOpen}
        isFullScreen={isFullScreen}
        enterFullScreenMode={() => setFullScreenChartId(id)}
        exitFullScreenMode={() => setFullScreenChartId(null)}
      />
      {openSidebar &&
        // createPortal(
        <Modal open={openSidebar} onClose={() => setOpenSidebar(false)}>
          <Paper
            className={`cancelDrag fixed z-[6] rounded top-8 bottom-8 w-[60rem] overflow-y-auto ${xCoord < 6 ? "right-8" : "left-8"
              }`}
            style={{
              backdropFilter: "blur(10px)",
              background: "rgba(255,255,255,0.6)",
            }}
          >

            {section === "settings" && (
              <DynamicChartConfigurations
                dataColumns={dataColumns}
                categoricalColumns={categoricalColumns}
                dataPoint={dataPoint}
                setDataPoint={setDataPoint}
                dataPointsOptions={dataPointsOptions}
                dataPointsDisplayOptions={dataPointsDisplayOptions}
                allSensorsDataKeys={allSensorsDataKeys}
                allSensorsDisplayNames={allSensorsDisplayNames}
                title={title}
                setTitle={setTitle}
                x={xAxis}
                setX={setXAxis}
                y={yAxis}
                setY={setYAxis}
                value={value}
                setValue={setValue}
                diagrams={diagrams}
                setDiagrams={setDiagrams}
                sortValue={sortValue}
                setSortValue={setSortValue}
                selectedChartType={chartType}
                legendsPosition={legendsPosition}
                setLegendsPosition={setLegendsPosition}
              />
            )}
            {section === "filters" && (
              <ChartFilters
                allSensorsDataKeys={allSensorsDataKeys}
                allSensorsDisplayNames={allSensorsDisplayNames}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
                scenarios={scenarios}
                selectedScenarios={selectedScenarios}
                setSelectedScenarios={setSelectedScenarios}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                minDate={minDate}
                maxDate={maxDate}
                dates={dates}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
                dataDaysOfWeek={dataDaysOfWeek}
                selectedDaysOfWeek={selectedDaysOfWeek}
                setSelectedDaysOfWeek={setSelectedDaysOfWeek}
                dataHoursOfDay={dataHoursOfDay}
                selectedHoursOfDay={selectedHoursOfDay}
                setSelectedHoursOfDay={setSelectedHoursOfDay}
                datePickerBoxPlacement={
                  xCoord < 6 ? "bottomEnd" : "bottomStart"
                }
              />
            )}
          </Paper>
        </Modal>
        // ,
        //   document.body
        // )
      }
    </>
  );
});

const DynamicChartBody = ({
  isLoading,
  isError,
  startDate,
  endDate,
  minDate,
  maxDate,
  generalData,
  specificData,
  selectedDaysOfWeek,
  selectedHoursOfDay,
  timeframe,
  width,
  chartType,
  dataPoint,
  allSensorsDataKeys,
  scenarios,
  title,
  xAxis,
  yAxis,
  diagrams,
  sortValue,
  w,
  height,
  selectedSensors,
  selectedScenarios,
  legendsPosition,
  isFullScreen,
  id
}) => {

  const dates = [new Date(startDate ?? minDate), new Date(endDate ?? maxDate)];
  const data = specificData?.length ? specificData : generalData;

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (!isLoading) {
      const unsortedData = [...data];
      unsortedData.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

      setFilteredData(
        unsortedData.filter((d, index) => {
          return (
            new Date(d.datetime) >= dateToUCTDatetime(dates[0]) &&
            new Date(d.datetime) <= dateToUCTDatetime(dates[1]) &&
            d.dataPoint === dataPoint &&
            (!selectedDaysOfWeek?.length ||
              selectedDaysOfWeek?.includes(d?.dayOfWeek)) &&
            (!selectedHoursOfDay?.length ||
              selectedHoursOfDay?.includes(d?.hourOfDay))
          );
        })
      );
    }
  }, [
    startDate,
    endDate,
    minDate,
    maxDate,
    generalData,
    isLoading,
    specificData,
    dataPoint,
    selectedDaysOfWeek,
    selectedHoursOfDay,
    timeframe,
  ]);

  return (
    <div className={`relative h-full`}>
      <LoadingOrEmptyWrapper
        showLoading={isLoading}
        showEmpty={!data?.length || isError}
        height="100%"
      >
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <ChartType
            chartType={chartType}
            dataPoint={dataPoint}
            allSensorsDataKeys={allSensorsDataKeys}
            scenarios={scenarios}
            title={title}
            xDataKey={xAxis}
            yDataKey={yAxis}
            id={id}
            data={filteredData}
            margin={{ top: 30, left: 30, right: 0, bottom: 0 }}
            barGap={0}
            barCategoryGap={"20%"}
            chartsConfigs={diagrams?.map((diagram) => ({
              dataKey: diagram.sensor,
              chartType: diagram.diagramType,
              stroke: diagram.color,
              fill: diagram.color,
              color: diagram.color,
              partitions: diagram.partitions,
              stacks: diagram.stacks,
              xDataKey: diagram.x,
              percentageBased: diagram.percentageBased,
              direction: diagram.direction,
            }))}
            sort={sortValue}
            sortBy={xAxis === "index" ? sortValue : xAxis}
            width={width}
            w={w}
            height={height}
            selectedSensors={selectedSensors}
            selectedScenarios={selectedScenarios}
            legendsPosition={legendsPosition}
            isFullScreen={isFullScreen}
          // dataKeys={selectedResources.map((r) => r.dataKey)}
          ></ChartType>
        </ResponsiveContainer>
      </LoadingOrEmptyWrapper>
    </div>
  );
};

const ChartTypeSelection = ({ chartOptions, handleChartSelection }) => {
  return (
    <Grid
      container
      spacing={2}
      className="w-full h-full mt-1"
      justifyContent={"space-evenly"}
    >
      {chartOptions?.map((option, index) => {
        const IconComponent = option.icon;
        return (
          <Grid item xs={4} className="hover:scale-[1.2] transition" key={index}>
            <Stack className="justify-center items-center">
              <Stack
                direction={"column"}
                className="hover:bg-blue-100 w-15 h-15 p-2 bg-[#f5f5ff] rounded-xl cursor-pointer "
                onClick={() => {
                  handleChartSelection(option.type);
                }}
              >
                <IconComponent size={32} color={option.color} />
                <span className="t-label-m">{option.label} </span>
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};


// The userLanguage parameter is used only to make a rerender when changed.
// TODO: Use a better approach
const DynamicChartMemoized = ({
  id,
  generalData,
  onClose,
  w,
  height,
  minDate,
  maxDate,
  xCoord,
  config,
  setConfig,
  projectInfoParsed,
  setFullScreenChartId,
  isFullScreen,
  userLanguage
}) => {

  const { projectId } = useParams();

  const {
    diagrams,
    sortValue,
    chartType,
    title,
    xAxis,
    yAxis,
    dataPoint,
    dataLevel,
    timeframe,
    startDate,
    endDate,
    selectedScenarios: savedScenarios,
    selectedUtilities: savedUtilities,
    selectedDaysOfWeek,
    selectedHoursOfDay,
    legendsPosition,
  } = config;
  const dates = [new Date(startDate ?? minDate), new Date(endDate ?? maxDate)];

  const { scenarios, utilities, dataPoints } = projectInfoParsed;
  const relatedSensors = utilities?.find((info) => info.metric === dataPoint);
  const allSensorsDataKeys = relatedSensors?.values ?? [];
  const allSensorsDisplayNames = relatedSensors
    ? relatedSensors["display name"]
    : [];

  const relatedDataPoints = dataPoints?.filter(
    (d) =>
      d.level === dataLevel || (d.level === "room" && dataLevel !== "building")
  );

  const dataPointsOptions = relatedDataPoints?.map((d) => d.dataKey);
  const dataPointsDisplayOptions = relatedDataPoints?.map((d) => d.displayName);

  const setChartType = (newValue) => setConfig("chartType", newValue);

  const isDaily = timeframe === "daily";

  const inputs = {
    quantities: [dataPoint],
    scenarios,
    projectId,
    startDate: isDaily ? new Date(minDate) : dates[0],
    endDate: isDaily ? new Date(maxDate) : dates[1],
    dataLevel,
    interval: timeframe,
    enabled: !(dataLevel === "building" && isDaily),
  };
  let {
    data: specificDataNotMemoized,
    isLoading,
    isError,
  } = useGetDashboardNew(inputs);


  const specificData = useMemo(() => specificDataNotMemoized, [
    isLoading,
    dataPoint,
    timeframe,
    startDate,
    endDate,
    minDate,
    maxDate,
    dataLevel,
    scenarios,
    projectId,
  ]);

  const data = specificData?.length ? specificData : generalData;
  let dataColumns = [],
    categoricalColumns = [];
  if (data?.length) {
    dataColumns = Object.keys(data[0]);
    categoricalColumns = dataColumns.filter(
      (c) => c !== "dataPoint" && !allSensorsDataKeys?.includes(c)
    );
  }

  const [chartSelectionIsOpen, setChartSelectionIsOpen] = useState(!chartType);

  const navbarAndSidebarRef = useRef();
  const handleChartSelection = (type) => {
    setChartType(type);
    setChartSelectionIsOpen(false);
    navbarAndSidebarRef.current?.childHandleClick("settings");
  };

  const [value, setValue] = useState("all");

  const selectedSensors = savedUtilities ?? allSensorsDataKeys;
  const selectedScenarios = savedScenarios ?? scenarios;

  const color = "var(--clr-secondary-blue-400)";
  const chartOptions = [
    {
      label: "Area",
      icon: LiaChartAreaSolid,
      type: chartTypes.AREA,
      color,
    },
    {
      label: "Doughnut",
      icon: PiChartDonutThin,
      type: chartTypes.DOUGHNUT,
      color,
    },
    {
      label: "Pie",
      icon: IoPieChartOutline,
      type: chartTypes.PIE,
      color,
    },
    {
      label: "Histogram",
      icon: GiHistogram,
      type: chartTypes.HISTOGRAM,
      color,
    },
    {
      label: "Combo",
      icon: TbChartHistogram,
      type: chartTypes.COMPOSED,
      color,
    },
    {
      label: "Bar",
      icon: IoBarChartOutline,
      type: chartTypes.BAR,
      color,
    },
    {
      label: "Line",
      icon: PiChartLineLight,
      type: chartTypes.LINE,
      color,
    },
    {
      label: "KPI",
      icon: TbSum,
      type: chartTypes.KPI,
      color,
    },
    {
      label: "Heatmap",
      icon: TfiLayoutGrid4Alt,
      type: chartTypes.HEATMAP,
      color,
    },
  ];

  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <Paper className={`p-4 h-full`}>
        <div className={`h-full w-full ${chartSelectionIsOpen && "bg-white"}`}>
          <DynamicChartNavbarAndSidebar
            ref={navbarAndSidebarRef}
            onClose={onClose}
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            setChartSelectionIsOpen={setChartSelectionIsOpen}
            dataColumns={dataColumns}
            categoricalColumns={categoricalColumns}
            dataPoint={dataPoint}
            dataPointsOptions={dataPointsOptions}
            dataPointsDisplayOptions={dataPointsDisplayOptions}
            allSensorsDataKeys={allSensorsDataKeys}
            allSensorsDisplayNames={allSensorsDisplayNames}
            title={title}
            xAxis={xAxis}
            yAxis={yAxis}
            value={value}
            setValue={setValue}
            diagrams={diagrams}
            sortValue={sortValue}
            chartType={chartType}
            selectedSensors={selectedSensors}
            scenarios={scenarios}
            selectedScenarios={selectedScenarios}
            dates={dates}
            minDate={minDate}
            maxDate={maxDate}
            xCoord={xCoord}
            timeframe={timeframe}
            selectedDaysOfWeek={selectedDaysOfWeek}
            selectedHoursOfDay={selectedHoursOfDay}
            setConfig={setConfig}
            legendsPosition={legendsPosition}
            isFullScreen={isFullScreen}
            id={id}
            setFullScreenChartId={setFullScreenChartId}
          />
          <TextErrorBoundary>
            {isVisible && (
              <div
                style={{ height: "calc(100% - 24px)" }}
                className=" cancelDrag"
              >
                {chartSelectionIsOpen && (
                  <ChartTypeSelection
                    chartOptions={chartOptions}
                    handleChartSelection={handleChartSelection}
                  />
                )}

                {!chartSelectionIsOpen && (
                  <DynamicChartBody
                    isLoading={isLoading}
                    isError={isError}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    generalData={generalData}
                    specificData={specificData}
                    selectedDaysOfWeek={selectedDaysOfWeek}
                    selectedHoursOfDay={selectedHoursOfDay}
                    timeframe={timeframe}
                    chartType={chartType}
                    dataPoint={dataPoint}
                    allSensorsDataKeys={allSensorsDataKeys}
                    scenarios={scenarios}
                    title={title}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    diagrams={diagrams}
                    sortValue={sortValue}
                    w={w}
                    width={"100%"}
                    height={height}
                    selectedSensors={selectedSensors}
                    selectedScenarios={selectedScenarios}
                    legendsPosition={legendsPosition}
                    isFullScreen={isFullScreen}
                    id={id}
                  />
                )}
              </div>
            )}
          </TextErrorBoundary>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(DynamicChartMemoized, isEqual);
