import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AssetCard } from "./AssetCard";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";

const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <h1 className="t-heading-l t-gary-900 mt-8 h-100">
      {t("AssetOverview.noBuilding")}
    </h1>
  );
};
export const CardsContainer = ({ className, buildings, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const noData = buildings?.length === 0 || !buildings;
  return (
    <>
      <LoadingOrEmptyComponent
        showLoading={isLoading}
        showEmpty={noData}
        emptyComponent={EmptyState}
        height="40rem"
      />
      <Grid className={className} container spacing={4}>
        {buildings?.map((building, index) => {
          return (
            <Grid item xs={4} xl={3} key={index}>
              <AssetCard
                imgPath={building?.image_url_value}
                name={building?.name}
                city={building?.address}
                type={building?.type}
                year={building?.construction_year}
                lastModernized={building?.last_modernized}
                energyClass={building?.energy_label}
                value={building?.cash_in}
                emission={building?.carbon_dioxide_emission_value}
                climatePositive={building?.climate_positive}
                key={building?.id}
                certificateList={building?.certificate}
                onClick={() => {
                  navigate(`/details/${building?.id}/buildingProfile`);
                }}
              ></AssetCard>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
