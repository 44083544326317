import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { withDashboardChartsPrecalculator } from "./DashboardChartsPrecalculator";
import { useParams } from "react-router-dom";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="t-body-xl"
      style={{
        fontSize: "",
      }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const SinglePieChart = ({ className, data, label }) => {
  return (
    <Stack flexDirection="column" className={className}>
      <p className=" t-body-xl capitalize my-1">{label}</p>
      <ResponsiveContainer width={"100%"} height={225}>
        <PieChart height={200} margin={0}>
          <Pie
            data={data}
            label={renderCustomizedLabel}
            labelLine={false}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={110}
            fill="#82ca9d"
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Stack>
  );
};

const DashboardPieChart = ({
  props,
  isSingleChart,
  pieChartDataFirst,
  pieChartDataSecond,
}) => {
  const { className } = props;
  const { t } = useTranslation();
  const { projectId } = useParams();
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20;
  const bobHam = Number(projectId) == 18;
  return (
    <Stack className={`items-start w-full ${"justify-around "} ${className} `}>
      {pieChartDataFirst && (
        <SinglePieChart
          data={pieChartDataFirst}
          label={
            bobHam
              ? "Standard year"
              : !newProject
              ? t("EnergyPerformance.dashboard.simulated")
              : "Energy Consumption"
          }
          className={` ${isSingleChart ? "w-full" : " w-[46%]"}  `}
        />
      )}
      {pieChartDataSecond && (
        <SinglePieChart
          data={pieChartDataSecond}
          label={
            bobHam
              ? "Extreme year"
              : !newProject
              ? t("EnergyPerformance.dashboard.measured")
              : "Energy Demand"
          }
          className={` ${isSingleChart ? "w-full" : " w-[46%]"}  `}
        />
      )}
    </Stack>
  );
};

export default withDashboardChartsPrecalculator(DashboardPieChart);
