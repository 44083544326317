import { Paper } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsAllowed } from "../../acl";
import { resources } from "../../acl/resources";
import {
  useDeleteChildTableRow,
  useDeleteParentTableRow,
  useModifyChildTableCell,
  useModifyParentTableCell,
} from "../../api/hooks/allHooks";
import { getTechTableConfig } from "../../configs/TechnicalData";
import { getSelectedColumns } from "../../features/techData/techDataSlice";
import { Loading } from "../Loading";
import { Table } from "../Table";
import { TableContainerHeader } from "./TableContainerHeader";
import { LoadingOrEmptyComponent } from "../LoadingAndEmptyHandler";

export const TechTable = ({
  data,
  enabledTabs,
  activeTab,
  projectId,
  parentsAreLoading,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store.user);
  const { colNames: userColumns, columnsIsLoading } = useSelector(
    (store) => store.techData
  );

  const canDownload = IsAllowed("TECHNICAL_DATA.DOWNLOAD_FILES");
  const editable = IsAllowed(resources.TECHNICAL_DATA.EDIT_TABLE_CELL);
  const removeable = IsAllowed(resources.TECHNICAL_DATA.REMOVE_ROW);

  const dispatch = useDispatch();
  const { mutate: parentMutate } = useModifyParentTableCell();
  const { mutate: childMutate } = useModifyChildTableCell();

  const { mutate: parentDeleteRow } = useDeleteParentTableRow();
  const { mutate: childDeleteRow } = useDeleteChildTableRow();

  const config = getTechTableConfig({ projectId, activeTab });
  const cols = config[activeTab].columns;

  const colsState = userColumns[config[activeTab].dataKey];
  const filteredCols = cols?.filter((col) => {
    return (
      colsState?.includes(col.dataKey) &&
      (canDownload || col.cellType !== "downloadZipFile")
    );
  });

  const childCols = config.children.columns;
  const defaultChildCols = childCols?.filter((col) => {
    return col.isInDefault && (canDownload || col.cellType !== "downloadFile");
  });

  useEffect(() => {
    dispatch(getSelectedColumns({ projectId, userId: user?.user_id }));
  }, []);

  const parentHandleBlur = ({ rowId, fieldName, value }) => {
    parentMutate({
      activeTab,
      rowId,
      projectId,
      data: { [fieldName]: value },
    });
  };

  const parentHandleDelete = ({ rowId }) => {
    parentDeleteRow({ activeTab, projectId, rowId });
  };

  const childHandleBlur = ({ parentRowId, rowId, fieldName, value }) => {
    childMutate({
      activeTab,
      rowId: parentRowId,
      layerId: rowId,
      projectId,
      data: { [fieldName]: value },
    });
  };

  const childHandleDelete = ({ rowId, layerId }) => {
    childDeleteRow({ activeTab, projectId, rowId, layerId });
  };

  return (
    <Paper className="relative w-full min-h-50">
      {enabledTabs?.length > 0 && (
        <>
          <TableContainerHeader
            activeTab={activeTab}
            projectId={projectId}
            selectedColumns={colsState}
          />
          {!parentsAreLoading && !columnsIsLoading && (
            <Table
              className="relative mt-6"
              foldTableOnChangeOfVariable={activeTab}
              data={[...data]}
              headers={filteredCols}
              idColumnName="id"
              columnsMinWidth="10rem"
              onBlur={parentHandleBlur}
              onDelete={parentHandleDelete}
              editable={() => editable && i18next.language === "en"}
              childTableDataKey="layers"
              childHeaders={defaultChildCols}
              childIdColumnName="id"
              childOnBlur={childHandleBlur}
              childOnDelete={childHandleDelete}
              childEditable={() => editable && i18next.language === "en"}
              rowRemoveable={() => removeable}
            ></Table>
          )}
        </>
      )}
      <LoadingOrEmptyComponent
        showEmpty={!(enabledTabs?.length > 0)}
        showLoading={parentsAreLoading || columnsIsLoading}
      />
    </Paper>
  );
};
