import { Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetAllMeasures, useGetCrrem } from "../../api/hooks/allHooks";
import { theme } from "../../styles/theme";
import { distinctFilter, sum } from "../../utils/dataManipulation";
import { KPIs } from "../BuildingProfile/KPIs";
import Chip from "../Chip";
import { RadioButton } from "../RadioButton";
import LegendBullet from "../chart/LegendBullet";

// TODOs:
// 1-Better choice of dropdown and oneTime dropdown
// 2-modularize
// 3-new row a part of main table
// 4-new row isloading state
// 5-selected retrofits hover state vice versa
// 7-empty new row onBlur
// 8-selected retrofits row another color
// 9-invalidate selected retro by change in all retro
// 10-userbased remove/modify all retros
// 11-Dropdown full width

const getArraysCrossPointIndex = (arr1, arr2) => {
  const diff = arr1.map((_, index) => arr1[index] - arr2[index]);
  const crossPointIndex = [...Array(diff?.length).keys()].find((_, index) => {
    return (
      (diff[index] >= 0 && diff[index + 1] <= 0) ||
      (diff[index] <= 0 && diff[index + 1] >= 0)
    );
  });
  return crossPointIndex;
};

const getCrossYear = (data, dataKey) => {
  if (!data?.length > 0) return [];
  const allYears = data?.map((e) => e?.year)?.filter(distinctFilter);
  allYears.sort((a, b) => a - b);
  let crossYears = [];
  for (let index in allYears) {
    index = Number(index);
    const year = allYears[index];
    const nextYear = allYears[index + 1];
    // const yearData = data.find(
    //   (e) =>
    //     e.year === year && e.crrem !== undefined && e[dataKey] !== undefined
    // );
    const yearDataItems = data?.filter(
      (e) =>
        e?.year === year && e?.crrem !== undefined && e[dataKey] !== undefined
    );
    const nextYearDataItems = data?.filter(
      (e) =>
        e?.year === nextYear &&
        e?.crrem !== undefined &&
        e[dataKey] !== undefined
    );

    // const nextYearData = data.find(
    //   (e) =>
    //     e.year === nextYear && e.crrem !== undefined && e[dataKey] !== undefined
    // );
    if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
    const yearData = yearDataItems[yearDataItems.length - 1];
    const nextYearData = nextYearDataItems[0];
    // if (!yearData || !nextYearData) continue;
    const diff = yearData.crrem - yearData[dataKey];
    const nextDiff = nextYearData.crrem - nextYearData[dataKey];
    if (diff >= 0 && nextDiff < 0) {
      // crossYear = year;
      //      crossNextYear = nextYear;
      crossYears.push([year, nextYear]);
    }
  }

  return crossYears;
};

const getSegmentsCrossPointCoords = (segA, segB) => {
  const [[xA1, yA1], [xA2, yA2]] = segA;
  const [[xB1, yB1], [xB2, yB2]] = segB;
  const mA = (yA2 - yA1) / (xA2 - xA1);
  const mB = (yB2 - yB1) / (xB2 - xB1);
  const x = (-mB * xB1 + yB1 - yA1 + mA * xA1) / (mA - mB);
  const y = mA * (x - xA1) + yA1;
  return [x, y];
};

const calculateStrandingPoint = (data, dataKey) => {
  const strandingPoints = [];
  if (!data?.length > 0) return [];
  const crossYears = getCrossYear(data, dataKey);
  for (const crossYear of crossYears) {
    const [year, nextYear] = crossYear;
    if (!year || !nextYear) continue;
    const yearDataItems = data.filter(
      (e) =>
        e.year === year && e.crrem !== undefined && e[dataKey] !== undefined
    );
    const nextYearDataItems = data.filter(
      (e) =>
        e.year === nextYear && e.crrem !== undefined && e[dataKey] !== undefined
    );

    if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
    const yearData = yearDataItems[yearDataItems.length - 1];
    const nextYearData = nextYearDataItems[0];

    // const yearData = data.find((e) => e.year === year);
    // const nextYearData = data.find((e) => e.year === nextYear);
    // if (!yearData || !nextYearData) continue;

    // if (yearData?.crrem === yearData[dataKey]){
    //   if (nextYearData?.crrem < nextYearData[dataKey]) return [year, yearData[dataKey]];
    //   if (nextYearData?.crrem > nextYearData[dataKey]) return [year, yearData[dataKey]];

    // }
    const lineA = [
      [year, yearData[dataKey]],
      [nextYear, nextYearData[dataKey]],
    ];
    const lineB = [
      [year, yearData?.crrem],
      [nextYear, nextYearData?.crrem],
    ];
    strandingPoints.push(getSegmentsCrossPointCoords(lineA, lineB));
  }
  return strandingPoints;
};

const getRetrofitYCoords = (retrofits, data) => {
  let retrofitYCoords = [];
  retrofits.forEach((retrofit, index) => {
    const sumOfPreviousRetros = sum(
      retrofits.slice(0, index).map((e) => e.amount)
    );
    const retrofitYearData = data?.find((e) => e.year === retrofit.year);
    if (!retrofitYearData) return;
    retrofitYCoords.push([
      retrofitYearData?.current - (sumOfPreviousRetros || 0),
      retrofitYearData?.current - (sumOfPreviousRetros || 0) - retrofit.amount,
    ]);
  });
  return retrofitYCoords;
};

const addRetrofitsToData = (retrofits, data) => {
  if (!retrofits.length > 0) return data;
  retrofits.sort((a, b) => a.year - b.year);
  const retrofitYCoords = getRetrofitYCoords(retrofits, data);
  if (!retrofitYCoords?.length > 0) return data;
  const newData = data.map((e, index) => {
    const modernized =
      e.current -
      sum(
        retrofits
          ?.filter((retro) => e.year > retro.year)
          ?.map((retro) => retro.amount)
      );
    return { ...e, modernized: modernized || e.current };
  });
  retrofitYCoords.forEach((_, index) => {
    newData.push({
      year: retrofits[index].year,
      retrofit: retrofitYCoords[index][0],
    });
    newData.push({
      year: retrofits[index].year,
      retrofit: retrofitYCoords[index][1] + 0.5,
      modernized: retrofitYCoords[index][1],
    });
  });
  newData.sort((a, b) => a.year - b.year);
  return newData;
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value, data, dataPoint } = props;

  if (payload.retrofit === undefined || payload[dataPoint] === undefined)
    return;
  return (
    <svg
      x={cx - 6}
      y={cy - 3}
      width={12}
      height={6}
      fill="#00a"
      viewBox="0 0 12 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.750002L5.5 5.25L10 0.75"
        stroke="#00a"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

const StrandingChartLegends = ({ className, modernized, quantity, width }) => {
  const legends = [
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "consumption",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Country Specific Energy Target",
      quantity: "consumption",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "consumption",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Asset Energy Intensity",
      quantity: "consumption",
    },
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "emission",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Decarbonization Target",
      quantity: "emission",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Climate And Grid Corrected Asset Performance",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-500)",
      label: "Baseline Emission",
      quantity: "emission",
    },
  ];
  return (
    <Stack
      spacing={4}
      className={className}
      style={{ flexWrap: "wrap", width: width, flexDirection: "row-reverse" }}
    >
      {legends?.map((legend, index) => {
        if (
          (legend.label !== "Modernized" || modernized) &&
          legend.quantity === quantity
        )
          return (
            <Stack spacing={2} key={index}>
              <LegendBullet
                width="0.8rem"
                height="0.8rem"
                type="circle"
                color={legend.color}
              />
              <span className="t-body-xl">{legend.label}</span>
            </Stack>
          );
      })}
    </Stack>
  );
};

const CustomTooltip = ({ active, payload, label, quantity }) => {
  if (active && payload && payload.length) {
    if (payload[0].payload.crrem !== undefined)
      return (
        <Paper
          className="t-heading-l0 p-4"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <p className="mt-1 text-plunge-700 ">{`Target: ${payload[0].payload.crrem?.toFixed(
            2
          )}`}</p>
          <p className="mt-1 text-gray-900">{`${
            quantity === "consumption"
              ? "Asset Energy Intensity"
              : "Climate And Grid Corrected Asset Performance"
          }: ${payload[0].payload.current?.toFixed(2)}`}</p>
          {quantity === "emission" && (
            <p className="mt-1 text-gray-500">{`Baseline Emission: ${payload[0].payload.baseline?.toFixed(
              2
            )}`}</p>
          )}
          {payload[0].payload?.modernized !== undefined && (
            <p className="mt-1 text-blue-500">{`Retrofit: ${payload[0].payload.modernized?.toFixed(
              2
            )}`}</p>
          )}
          <p className="mt-1 text-[black]">{`Year: ${label}`}</p>
        </Paper>
      );
  }

  return null;
};

export const StrandingChart = ({
  className,
  projectId,
  selectedMeaures,
  kpis,
  kpiIsLoading,
  onCrremChange,
  chartHeight = 400,
  legendsWidth,
}) => {
  // const dataInit = [
  //   { crrem: 180.0, year: 2020, current: 135.0 },
  //   { crrem: 179.56175162946187, year: 2021, current: 134.7991836734694 },
  //   { crrem: 178.25180805870446, year: 2022, current: 134.59673469387755 },
  //   { crrem: 176.0845213036123, year: 2023, current: 134.3926530612245 },
  //   { crrem: 173.08363661140805, year: 2024, current: 134.18693877551019 },
  //   { crrem: 169.2820323027551, year: 2025, current: 133.9795918367347 },
  //   { crrem: 164.72135954999578, year: 2026, current: 133.77061224489796 },
  //   { crrem: 159.45158603819152, year: 2027, current: 133.56 },
  //   { crrem: 153.53044850870867, year: 2028, current: 133.34775510204082 },
  //   { crrem: 147.02282018339787, year: 2029, current: 133.1338775510204 },
  //   { crrem: 140.0, year: 2030, current: 132.91836734693877 },
  //   { crrem: 132.53893144606403, year: 2031, current: 132.70122448979592 },
  //   { crrem: 124.7213595499958, year: 2032, current: 132.48244897959182 },
  //   { crrem: 116.63293526542076, year: 2033, current: 132.26204081632653 },
  //   { crrem: 108.36227706141227, year: 2034, current: 132.04 },
  //   { crrem: 100.0, year: 2035, current: 131.81632653061226 },
  //   { crrem: 91.63772293858773, year: 2036, current: 131.59102040816327 },
  //   { crrem: 83.36706473457927, year: 2037, current: 131.36408163265307 },
  //   { crrem: 75.27864045000422, year: 2038, current: 131.13551020408164 },
  //   { crrem: 67.461068553936, year: 2039, current: 130.90530612244896 },
  //   { crrem: 60.000000000000014, year: 2040, current: 130.6734693877551 },
  //   { crrem: 52.97717981660216, year: 2041, current: 130.44 },
  //   { crrem: 46.46955149129134, year: 2042, current: 130.20489795918365 },
  //   { crrem: 40.54841396180847, year: 2043, current: 129.96816326530612 },
  //   { crrem: 35.27864045000422, year: 2044, current: 129.72979591836733 },
  //   { crrem: 30.7179676972449, year: 2045, current: 129.48979591836735 },
  //   { crrem: 26.916363388591947, year: 2046, current: 129.24816326530615 },
  //   { crrem: 23.915478696387723, year: 2047, current: 129.00489795918367 },
  //   { crrem: 21.748191941295545, year: 2048, current: 128.76000000000002 },
  //   { crrem: 20.438248370538133, year: 2049, current: 128.5134693877551 },
  // ];

  const [isScenarioOne, setIsScenarioOne] = useState(true);
  const [quantity, setQuantity] = useState("consumption");

  const { data: crremDataRaw } = useGetCrrem(
    projectId,
    isScenarioOne ? "1.5" : "1.5 GEG",
    quantity
  );
  const crremData = crremDataRaw?.map((row) => {
    return { ...row, baseline: row.current, current: row.climate };
  });
  var dataInit = crremData || [];

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(crremData || []);
    if (onCrremChange) onCrremChange(crremData);
  }, [crremDataRaw]);

  const { data: measures } = useGetAllMeasures(projectId);
  const [retrofits, setRetrofits] = useState(selectedMeaures);
  useEffect(() => {
    const retrofitsWithAmount = selectedMeaures?.map((retrofit) => {
      const correspondingRetrofit = measures?.find(
        (measure) => measure.name === retrofit.name
      );
      if (correspondingRetrofit) {
        const amount =
          quantity === "consumption"
            ? correspondingRetrofit?.embodied_carbon
            : correspondingRetrofit?.carbon_dioxide_reduction;
        return { ...retrofit, amount: Number(amount) };
      }
      return retrofit;
    });
    setRetrofits(retrofitsWithAmount);
  }, [selectedMeaures, quantity, isScenarioOne, measures]);
  // const strandingPoint = calculateStrandingPoint(data);
  const [strandingPoint, setStrandingPoint] = useState([]);
  const [strandingPointsModernized, setStrandingPointsModernized] = useState(
    []
  );

  useEffect(() => {
    const selectedRetrofits = (retrofits || [])
      .map((retrofit) => ({ ...retrofit, year: Number(retrofit.year) }))
      .filter((retrofit) => retrofit.year >= 2020 && retrofit.year <= 2130);
    setData(addRetrofitsToData(selectedRetrofits, dataInit));
  }, [retrofits, crremDataRaw, quantity, isScenarioOne]);

  useEffect(() => {
    const enhancedData = data.map((e) => {
      if (e.crrem !== undefined) return e;
      const year = e.year;
      const yearData = data.find(
        (e) => e.year === year && e.crrem !== undefined
      );
      if (yearData) return { ...e, crrem: yearData.crrem };
      return e;
    });
    setStrandingPointsModernized(
      calculateStrandingPoint(enhancedData, "modernized")
    );
    setStrandingPoint(calculateStrandingPoint(data, "current"));
  }, [data]);

  return (
    <Paper className={`relative  ${className}`}>
      <Stack gap={theme.spacing(6)} alignItems="center" className="mb-9">
        <h2 className="capitalize t-heading-l">Building Energy Performance</h2>
        <RadioButton
          options={["emission", "consumption"]}
          displayOptions={["CO₂ Emission", "kWh"]}
          row={true}
          value={quantity}
          setValue={setQuantity}
        />
      </Stack>

      <section className="flex justify-between items-start gap-6">
        <div style={{ width: kpis ? "calc(100% - 28rem)" : "100%" }}>
          <Stack justifyContent="space-between" alignItems="flex-start">
            <Stack gap={theme.spacing(4)} className="mb-6">
              <Chip isOn={isScenarioOne} onClick={() => setIsScenarioOne(true)}>
                Scenario 1
              </Chip>
              <Chip
                isOn={!isScenarioOne}
                onClick={() => setIsScenarioOne(false)}
              >
                Scenario 2
              </Chip>
            </Stack>
            <StrandingChartLegends
              modernized={selectedMeaures?.length > 0}
              quantity={quantity}
              width={legendsWidth}
            />
          </Stack>

          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="year"
                type="number"
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  lineHeight: "1.4rem",
                  letterSpacing: 0,
                }}
                domain={["dataMin", "dataMax"]}
                angle={270}
                tickCount={30}
                tickMargin={18}
              />
              <YAxis
                type="number"
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  lineHeight: "1.4rem",
                  letterSpacing: 0,
                }}
                tickCount={5}
                // tickMargin={18}
                // ticks={[10, 30, 90, 200, 300]}
              />
              <Tooltip content={<CustomTooltip quantity={quantity} />} />
              {/* <Legend
                  align="right"
                  verticalAlign="top"
                  iconSize={9}
                  iconType="circle"
                /> */}
              {strandingPoint?.length > 0 && (
                <ReferenceDot
                  x={strandingPoint[0][0]}
                  y={strandingPoint[0][1]}
                  r={8}
                  fill="none"
                  stroke="var(--clr-mystic-red-500)"
                  strokeWidth={3}
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                  }}
                />

                // <StrandingPoint
                //   x={strandingPoint[0][0]}
                //   y={strandingPoint[0][1]}
                // />
              )}
              {strandingPointsModernized?.length > 0 &&
                strandingPointsModernized.map((sP) => {
                  return (
                    <ReferenceDot
                      x={sP[0]}
                      y={sP[1]}
                      r={8}
                      fill="none"
                      stroke="var(--clr-mystic-red-500)"
                      strokeWidth={3}
                      style={{
                        filter:
                          "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                      }}
                    />
                  );
                })}
              {quantity === "emission" && (
                <Line
                  type="linear"
                  dataKey="baseline"
                  stroke="var(--clr-gray-500)"
                  dot={false}
                  strokeDasharray={5}
                  strokeWidth={1}
                  connectNulls
                />
              )}
              <Line
                type="linear"
                dataKey="current"
                stroke="var(--clr-gray-900)"
                dot={false}
                strokeWidth={1}
                connectNulls
              />
              <Line
                type="linear"
                dataKey="modernized"
                stroke="var(--clr-secondary-blue-500)"
                dot={false}
                strokeWidth={2}
              />
              <Area
                type="linear"
                dataKey="crrem"
                stroke="var(--clr-plunge-700)"
                dot={false}
                strokeWidth={1}
                connectNulls
                fillOpacity={0.05}
                fill="var(--clr-plunge-700)"
              />
              <Line
                type="linear"
                dataKey="retrofit"
                stroke="#00a"
                dot={<CustomizedDot dataPoint="modernized" data={data} />}
                strokeWidth={2}
                isAnimationActive={false}
                strokeDasharray="1"
              />
              {/* <Scatter dataKey="crrem" fill="red" /> */}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {kpis && (
          <div className="w-70">
            <KPIs kpis={kpis || []} isLoading={kpiIsLoading} xs={12} />
          </div>
        )}
      </section>
    </Paper>
  );
};
