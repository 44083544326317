import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sortFunction } from "../functions/general";
import { Icon } from "./Icon";
import { ButtonNew } from "./ButtonNew";
import { LabelLessInput } from "./Input";
import { useRef } from "react";
import { updateTableCell } from "../utils/dataManipulation";
import { Loading } from "./Loading";
import {
  getSortType,
  isCellTypeDownload,
  shouldBeSentenceCase,
} from "../functions/TechnicalData";
import {
  DropdownSingleSelect,
  DropdownSingleSelectOneTime,
} from "./DropdownSingleSelect";
import { DropdownSingleSelectNative } from "./DropdownSingleSelectNative";
import { DropdownSingleSelectNativeOneTime } from "./DropdownOneTimeSingleSelectNative";

const TableHeaderCell = ({
  className,
  labelClassName,
  handleColumnClick,
  columnsMinWidth,
  label,
  endAndromat,
}) => {
  return (
    <TableCell
      className={`h-9 p-0 pr-1 pl-3 ${className}`}
      onClick={handleColumnClick}
      tabIndex={1}
      sx={{
        background: "var(--clr-gray-50)",
        border: "1px solid var(--clr-gray-200)",
        // borderRadius: "4px 4px 0px 0px",
        minWidth: columnsMinWidth,
        ":hover": {
          boxShadow: "var(--box-shadow-up-hover)",
        },
        ":active": {
          background: "var(--clr-secondary-blue-50)",
          border: "1px solid var(--clr-secondary-blue-300)",
        },
      }}
    >
      <Box className="flex justify-between items-center">
        <span className={`t-body-s text-gray-800 ${labelClassName} `}>
          {label}
        </span>
        {endAndromat}
      </Box>
    </TableCell>
  );
};

const TableHeader = ({
  headers,
  sortColumn,
  isSortAscending,
  columnsMinWidth,
  handleColumnClick,
}) => {
  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => {
          const sortType = getSortType(
            header.dataKey,
            sortColumn,
            isSortAscending
          );
          const isDownload = isCellTypeDownload(header.cellType);

          return (
            <TableHeaderCell
              key={index}
              className={isDownload ? "" : "cursor-pointer"}
              labelClassName={
                shouldBeSentenceCase(header.label) ? "sentence-case" : ""
              }
              label={header.label}
              columnsMinWidth={columnsMinWidth}
              handleColumnClick={() => handleColumnClick(header.dataKey)}
              endAndromat={
                isDownload ? null : (
                  <Icon
                    iconName={sortType}
                    size="sm"
                    svgClassName="scale-150"
                  />
                )
              }
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const Table = (props) => {
  const {
    className,
    data,
    headers,
    newRowHeaders,
    childTableDataKey = null,
    columnsMinWidth,
    idColumnName,
    rowRemoveable = () => false,
    foldTableOnChangeOfVariable,
    onAddNewRow,
    addable,
    editable = () => false,
    newRowDataKey,
    scrollDown,
  } = props;
  const isSomeRowRemovable = data?.some((row) => rowRemoveable(row));
  const [tableData, setTableData] = useState([]);
  const [isSortAscending, setIsSortAscending] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);

  const sortData = (data) => {
    if (!sortColumn) return data;
    const sortedData = [...data];
    if (isSortAscending) {
      sortedData?.sort((a, b) => sortFunction(a[sortColumn], b[sortColumn]));
    } else {
      sortedData?.sort((a, b) => sortFunction(b[sortColumn], a[sortColumn]));
    }
    return sortedData;
  };

  const hasChild = data?.some((row) => row[childTableDataKey]?.length > 0);

  const handleCellValueChange = (e, rowId, dataKey, idColumnName) => {
    const newValue = e.target.value;
    const updatedData = updateTableCell(
      tableData,
      rowId,
      dataKey,
      newValue,
      idColumnName
    );
    setTableData(updatedData);
  };

  const emptyNewRow = { id: -1, [newRowDataKey]: null };
  useEffect(() => {
    setNewRow(emptyNewRow);
    setTableData(sortData(data));
  }, [sortColumn, isSortAscending, data]);

  useEffect(() => setSelectedRow(null), [foldTableOnChangeOfVariable]);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (id) => {
    setSelectedRow(id === selectedRow ? null : id);
  };

  const handleColumnClick = (header) => {
    if (header === sortColumn) setIsSortAscending(!isSortAscending);
    else setIsSortAscending(true);
    setSortColumn(header);
  };

  const [newRow, setNewRow] = useState(emptyNewRow);
  const handleNewRowCellChange = (e) => {
    const newValue = e.target.value;
    setNewRow({ ...newRow, [newRowDataKey]: newValue });
  };

  const sRef = useRef();
  useEffect(() => {
    if (scrollDown) sRef.current?.scrollIntoView();
    // setNewRow(emptyNewRow);
  }, [data]);
  return (
    <>
      <Wrapper className={className} rowRemoveable={isSomeRowRemovable}>
        <MuiTable stickyHeader>
          <TableHeader
            columnsMinWidth={columnsMinWidth}
            headers={headers}
            isSortAscending={isSortAscending}
            sortColumn={sortColumn}
            handleColumnClick={handleColumnClick}
          />
          <TableBody>
            {tableData?.map((row, index) => {
              const childData = row[childTableDataKey] || [];
              const rowHasChild = childData?.length > 0;
              if (!rowHasChild)
                return (
                  <Row
                    {...props}
                    key={index}
                    row={row}
                    parentRowId={row[idColumnName]}
                    rowHasChild={rowHasChild}
                    parentHasChild={hasChild}
                    onCellChange={handleCellValueChange}
                    editable={editable(row)}
                    rowRemoveable={rowRemoveable(row)}
                  />
                );
              return (
                <NestedRow
                  {...props}
                  key={index}
                  row={row}
                  childData={[...childData]}
                  parentHasChild={hasChild}
                  rowHasChild={rowHasChild}
                  onCellChange={handleCellValueChange}
                  onClick={() => handleRowClick(row[idColumnName])}
                  selectedRow={selectedRow}
                  editable={editable(row)}
                  rowRemoveable={rowRemoveable(row)}
                />
              );
            })}
          </TableBody>
        </MuiTable>
        {!sortColumn && <div ref={sRef} />}
      </Wrapper>
      {addable && (
        <Wrapper rowRemoveable={isSomeRowRemovable} className="mt-2 ">
          <MuiTable
            style={{
              border: "2px solid #ddd",
              // backgroundColor: "#eee7",
            }}
          >
            <TableBody>
              <Row
                {...props}
                row={newRow}
                className=""
                headers={newRowHeaders || headers.slice(0, 1)}
                // onClick={onClick}
                // selectedRow={selectedRow}
                idColumnName={"id"}
                onBlur={onAddNewRow}
                // onDelete={onDelete}
                // onCellChange={onCellChange}
                editable={true}
                rowRemoveable={false}
                parentRowId={-1}
                onCellChange={handleNewRowCellChange}
              />
            </TableBody>
          </MuiTable>
        </Wrapper>
      )}
    </>
  );
};

function NestedRow(props) {
  const {
    row,
    selectedRow,
    idColumnName,
    childData,
    childHeaders,
    childOnBlur,
    childOnDelete,
    childEditable,
    editable,
    rowRemoveable,
  } = props;
  return (
    <>
      <Row {...props} parentRowId={row[idColumnName]} />
      <TableRow>
        <TableCell className="p-0 m-0" colSpan={Object.keys(row)?.length}>
          <Collapse
            in={selectedRow === row[idColumnName]}
            timeout="auto"
            unmountOnExit
          >
            <Box className="my-6 mr-6">
              <Table
                {...props}
                className="pl-9"
                data={childData}
                headers={childHeaders}
                onBlur={childOnBlur}
                onDelete={childOnDelete}
                editable={childEditable}
                parentRowId={row[idColumnName]}
                editable={() => editable}
                rowRemoveable={() => rowRemoveable}
              ></Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const Row = ({
  data,
  row,
  headers,
  parentHasChild,
  rowHasChild,
  onClick,
  selectedRow,
  idColumnName,
  onBlur,
  onDelete,
  onCellChange,
  editable,
  rowRemoveable,
  parentRowId,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  return (
    <TableRow
      className={`relative`}
      sx={{
        ":hover": {
          background: "var(--clr-secondary-blue-50)",
        },
      }}
      onMouseEnter={() => setShowDeleteBtn(true)}
      onMouseLeave={() => setShowDeleteBtn(false)}
    >
      {headers?.map((header, index) => {
        const value = header.valueTransformation(row);
        const subValue = header.subValueTransformation(row);
        const placeholder = header.placeholder;
        const href = header.href(row);
        const errorMessage = (input) =>
          header.errorTransformation(row, data, input);
        const downloadZipFile = () => header.downloadZipFile(row);
        const beforeTextIconName =
          index !== 0 || !rowHasChild
            ? null
            : selectedRow === row[idColumnName]
            ? "ChevronDown"
            : "ChevronRight";
        const rowId = row[idColumnName];
        const fieldName = header.dataKey;
        const cellIsReadOnly = header?.isReadOnly;
        return (
          <CustomCell
            key={index}
            value={value}
            subValue={subValue}
            placeholder={placeholder}
            cellType={header.cellType}
            href={href}
            downloadZipFile={downloadZipFile}
            valueClassName={`t-body-l body-cell ${
              index == 0 && parentHasChild && !rowHasChild ? "pl-11" : ""
            }`}
            subValueClassName="t-body-xs text-gray-500"
            errorMessage={errorMessage}
            beforeTextIconName={rowHasChild && beforeTextIconName}
            iconAction={() => {
              if (onClick) onClick();
            }}
            onBlur={() => {
              if (onBlur) onBlur({ parentRowId, rowId, fieldName, value });
              setEditingCell(null);
            }}
            onDelete={() => {
              if (onDelete) onDelete({ parentRowId, rowId });
            }}
            onCellChange={(e) => {
              if (onCellChange) onCellChange(e, rowId, fieldName, idColumnName);
              if (
                ["dropdown", "dropdownOneTime"].includes(header.cellType) &&
                onBlur
              )
                onBlur({
                  parentRowId,
                  rowId,
                  fieldName,
                  value: e.target.value,
                });
            }}
            options={header.options}
            displayOptions={header.displayOptions}
            editable={editable && !cellIsReadOnly}
            isEditing={
              editingCell &&
              rowId === editingCell.rowId &&
              fieldName === editingCell.fieldName
            }
            onClick={() => setEditingCell({ rowId, fieldName })}
            hasDeleteBtn={index === 0 && rowRemoveable}
            showDeleteBtn={showDeleteBtn}
            setShowDeleteBtn={setShowDeleteBtn}
          />
        );
      })}
    </TableRow>
  );
};

const DownloadCell = ({ valueClassName, value, cellType, href }) => {
  return (
    <TableCell
      className={`${valueClassName}`}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      {cellType === "downloadFile" && (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className=" flex justify-center cursor-pointer"
        >
          {value}
        </a>
      )}
      {cellType === "downloadZipFile" && (
        <div className=" flex justify-center">{value}</div>
      )}
    </TableCell>
  );
};

const RowDeleteBtn = ({
  onDelete,
  showDeleteBtn,
  setShowDeleteBtn,
  className,
}) => {
  return (
    <Stack
      className={` cursor-pointer ${className}`}
      onMouseEnter={() => setShowDeleteBtn(true)}
    >
      {showDeleteBtn && (
        <ButtonNew
          onClick={() => {
            if (onDelete) onDelete();
          }}
          variant="tertiary"
          className=" absolute -left-1"
          size="sm"
        >
          <Icon iconName="Close" color="red" />
        </ButtonNew>
      )}
    </Stack>
  );
};

const TextCellContent = ({
  value,
  errorMessage,
  onCellChange,
  onBlur,
  onClick,
  editable,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") event.target.blur();
  };
  const handleBlur = () => {
    setIsEditing(false);
    if (onBlur) onBlur();
  };
  const handleClick = () => {
    setIsEditing(true);
    if (onClick) onClick();
  };
  const [error, setError] = useState("");
  const isValueNull = value === undefined || value === null;
  return (
    <>
      {editable && isEditing && (
        <div className="w-full">
          <LabelLessInput
            className="block w-full"
            style={{
              marginLeft: "-6px",
            }}
            value={!isValueNull ? value : ""}
            autoFocus={true}
            type="text"
            onChange={(e) => {
              if (onCellChange) onCellChange(e);
              if (errorMessage) setError(errorMessage(e.target.value));
            }}
            placeholder={placeholder}
            onBlur={handleBlur}
            height="3.2rem"
            onKeyDown={handleKeyDown}
          />
          {error && <p className="t-body-s text-red-500">{error}</p>}
        </div>
      )}
      {editable && !isEditing && (
        <ButtonNew
          variant="tertiary"
          size="sm"
          className=" w-full"
          style={{
            justifyContent: "flex-start",
            cursor: "text",
            marginLeft: "-6px",
          }}
          onClick={handleClick}
        >
          {t(!isValueNull ? value : placeholder)}
        </ButtonNew>
      )}
      {!editable && <span>{t(value)}</span>}
    </>
  );
};

const TextCell = ({
  valueClassName,
  value,
  errorMessage,
  placeholder,
  beforeTextIconName,
  beforeTextIconClassName,
  iconAction,
  onCellChange,
  onBlur,
  onDelete,
  onClick,
  editable,
  hasDeleteBtn,
  showDeleteBtn,
  setShowDeleteBtn,
}) => {
  return (
    <TableCell
      className={valueClassName}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      <Stack spacing={3} className="w-full">
        {hasDeleteBtn && (
          <RowDeleteBtn
            onDelete={onDelete}
            showDeleteBtn={showDeleteBtn}
            setShowDeleteBtn={setShowDeleteBtn}
            className="h-full w-8 top-1/2 -left-8 absolute -translate-y-1/2"
          />
        )}
        {beforeTextIconName && (
          <Icon
            iconName={beforeTextIconName}
            onClick={iconAction}
            className={`before-text-icon -ml-2 ${beforeTextIconClassName}`}
            size="md"
          />
        )}
        <TextCellContent
          value={value}
          errorMessage={errorMessage}
          onCellChange={onCellChange}
          onBlur={onBlur}
          onClick={onClick}
          editable={editable}
          placeholder={placeholder}
        />
      </Stack>
    </TableCell>
  );
};

const DropdownCell = ({
  valueClassName,
  value,
  placeholder,
  beforeTextIconName,
  beforeTextIconClassName,
  iconAction,
  onCellChange,
  // onBlur,
  onDelete,
  // onClick,
  // editable,
  hasDeleteBtn,
  showDeleteBtn,
  setShowDeleteBtn,
  options,
  displayOptions,
  cellType,
}) => {
  return (
    <TableCell
      className={valueClassName}
      sx={{
        ":hover": {
          backgroundColor: "var(--clr-secondary-blue-100)",
        },
      }}
    >
      <Stack spacing={3} className="w-full">
        {hasDeleteBtn && (
          <RowDeleteBtn
            onDelete={onDelete}
            showDeleteBtn={showDeleteBtn}
            setShowDeleteBtn={setShowDeleteBtn}
            className="h-full w-8 top-1/2 -left-8 absolute -translate-y-1/2"
          />
        )}
        {beforeTextIconName && (
          <Icon
            iconName={beforeTextIconName}
            onClick={iconAction}
            className={`before-text-icon -ml-2 ${beforeTextIconClassName}`}
            size="md"
          />
        )}
        {cellType === "dropdown" && (
          <DropdownSingleSelectNative
            placeholder={placeholder}
            options={options}
            displayOptions={displayOptions}
            selectedValue={value}
            setSelectedValue={() => {}}
            onChange={onCellChange}
            width="120px"
          />
        )}
        {cellType === "dropdownOneTime" && (
          <DropdownSingleSelectNativeOneTime
            placeholder={placeholder}
            options={options}
            displayOptions={displayOptions}
            selectedValue={value}
            setSelectedValue={() => {}}
            onChange={onCellChange}
            width="120px"
          />
        )}
      </Stack>
    </TableCell>
  );
};

const CustomCell = (props) => {
  const { cellType } = props;
  if (cellType === "downloadFile" || cellType === "downloadZipFile")
    return <DownloadCell {...props} />;
  if (cellType === "dropdown" || cellType === "dropdownOneTime")
    return <DropdownCell {...props} />;
  else {
    return <TextCell {...props} />;
  }
};
const Wrapper = styled(TableContainer)(({ rowRemoveable }) => ({
  paddingLeft: `${rowRemoveable ? "42px" : ""}`,
  textAlign: "left",
  ".body-cell": {
    borderRight: "1px solid var(--clr-gray-100)",
    borderLeft: "1px solid var(--clr-gray-100)",
    // padding: "0rem 1.6rem",
    height: "4.8rem",
  },
  ".text-center": {
    textAlign: "center",
  },
  ".before-text-icon": {
    cursor: "pointer",
  },
  ".opacity-zero": {
    opacity: 0,
  },
}));
