import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { Stack } from "@mui/material";
import BackgroundImage from "../Asset/Images/NavLooperBG.png";
import LeaftechL from "../Asset/Images/leaftechLLg.svg";
import { useTranslation } from "react-i18next";
import { ButtonNew } from "./ButtonNew";
import { updateUserLanguage } from "../api/functions/all";
import { useSelector } from "react-redux";
import { IsAllowed } from "../acl";
import { resources } from "../acl/resources";
import { IconButton } from "./IconButton";

export const Navbar = ({ className }) => {
  const { i18n } = useTranslation();
  const alternativeLanguage = i18n.language === "en" ? "de" : "en";
  const { user } = useSelector((store) => store.user);
  const { pathname } = useLocation();
  const pageName = pathname.includes("administration")
    ? "administration"
    : pathname.includes("contact")
      ? "contact"
      : "";
  const defaultNavLinks = [
    { iconName: "BuildingIcon", to: "/", isActive: pageName === "" },
    // {
    //   iconName: "SimulatorIcon",
    //   to: "simulator",
    //   isActive: pageName === "simulator",
    // },
    {
      iconName: "Contact",
      to: "mailto:service@leaftech.eu",
      isActive: false,
    },

    // { iconName: "ThreeDViewerIcon", to: "page1", isActive: false },
    {
      iconName: "Settings",
      to: "administration",
      isActive: pageName === "administration",
    },
    // { iconName: "SearchIcon", to: "page1", isActive: false },
    // { iconName: "ChartIcon", to: "page1", isActive: false },
  ];
  const [navLinks, setNavLinks] = useState(defaultNavLinks);

  const changeLink = (clickedNavLink) => {
    if (clickedNavLink.iconName === "Contact") return;
    const newNavlinks = navLinks?.map((navLink) => {
      if (navLink.iconName === clickedNavLink.iconName)
        return { ...navLink, isActive: true };
      else return { ...navLink, isActive: false };
    });
    setNavLinks(newNavlinks);
  };

  const settingsIsAllowed = IsAllowed(resources.NAVBAR.SETTINGS);

  return (
    <nav
      className={`${className} py-6 px-1.5 flex flex-col items-center bg-white shadow`}
    >
      <Stack
        flexDirection="column"
        className="justify-between h-20vh min-h-55 "
      >
        {navLinks.slice(0, 1)?.map((navLink, index) => {
          if (navLink.to !== "administration" || settingsIsAllowed)
            return (
              <NavLink
                key={index}
                to={navLink.to}
                tabIndex={-1}
                onClick={() => changeLink(navLink)}
              >
                <IconButton
                  iconProps={{
                    iconName: navLink.iconName,
                    style: { transform: "scale(1.1)" },
                  }}
                  buttonProps={{
                    variant: "tertiary",
                    size: "sm",
                    isActive: navLink.isActive,
                    width: "4rem",
                    height: "4rem",
                  }}
                />
              </NavLink>
            );
        })}
        <div className="w-4/5 border border-solid border-gray-200"></div>
        {navLinks.slice(1)?.map((navLink, index) => {
          if (navLink.to !== "administration" || settingsIsAllowed)
            return (
              <NavLink
                key={index}
                to={navLink.to}
                tabIndex={-1}
                onClick={() => changeLink(navLink)}
              >
                <IconButton
                  iconProps={{
                    iconName: navLink.iconName,
                    style: { transform: "scale(1.1)" },
                  }}
                  buttonProps={{
                    variant: "tertiary",
                    size: "sm",
                    isActive: navLink.isActive,
                    width: "4rem",
                    height: "4rem",
                  }}
                />
              </NavLink>
            );
        })}
        <ButtonNew
          onClick={() => {
            i18n.changeLanguage(alternativeLanguage);
            localStorage.setItem("language", alternativeLanguage);
            updateUserLanguage(user.user_id, alternativeLanguage);
          }}
          variant="tertiary"
          size="md"
          className="uppercase"
        >
          {alternativeLanguage}
        </ButtonNew>
      </Stack>
      <img
        src={BackgroundImage}
        alt="Background"
        className="absolute bottom-0 w-full h-28vh"
      />
      <img src={LeaftechL} alt="Logo" className="absolute z-50 bottom-6" />
    </nav>
  );
};
