import React, { useEffect } from "react";

import { Stack } from "@mui/material";
import { useState } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useSelector } from "react-redux";
import apiUrls from "../../api/urls";
import { Icon, CustomizedDialogs, ButtonNew, Loading } from "../../components";
import { theme } from "../../styles/theme";
import { customApiCall } from "../../utils/axios";
import { useTranslation } from "react-i18next";
import { IsAllowed } from "../../acl";
import {
  useCheckAllFilesStatus,
  useGetBuilding,
  useGetScenarios,
} from "../../api/hooks/allHooks";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const BuildingPassportHeader = ({
  pageName,
  projectId,
  componentRef,
}) => {
  const { t } = useTranslation();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { user } = useSelector((store) => store.user);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const successMessage = t("general.successfulFileUpload");
  const headerTitle = t("general.uploadFiles");
  const headerText = t("general.dragToUploadFile");
  const token = user?.access_token;
  const url = apiUrls.userFiles.upload;
  const queryParams = [
    { name: "project", value: building?.id },
    { name: "user", value: user?.user_id },
  ];
  const onSuccess = (resp) => {
    setHasError(false);
    setErrorMessage(false);
  };
  const onError = (xhr) => {
    setHasError(true);
    setErrorMessage(xhr.statusText);
  };

  const downloadAllFiles = () => {
    const isTechnical = pageName === "technicalData";
    const url = apiUrls.files.folders.download(projectId, isTechnical);
    const fileName = `${
      isTechnical ? "All technical files.zip" : "All passport files.zip"
    }`;
    const errorMessage = t("general.downloadFileProblem");
    customApiCall.download({ url, fileName, errorMessage });
  };
  const { data: allFilesStatus } = useCheckAllFilesStatus(projectId, pageName);
  const { data: scenarios } = useGetScenarios(projectId);

  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="100%">
      <Stack className="justify-between">
        {pageName !== "modernization" ? (
          <div>
            <h2 className=" t-label-m-caps uppercase text-gray-500">
              {t("BuildingPassportSharedLayout.buildingPassport")}
            </h2>
            <h1 className="t-heading-xxl">{building?.name}</h1>
          </div>
        ) : (
          <div>
            <h2 className=" t-label-m-caps uppercase text-gray-500">
              {t("BuildingPassportSharedLayout.energyConsultant")}
            </h2>
            <h1 className="t-heading-xxl">{building?.name}</h1>
          </div>
        )}

        {["buildingProfile", "technicalData", "modernization"].includes(
          pageName
        ) && (
          <Stack spacing={theme.spacing(4)}>
            {pageName !== "modernization" &&
              IsAllowed("TECHNICAL_DATA.DOWNLOAD_FILES") && (
                <ButtonNew
                  size="md"
                  variant="secondary"
                  onClick={downloadAllFiles}
                  disabled={allFilesStatus?.status !== 200}
                >
                  <Stack spacing={theme.spacing(1.5)}>
                    <Icon
                      iconName="Download"
                      disabled={allFilesStatus?.status !== 200}
                    ></Icon>
                    <p>{t("BuildingPassportSharedLayout.downloadAllFiles")}</p>
                  </Stack>
                </ButtonNew>
              )}
            {pageName === "modernization" &&
              IsAllowed("MODERNIZATION.DOWNLOAD_SCENARIOS") && (
                <ButtonNew
                  size="md"
                  variant="secondary"
                  onClick={() => exportComponentAsJPEG(componentRef)}
                  disabled={!scenarios || scenarios?.length === 0}
                >
                  <Stack spacing={theme.spacing(1.5)}>
                    <Icon
                      iconName="Download"
                      disabled={!scenarios || scenarios?.length === 0}
                    ></Icon>
                    <p>{t("BuildingPassportSharedLayout.downloadScenarios")}</p>
                  </Stack>
                </ButtonNew>
              )}
            {pageName === "technicalData" &&
              IsAllowed("TECHNICAL_DATA.UPLOAD_FILES") && (
                <CustomizedDialogs
                  Component={
                    <ButtonNew size="md" variant="primary">
                      <Stack spacing={theme.spacing(1.5)}>
                        <Icon iconName="Upload" color="white"></Icon>
                        <p> {t("BuildingPassportSharedLayout.uploadFiles")}</p>
                      </Stack>
                    </ButtonNew>
                  }
                  token={token}
                  url={url}
                  formDataFileKeyName="file"
                  onSuccess={onSuccess}
                  onError={onError}
                  queryParams={queryParams}
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  headerTitle={headerTitle}
                  headerText={headerText}
                  hasError={hasError}
                />
              )}
            {pageName === "buildingProfile" &&
              IsAllowed("BUILDING_PROFILE.THREE_D_VIEWER") && (
                <a
                  href={apiUrls.threeDViewer.navigate(
                    projectId,
                    user?.access_token
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ButtonNew size="md" variant="primary">
                    <Stack spacing={theme.spacing(1.5)}>
                      <p> {t("BuildingPassportSharedLayout.threeDViewer")}</p>
                      <Icon
                        iconName="ChevronRight"
                        color="white"
                        size="sm"
                      ></Icon>
                    </Stack>
                  </ButtonNew>
                </a>
              )}
          </Stack>
        )}
      </Stack>
    </LoadingOrEmptyWrapper>
  );
};
