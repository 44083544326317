import { getUserFromLocalStorage } from "../utils/localStorage";
import { roles } from "./roles";

export const getUserRoleId = () => {
  const user = getUserFromLocalStorage();
  // return 4;
  if (user) return user?.role;
  return roles.GUEST.roleId;
};

export const getRoleName = (roleId) => {
  const roleNames = Object.keys(roles).filter(
    (roleName) => roles[roleName]?.roleId === roleId
  );
  if (roleNames?.length > 0) return roleNames[0];
};

export const getRoleIdPermissions = (roleId) => {
  const roleName = getRoleName(roleId);
  return roles[roleName]?.permissions || [];
};

export const resourceIsAllowed = (resource, permissions) => {
  return permissions?.includes(resource);
};

export const IsAllowed = (resource) => {
  const userRoleId = getUserRoleId();
  const userPermissions = getRoleIdPermissions(userRoleId);
  const userIsAllowed = resourceIsAllowed(resource, userPermissions);
  return userIsAllowed;
};
