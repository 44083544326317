import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useGetGoals, useGetScenarios } from "../api/hooks/allHooks";
import { FormSection } from "../components/Modernization/FormSection";
import { GoalsSection } from "../components/Modernization/GoalsSection";
import { ScenariosSection } from "../components/Modernization/ScenariosSection";
import { ScreenShotContext } from "./BuildingPassportSharedLayout";
import { IsAllowed } from "../acl";
import { Helmet } from "react-helmet-async";
import { Wizard } from "../components/Modernization/Wizard";

export const Modernization = () => {
  const { projectId } = useParams();

  const {
    data: goals,
    isLoading: gIsLoading,
    isLoadingError: gIsError,
  } = useGetGoals(projectId);
  const {
    data: scenarios,
    isLoading: sIsLoading,
    isLoadingError: sIsError,
  } = useGetScenarios(projectId);

  const componentRef = useContext(ScreenShotContext);
  return (
    <>
      <Helmet>
        <title>Modernization | Leaftech</title>
      </Helmet>

      <Wizard projectId={projectId} />
    </>
  );
};
